import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'masked',
})
export class MaskedPipe implements PipeTransform {
  transform(plainString: string, visibleDigits: number = 4): string {
    // const visibleDigits = 4;
    const maskedSection = plainString.slice(0, -visibleDigits);
    const visibleSection = plainString.slice(-visibleDigits);
    return maskedSection.replace(/./g, '*') + visibleSection;
  }
}
