import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import {
  Agency,
  AppStateModel,
  LookupService,
  PortalFilterService,
  Program,
  RightSidebarService,
  RoleType,
  SalesSummaryReportLengthType,
  SalesSummaryRequest,
  SearchCriteriaPageType,
} from '@mbp/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngxs/store';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

@UntilDestroy()
@Component({
  selector: 'mbp-sales-summary-search-criteria',
  templateUrl: './sales-summary-search-criteria.component.html',
  styleUrls: ['./sales-summary-search-criteria.component.scss'],
})
export class SalesSummarySearchCriteriaComponent implements OnInit, OnDestroy {
  //#region : Variable Declarations :
  formErrors = {
    dealer: '',
    dealerCode: '',
    startDate: '',
    endDate: '',
  };
  validationMessages = {
    dealer: {
      required: 'Please select a dealer',
      dealer: 'Please select a valid dealer',
    },
    dealerCode: {
      required: 'Please select a dealer',
      dealer: 'Please select a valid dealer',
    },
    startDate: {
      required: 'Please enter a start date',
    },
    endDate: {
      required: 'Please enter a end date',
    },
  };

  includeAgencyFilter = false;
  isLoading = false;
  isLoadingVehicleYears = false;
  isLoadingVehicleMakes = false;
  isLoadingVehicleModels = false;
  defaultAgentSearchText: string = null;
  defaultDealerSearchText: string = null;
  searchCriteriaForm: FormGroup;

  programs: Program[] = [];
  searchCriteriaRequest: SalesSummaryRequest;
  salesSummaryReportLengthType: SalesSummaryReportLengthType = SalesSummaryReportLengthType.Day;
  selectedAgency: Agency = null;

  hasPortalFilter = false;
  private subscription = new Subscription();
  //#endregion

  //#region : Constructor :
  constructor(
    private formBuilder: FormBuilder,
    private store: Store,
    private lookupService: LookupService,
    private rightSidebarService: RightSidebarService,
    private portalFilterService: PortalFilterService,
  ) {}
  //#endregion

  //#region : Properties :
  //#endregion

  //#region : Implementation Methods :
  ngOnInit() {
    const user = this.store.selectSnapshot((state: AppStateModel) => state.auth.user);
    this.includeAgencyFilter = user.roleType === RoleType.Agent || user.roleType === RoleType.MBPIUser;

    this.buildForm();

    setTimeout(() => {
      this.loadLookupData();
    }, 500);

    this.subscription.add(
      this.rightSidebarService.runImmediately$
        .pipe(
          filter((sc) => !!sc),
          filter(({ searchCriteriaPageType }) => searchCriteriaPageType === SearchCriteriaPageType.SalesSummaryReport),
          untilDestroyed(this),
        )
        .subscribe((sc) => {
          this.initializeByReportLength(sc.eventData?.salesSummaryReportLengthType);
          this.updateSearchCriteria(sc.eventData?.searchCriteria);
          this.search();
        }),
    );

    this.subscription.add(
      this.store
        .select((appState: AppStateModel) => appState.dealer.currentDealerMinimal)
        .pipe(
          filter((dm) => !!dm),
          untilDestroyed(this),
        )
        .subscribe((dealerMinimal) => {
          this.searchCriteriaForm.patchValue({
            dealer: dealerMinimal,
          });
          this.searchCriteriaForm.get('dealer').disable();
          this.search();
        }),
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  //#endregion

  //#region : Methods :
  buildForm() {
    const startDate = new Date();
    const endDate = new Date();

    this.searchCriteriaForm = this.formBuilder.group({
      startDate,
      endDate,
      agency: null,
      dealer: null,
      program: '',
    });
    this.searchCriteriaForm.valueChanges.subscribe((data) => this.onFormValueChanged(data));
  }

  loadLookupData() {
    this.lookupService.getPrograms({}).subscribe(
      (data) => this.onProgamsLoadSuccessful(data),
      (error) => this.onProgramsLoadFailed(error),
    );
  }

  public onFormValueChanged(data?: any) {
    if (!this.searchCriteriaForm) {
      return;
    }
    const form = this.searchCriteriaForm;
    for (const field in this.formErrors) {
      if (Object.prototype.hasOwnProperty.call(this.formErrors, field)) {
        this.formErrors[field] = '';
        const control = form.get(field);
        if (control && control.dirty && !control.valid) {
          const messages = this.validationMessages[field];
          for (const key in control.errors) {
            if (Object.prototype.hasOwnProperty.call(control.errors, key)) {
              this.formErrors[field] += messages[key] + ' ';
            }
          }
        }
      }
    }
  }

  onProgamsLoadSuccessful(programsData: Program[]) {
    this.programs = Array.isArray(programsData) ? programsData : [];
  }

  onProgramsLoadFailed(error: any) {}

  public search() {
    const formValue = this.searchCriteriaForm.getRawValue();
    let searchCriteria: SalesSummaryRequest = {} as SalesSummaryRequest;

    searchCriteria.agency = formValue.agency;
    searchCriteria.agentCode = formValue.agency && formValue.agency.agentCode;
    searchCriteria.dealer = formValue.dealer;
    searchCriteria.dealerCode = formValue.dealer && formValue.dealer.dealerCode;
    searchCriteria.endDate = formValue.endDate;
    searchCriteria.program = formValue.program;
    searchCriteria.programCode = formValue.program && formValue.program.programCode;
    searchCriteria.startDate = formValue.startDate;

    this.rightSidebarService.setSearchCriteria(SearchCriteriaPageType.SalesSummaryReport, searchCriteria);
  }

  initializeByReportLength(reportLengthType: SalesSummaryReportLengthType) {
    const user = this.store.selectSnapshot((state: AppStateModel) => state.auth.user);
    const currentDate = new Date();
    const currentWeekStartDate = new Date();
    const currentMonthStartDate = new Date();
    const currentYearStartDate = new Date();
    const currentUserIsMBPI: boolean = user.isActiveDirectoryUser;
    const previousDate = new Date();
    const previousWeekStartDate = new Date();
    const previousMonthStartDate = new Date();
    let previousYearStartDate = new Date();
    let startDate = new Date(currentDate);
    let endDate: Date = null;
    const day = currentDate.getDay();

    this.salesSummaryReportLengthType = reportLengthType;
    currentMonthStartDate.setDate(1);
    if (day !== 1) {
      currentWeekStartDate.setHours(-24 * (day - 1));
    }

    if (currentUserIsMBPI) {
      currentYearStartDate.setFullYear(currentDate.getFullYear() - 1, 10, 1);
    } else {
      currentYearStartDate.setDate(1);
      currentYearStartDate.setMonth(0);
    }

    previousDate.setDate(previousDate.getDate() - 1);
    previousWeekStartDate.setDate(currentWeekStartDate.getDate() - 7);
    previousMonthStartDate.setMonth(currentMonthStartDate.getMonth() - 1, 1);
    previousYearStartDate = new Date(currentYearStartDate);
    previousYearStartDate.setFullYear(previousYearStartDate.getFullYear() - 1);

    if (this.salesSummaryReportLengthType === SalesSummaryReportLengthType.Month) {
      startDate = currentMonthStartDate;
    } else if (this.salesSummaryReportLengthType === SalesSummaryReportLengthType.Week) {
      startDate = currentWeekStartDate;
      endDate = new Date(currentWeekStartDate);
      endDate.setDate(endDate.getDate() + 7);
    } else if (this.salesSummaryReportLengthType === SalesSummaryReportLengthType.Year) {
      startDate = currentYearStartDate;
      endDate = new Date(currentYearStartDate);
      endDate.setDate(endDate.getDate() - 1);
      endDate.setFullYear(endDate.getFullYear() + 1);
    } else if (this.salesSummaryReportLengthType === SalesSummaryReportLengthType.PreviousDay) {
      startDate = previousDate;
      endDate = previousDate;
    } else if (this.salesSummaryReportLengthType === SalesSummaryReportLengthType.PreviousWeek) {
      startDate = previousWeekStartDate;
      endDate = new Date(currentWeekStartDate);
      endDate.setDate(endDate.getDate() - 1);
    } else if (this.salesSummaryReportLengthType === SalesSummaryReportLengthType.PreviousMonth) {
      startDate = previousMonthStartDate;
      endDate = new Date(currentMonthStartDate);
      endDate.setDate(endDate.getDate() - 1);
    } else if (this.salesSummaryReportLengthType === SalesSummaryReportLengthType.PreviousYear) {
      startDate = previousYearStartDate;
      endDate = new Date(currentYearStartDate);
      endDate.setDate(endDate.getDate() - 1);
    } else if (this.salesSummaryReportLengthType === SalesSummaryReportLengthType.SalesSummary) {
      startDate = currentDate;
      endDate = new Date(currentDate);
      startDate.setDate(startDate.getDate() - 7);
    }

    this.searchCriteriaForm.patchValue({ startDate });
    if (endDate != null) {
      this.searchCriteriaForm.patchValue({ endDate });
    }
  }

  public updateSearchCriteria(overrideSearchCriteria: SalesSummaryRequest) {
    this.selectedAgency = overrideSearchCriteria.agency;

    this.searchCriteriaForm.patchValue({
      agency: overrideSearchCriteria.agency,
      agentCode: overrideSearchCriteria.agentCode,
    });
    this.searchCriteriaForm.patchValue({
      dealer: overrideSearchCriteria.dealer,
      dealerCode: overrideSearchCriteria.dealerCode,
    });
  }
  //#endregion
}
