import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';

@Component({
  selector: 'mbp-search-input',
  templateUrl: './search-input.component.html',
  styleUrls: ['./search-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchInputComponent implements AfterViewInit {
  @Input() searchText = '';
  @Input() placeholder = '';
  @Input() isSearching = false;
  @Output() search = new EventEmitter<string>();
  @Output() clear = new EventEmitter<any>();
  @Input() focus = false;
  @ViewChild('searchInput') searchInput: ElementRef;

  constructor(private cd: ChangeDetectorRef) {}

  ngAfterViewInit(): void {
    if (this.focus) {
      this.onFocus();
    }
  }

  onFocus() {
    this.searchInput.nativeElement.focus();
    this.cd.detectChanges();
  }
}
