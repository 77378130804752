import { MenuGroupType } from './enums';
import { RaterMenuDealerSetting } from './rater-menu-dealer-setting.model';
import { RaterMenuGroup } from './rater-menu-group.model';
import { RaterMenuProduct } from './rater-menu-product.model';

export class RaterMenu {
  //#region : Constructor :
  constructor() {}
  //#endregion

  //#region : Properties :

  public products: RaterMenuProduct[];
  public groups: RaterMenuGroup[];
  public dealerSetting: RaterMenuDealerSetting;

  public menuGroupType: MenuGroupType = MenuGroupType.Financed;

  public loanTermMonths: number;
  public loanTermRatePercent: number;
  public loanTermMonths2: number;
  public loanTermRatePercent2: number;

  public selectedLoanTermMonths: number = null;

  public cashDownAmount: number;
  public rebateAmount: number;
  public tradeInAmount: number;
  public payoffAmount: number;
  public feesAmount: number;
  public taxAmount: number;
  public totalFinanceAmount: number;
  public taxOverrideAmount: number;

  public cashDownAmountIsEditing: boolean;
  public firstPaymentDateIsEditing: boolean;
  public rebateAmountIsEditing: boolean;
  public tradeInAmountIsEditing: boolean;
  public payoffAmountIsEditing: boolean;
  public taxAmountIsEditing: boolean;

  public firstPaymentDate: Date;
  public originalFirstPaymentDate: Date;
  public vehicleOnlyMonthlyPaymentAmount: number;
  public vehicleOnlyMonthlyPaymentAmount2: number;
  public vehicleOnlyTaxAmount: number;

  public inCustomerMode: boolean;
  public showDealerCostAndProfit: boolean;

  // because we don't have this setting at the user level, this will allow them to toggle it while in the menu
  public showMonthlyProductCost: boolean;
  public showLegacyMenu: boolean;
  //#endregion

  //#region : Methods :
  //#endregion
}
