import { Injectable } from '@angular/core';
import { environment } from '@mbp/environment';
import { Action, NgxsAfterBootstrap, Selector, State, StateContext, StateToken } from '@ngxs/store';
import { patch } from '@ngxs/store/operators';
import { map } from 'rxjs/operators';
import { UserProfileService } from '../api/user-profile.service.js';
import { FeatureFlagsQueries } from '../graphql/feature-flags.js';
import { RoleNameType, RoleType } from '../models/enums';
import { User } from '../models/user.model';
import { FetchFeatureFlags, UserAllowedClaimsPreAuth, UserLoggedIn } from './auth.actions';
import { FetchDealerCount, SetDealerCode } from './dealer.actions';
import { createPropertySelectors } from './ngxs-next';

const { profilePhotoBaseUrl, noAvatar } = environment;

export interface AuthStateModel {
  user: User;
  accessToken: string;
  currentUserIsDealer: boolean;
  currentUserIsAgent: boolean;
  currentUserIsMBPI: boolean;
  currentUserIsCustomer: boolean;
  allowedClaimsPreAuth: boolean;
  yearMakeModelRatingDisabled: boolean;
  userManagementEnabled: boolean;
}

const defaultState: AuthStateModel = {
  user: null,
  accessToken: null,
  currentUserIsDealer: null,
  currentUserIsAgent: null,
  currentUserIsMBPI: null,
  currentUserIsCustomer: null,
  allowedClaimsPreAuth: null,
  yearMakeModelRatingDisabled: false,
  userManagementEnabled: false,
};

export const AUTH_STATE_TOKEN = new StateToken<AuthStateModel>('auth');

// FIXME: We need to check if a user is logged in or not on page reload and dispatch affected actions
// ie if a user is logged in we should fetch all dealer/agency information so state is up to date
@State<AuthStateModel>({
  name: AUTH_STATE_TOKEN,
  defaults: { ...defaultState },
})
@Injectable()
export class AuthState implements NgxsAfterBootstrap {
  public static props = createPropertySelectors(AUTH_STATE_TOKEN);

  @Selector([AUTH_STATE_TOKEN])
  public static userName(state: AuthStateModel) {
    return state.user?.username;
  }

  @Selector([AUTH_STATE_TOKEN])
  public static getIsLoggedIn(state: AuthStateModel) {
    return !!state.user;
  }

  @Selector([AUTH_STATE_TOKEN])
  public static profilePhotoUrl(state: AuthStateModel) {
    return state.user.profilePhotoUrl;
  }

  @Selector([AUTH_STATE_TOKEN])
  public static userIsDealerOrMbpi(state: AuthStateModel) {
    return state.currentUserIsDealer || state.currentUserIsMBPI;
  }

  @Selector([AuthState.getAgentCode])
  public static userIsRtda(agentCode: string) {
    return agentCode === 'RTDA';
  }

  @Selector([AuthState.props.currentUserIsAgent, AuthState.props.currentUserIsMBPI])
  public static userIsMbpiOrAgent(userIsAgent: boolean, userIsMbpi: boolean) {
    return userIsAgent || userIsMbpi;
  }

  @Selector([AuthState.props.user])
  public static getUserRoles(user: User) {
    return user?.roles ?? [];
  }

  @Selector([AuthState.getUserRoles])
  public static getIsMasterAgent(roles: string[]) {
    return !!roles?.some((r) => r?.toLowerCase() === 'master agent');
  }

  @Selector([AuthState.getUserRoles])
  public static getIsMasterDealer(roles: string[]) {
    return !!roles?.some((r) => r?.toLowerCase() === 'master dealer');
  }

  @Selector([AuthState.getUserRoles])
  public static getIsServiceUser(roles: string[]) {
    return !!roles?.some((r) => r?.toLowerCase() === 'service user');
  }

  @Selector([AuthState.getUserRoles])
  public static getIsServiceUserLimited(roles: string[]) {
    return !!roles?.some((r) => r?.toLowerCase() === 'service user limited');
  }

  @Selector([AuthState.getUserRoles])
  public static getIsAdministrator(roles: string[]) {
    return roles?.some((r) => r === RoleNameType[RoleNameType.MBPIAdministrator] || r === RoleNameType[RoleNameType.SiteAdministrator]);
  }

  @Selector([AuthState.props.user])
  public static usersDealerCode(user: User) {
    return user?.dealerCode;
  }

  @Selector([AuthState.props.user])
  public static getAgentCode(user: User) {
    return user?.agentCode;
  }

  constructor(private userProfileService: UserProfileService, private featureFlagsQueries: FeatureFlagsQueries) {}

  ngxsAfterBootstrap(ctx?: StateContext<AuthStateModel>): void {
    try {
      window.localStorage.removeItem('auth');
    } catch (error) {
      console.log(error);
    }

    // [IT-3765] Do we need to figure this out to work with kratos?
    // this.store
    //   .select(AuthState.props.user)
    //   .pipe(
    //     filter((user) => user?.requirePasswordChange),
    //     distinctUntilChanged(isEqual),
    //   )
    //   .subscribe((user) => {
    //     if (user.requirePasswordChange) {
    //       this.alert
    //         .showStickyMessage('Change Password', 'You are required to change your password. Click here to change it.', ToastType.warning)
    //         .onTap.pipe(take(1))
    //         .subscribe(() => {
    //           this.router.navigate(['/user/profile']);
    //         });
    //     }
    //   });
  }

  @Action(UserLoggedIn)
  public async userLoggedIn({ dispatch, patchState }: StateContext<AuthStateModel>, { payload }: UserLoggedIn) {
    const { accessToken, user } = payload;

    const currentUserIsDealer = !!user.dealerCode;
    const currentUserIsAgent = !!user.agentCode && !currentUserIsDealer;
    const currentUserIsMBPI = !!user.isActiveDirectoryUser;
    const currentUserIsCustomer = user.roleType === RoleType.Customer;
    const currentUserIsRtda = user.agentCode === 'RTDA';

    // We may need this eventually when the user roles are converted to Ory
    // Fix for: If you change the users role from _Dealer -> _Customer, they may still have some Dealer permissions in the Portal
    // const currentUserIsDealer = user.roleType === RoleType.Dealer;
    // const currentUserIsAgent = user.roleType === RoleType.Agent;
    // const currentUserIsMBPI = user.roleType === RoleType.MBPIUser;
    // const currentUserIsCustomer = user.roleType === RoleType.Customer;

    patchState({
      user: {
        ...user,
        profilePhotoUrl: `${profilePhotoBaseUrl}/${user.profilePhotoUrl || noAvatar}`,
      },
      accessToken,
      currentUserIsDealer,
      currentUserIsAgent,
      currentUserIsMBPI,
      currentUserIsCustomer,
      allowedClaimsPreAuth: currentUserIsMBPI,
    });

    dispatch([new SetDealerCode(user.dealerCode), new FetchDealerCount(), new FetchFeatureFlags()]);
    if (!currentUserIsMBPI) dispatch([new UserAllowedClaimsPreAuth()]);
  }

  @Action(UserAllowedClaimsPreAuth)
  userAllowedClaimsPreAuth(ctx: StateContext<AuthStateModel>) {
    return this.userProfileService.getAllowedClaimsPreAuth().pipe(
      map((allowed) => {
        ctx.setState(
          patch<AuthStateModel>({
            allowedClaimsPreAuth: allowed,
          }),
        );
      }),
    );
  }

  @Action(FetchFeatureFlags)
  async fetchFeatureFlags(ctx: StateContext<AuthStateModel>) {
    const now = new Date();
    const year = now.getUTCFullYear();
    const month = now.getUTCMonth() + 1;
    const day = now.getUTCDate();
    const date = new Date(Date.UTC(year, month, day)).toISOString();
    const features = await this.featureFlagsQueries.getFeatureFlags({
      date,
      name: 'Year Make Model Rating Disabled',
    });
    if (features.errors) {
      console.dir(features, {
        colors: true,
        breakLength: 80,
        depth: Infinity,
        maxArrayLength: Infinity,
        maxStringLength: Infinity,
        numericSeparator: true,
        sorted: true,
      });
      return;
    }
    const userManagementFeature = await this.featureFlagsQueries.getFeatureFlags({
      date,
      name: 'UserManagement',
    });
    if (userManagementFeature.errors) {
      console.dir(userManagementFeature, {
        colors: true,
        breakLength: 80,
        depth: Infinity,
        maxArrayLength: Infinity,
        maxStringLength: Infinity,
        numericSeparator: true,
        sorted: true,
      });
      return;
    }

    return ctx.setState(
      patch<AuthStateModel>({
        yearMakeModelRatingDisabled: !!features.data.selectFeatures.length,
        userManagementEnabled: !!userManagementFeature.data.selectFeatures.length,
      }),
    );
  }
}
