import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AnnouncementRequest, AnnouncementsRequest, ApiResponse, RepositoryItem, SendPushNotificationRequest, Setting } from '@mbp/core';
import { ClickupTaskItem, ClickupTask } from '../models/clickup-task.model';
import { getApiResponseData } from '../rxjs/get-api-response-data';

@Injectable({
  providedIn: 'root',
})
export class SystemService {
  constructor(private http: HttpClient) {}

  getAnnouncement(request: AnnouncementRequest) {
    return this.http.put<ApiResponse<ClickupTaskItem>>(`/api/system/announcement`, request).pipe(getApiResponseData());
  }

  getAnnouncements(request: AnnouncementsRequest) {
    return this.http.put<ApiResponse<ClickupTask>>(`/api/system/announcements`, request).pipe(getApiResponseData());
  }

  sendPushNotification(request: SendPushNotificationRequest) {
    return this.http.put<ApiResponse<boolean>>(`/api/system/sendpushnotification`, request).pipe(getApiResponseData());
  }

  getSettings() {
    return this.http.get<ApiResponse<Setting[]>>(`/api/system/settings`).pipe(getApiResponseData());
  }
}
