import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { of } from 'rxjs';
import { DocumentRepositoryService } from '../api/document-repository.service';
import { TreeEntry } from '../models/tree-entry.model';
import { AlertService } from '../services/alert.service';
import {
  ExportReviewFilesToOldPortal,
  ExportToScs,
  FetchDocumentById,
  FetchDocuments,
  GenerateChecks,
  ImportFromScs,
} from './documents.actions';

const defaultState = {
  documents: null,
};

export interface DocumentsStateModel {
  documents: TreeEntry[];
}

@State<DocumentsStateModel>({
  name: 'documents',
  defaults: {
    ...defaultState,
  },
})
@Injectable()
export class DocumentsState {
  @Selector()
  public static documents(state: DocumentsStateModel) {
    return state.documents;
  }

  @Action(FetchDocuments)
  fetchDocuments({ patchState }: StateContext<DocumentsStateModel>) {
    return of();
    // return this.docService.getAllFiles().pipe(
    //   tap((treeEntry) => {
    //     patchState({
    //       documents: treeEntry.children,
    //     });
    //   }),
    // );
  }

  @Action(FetchDocumentById)
  fetchDocumentById(ctx: StateContext<DocumentsStateModel>, { payload }: FetchDocumentById) {
    return of();
    // return this.docService.getFileById(payload).pipe(
    //   tap((response: DocumentDatabaseItem) => {
    //     SaveFile.dispatch({
    //       data: response.buffer,
    //       filename: response.name,
    //       contentType: response.mimeType,
    //     });
    //   }),
    // );
  }

  @Action(ImportFromScs)
  importFromSCS(_ctx: any, { payload }: ImportFromScs) {
    const id =
      payload.fullPath === 'SCS/Cancellation'
        ? 0
        : payload.fullPath === 'SCS/Commission'
        ? 8
        : payload.fullPath === 'SCS/Claim'
        ? 1
        : payload.fullPath === 'SCS/OverRemit'
        ? 2
        : -1;
    if (id === -1) return;
    return of();
    // return this.docService.importFromScs(id);
  }

  @Action(ExportToScs)
  exportToScs(_ctx: any, { payload }: ExportToScs) {
    const id =
      payload.fullPath === 'SCS/export/CancellationExport'
        ? 4
        : payload.fullPath === 'SCS/export/ClaimExport'
        ? 5
        : payload.fullPath === 'SCS/export/OverRemitExport'
        ? 6
        : payload.fullPath === 'SCS/export/PaymentFileExport'
        ? 7
        : -1;
    if (id === -1) return;
    return of();
    // return this.docService.exportToScs(id);
  }

  @Action(ExportReviewFilesToOldPortal)
  exportReviewFiles(_ctx: any, { payload }: ExportReviewFilesToOldPortal) {
    this.alertService.startLoadingMessage('', 'Exporting...');
    const request = payload.children.map((child) => {
      return child.id;
    });
    return of();
    // return this.docService.exportReviewFiles(request).pipe(
    //   tap((response: FileDownloadResponse) => {
    //     this.alertService.stopLoadingMessage();

    //     SaveFile.dispatch({
    //       data: response.fileContents,
    //       filename: response.fileDownloadName,
    //       contentType: response.contentType,
    //     });
    //   }),
    // );
  }

  @Action(GenerateChecks)
  generateChecks(_ctx, { payload }: GenerateChecks) {
    const id =
      payload.fullPath === 'accounting/checks/cancellations'
        ? 0
        : payload.fullPath === 'accounting/checks/claimgap'
        ? 1
        : payload.fullPath === 'accounting/checks/claimother'
        ? 2
        : -1;
    if (id === -1) return;
    return of();
    // return this.docService.generateChecks(id).pipe(tap((res) => console.log('return from check generation: ', res)));
  }

  constructor(private docService: DocumentRepositoryService, private alertService: AlertService) {}
}
