import { MessageStatusType } from './enums';

export class MessageStatus {
  //#region : Constructor :
  constructor(messageStatusTypeID?: MessageStatusType, name?: string) {
    this.messageStatusTypeID = messageStatusTypeID;
    this.name = name;
  }
  //#endregion

  //#region : Properties :
  public messageStatusTypeID: MessageStatusType;
  public name: string;
  //#endregion

  //#region : Methods :
  public static arrayList() {
    const messageStatuses: MessageStatus[] = [];

    messageStatuses.push(new MessageStatus(MessageStatusType.All, '[All Statuses]'));
    messageStatuses.push(new MessageStatus(MessageStatusType.Unassigned, 'Unassigned'));
    messageStatuses.push(new MessageStatus(MessageStatusType.Assigned, 'Assigned'));
    messageStatuses.push(new MessageStatus(MessageStatusType.OnHold, 'On Hold'));
    messageStatuses.push(new MessageStatus(MessageStatusType.InProgress, 'In Progress'));
    messageStatuses.push(new MessageStatus(MessageStatusType.Closed, 'Closed'));
    messageStatuses.push(new MessageStatus(MessageStatusType.Cancelled, 'Cancelled'));

    return messageStatuses;
  }
  //#endregion
}
