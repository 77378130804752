import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'mbp-scs-claim-info-search-criteria',
  templateUrl: './scs-claim-info-search-criteria.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ScsClaimInfoSearchCriteriaComponent {
  //#region : Variable Declarations :
  //#endregion

  //#region : Constructor :
  constructor(private router: Router, private route: ActivatedRoute) {}
  //#endregion

  //#region : Properties :
  //#endregion

  //#region : Implementation Methods :

  public search(claimNumber: string, contractNumber: string) {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: { claimNumber, contractNumber },
      queryParamsHandling: 'merge',
    });
  }
  //#endregion
}
