import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'momentFrom',
})
export class MomentFromPipe implements PipeTransform {
  transform(value: string, ...args: unknown[]): unknown {
    if (value == null) {
      return null;
    }

    const date = isFinite(+value) ? new Date(+value) : new Date(value);
    return moment(date).from(moment());
  }
}
