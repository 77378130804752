import { DealerMinimal, DealerStateModel, DEALER_STATE_TOKEN } from '@mbp/core';
import { Selector } from '@ngxs/store';

export interface DealerClaimsSearchCriteriaViewModel {
  dealerCount: number;
  dealerMinimals: DealerMinimal[];
  currentDealerMinimal: DealerMinimal;
  fetchedDealerMinimals: boolean;
}

export class DealerClaimsSearchCriteriaQueries {
  @Selector([DEALER_STATE_TOKEN])
  public static getViewModel(dealerState: DealerStateModel): DealerClaimsSearchCriteriaViewModel {
    return {
      dealerCount: dealerState.dealerCount,
      dealerMinimals: dealerState.dealerMinimals,
      currentDealerMinimal: dealerState.currentDealerMinimal,
      fetchedDealerMinimals: dealerState.fetchedDealerMinimals,
    };
  }
}
