import { Agency } from './agency.model';
import { DealerMinimal } from './dealer-minimal';
import { RoleNameType, RoleType, UserProfileType } from './enums';
import { User } from './user.model';

export class PortalUser {
  //#region : Events :
  //#endregion

  //#region : Constructor :
  constructor() {}
  //#endregion

  //#region : Properties :

  public userProfileID: number = null;
  public parentUserProfileID: number = null;
  public firstName: string = null;
  public lastName: string = null;
  public username: string = null;
  public emailAddress: string = null;
  public profilePhotoUrl: string = null;
  public agentCode: string = null;
  public dealerCode: string = null;

  public password: string = null;
  public confirmPassword: string = null;
  public usernameEncoded: string = null;
  public passwordEncoded: string = null;

  public agencyName: string = null;
  public dealerName: string = null;

  public isActive = true;
  public isFakeUser = false;

  public roleID: number = null;
  public roleName: string = null;
  public roleType: RoleType = null;
  public userProfileType: UserProfileType = null;

  public requirePasswordChange = true;
  public pipMemberNumber: string = null;

  public assignedAllActiveDealers: boolean = null;
  public assignedAllInactiveDealers: boolean = null;

  public additionalAgencies: UserProfileAssignedAgency[] = null;
  public additionalDealers: UserProfileAssignedDealer[] = null;
  public assignedDealers: UserProfileAssignedDealer[] = null;

  public agency: Agency = null;
  public dealer: DealerMinimal = null;
  //#endregion

  //#region : Methods :
  public static fromUser(user: User): PortalUser {
    const portalUser: PortalUser = new PortalUser();
    portalUser.userProfileID = user.userProfileID;
    portalUser.username = user.username;
    portalUser.roleID = user.roleType === RoleType.Agent ? RoleNameType.MasterAgent : RoleNameType.MasterDealer;
    portalUser.roleType = user.roleType;
    return portalUser;
  }
  //#endregion
}

export class UserProfileAssignedAgency {
  //#region : Constructor :
  constructor() {}
  //#endregion

  //#region : Properties :
  public agentCode: string = null;
  public agencyName: string = null;

  public agency: Agency = null;
  //#endregion
}

export class UserProfileAssignedDealer {
  //#region : Constructor :
  constructor(mainDealerCode?: string, dealerCode?: string, includeAllRelatedSubDealers?: boolean) {
    this.mainDealerCode = mainDealerCode;
    this.dealerCode = dealerCode;
    this.includeAllRelatedSubDealers = includeAllRelatedSubDealers;
  }
  //#endregion

  //#region : Properties :
  public mainDealerCode: string = null;
  public dealerCode: string = null;
  public dealerName: string = null;
  public includeAllRelatedSubDealers = false;

  public dealer: DealerMinimal = null;
  //#endregion
}
