<ng-container *ngIf="viewModel$ | async as vm">
  <nb-layout>
    <nb-layout-column>
      <mat-sidenav-container class="container" (backdropClick)="onBackdropClick()">
        <mat-sidenav
          *ngIf="vm.isLoggedIn && !vm.inCustomerMode"
          class="sidenav mat-elevation-z8"
          [opened]="vm.sidenavOpened"
          [mode]="vm.sidenavMode"
          disableClose
        >
          <mbp-portal-sidemenu></mbp-portal-sidemenu>
        </mat-sidenav>
        <mat-sidenav *ngIf="vm.isLoggedIn && !vm.inCustomerMode" position="end" mode="over" [opened]="vm.rightSidenavOpened" disableClose>
          <mbp-portal-sidebar></mbp-portal-sidebar>
        </mat-sidenav>

        <mat-sidenav-content>
          <mbp-ie-detector></mbp-ie-detector>
          <mbp-portal-toolbar *ngIf="vm.isLoggedIn && !vm.inCustomerMode">
            <button mat-icon-button (click)="onSidenavToggle()" *ngIf="vm.showToggle" class="toggle">
              <mat-icon>menu</mat-icon>
            </button>
          </mbp-portal-toolbar>
          <div [class.logged-in]="vm.isLoggedIn" class="router-container">
            <router-outlet></router-outlet>
          </div>
        </mat-sidenav-content>
      </mat-sidenav-container>
      <router-outlet name="contractsearch"></router-outlet>
    </nb-layout-column>
  </nb-layout>
</ng-container>
