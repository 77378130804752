import { DealerMinimal } from './dealer-minimal';

export class RemittanceHistoryRequest {
  //#region : Constructor :
  constructor() {
    this.dealer = null;
  }
  //#endregion

  //#region : Properties :
  public userName: string;
  public dealerCode: string;
  public programCode: string;
  public startDate: Date;
  public endDate: Date;
  public dealer: DealerMinimal;
  public useSefi: boolean;
  //#endregion
}
