export class ZipLookupResponse {
  //#region : Constructor :
  constructor() {}
  //#endregion

  //#region : Properties :
  public country: string;
  public places: any;
  //#endregion

  //#region : Methods :
  static toPlaces(obj: any) {
    if (obj == null || obj.length == 0) {
      return null;
    }

    const newPlaces: ZipLookupPlace[] = [];

    obj.forEach((v) => {
      newPlaces.push(new ZipLookupPlace(v.state, v['state abbreviation'], v['place name']));
    });

    return newPlaces;
  }
  //#endregion
}

export class ZipLookupPlace {
  //#region : Constructor :
  constructor(stateName?: string, stateAbbreviation?: string, city?: string) {
    this.stateName = stateName;
    this.stateAbbreviation = stateAbbreviation;
    this.city = city;
  }
  //#endregion

  //#region : Properties :
  public stateName: string;
  public stateAbbreviation: string;
  public city: string;
  //#endregion
}
