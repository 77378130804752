import { Directive, ElementRef, HostListener, Input, Renderer2 } from '@angular/core';

export enum NumberOnlyType {
  Standard = 1,
  Currency = 2,
  Zip = 3,
}

@Directive({
  selector: '[mbpNumberOnly]',
})
export class NumberOnlyDirective {
  // implements ControlValueAccessor {
  //#region : Variable Declarations :
  // Allow decimal numbers and negative values
  private regex: RegExp = new RegExp(/^-?[0-9]+(\.[0-9]*){0,1}$/g);
  private zipRegex: RegExp = new RegExp(/^[0-9]+(\-[0-9]*){0,1}$/g);
  // Allow key codes for special keys.
  private specialKeys: Array<string> = ['Backspace', 'Tab', 'End', 'Home', 'Delete', 'ArrowLeft', 'ArrowRight', 'Paste', 'Control', '-'];

  //#endregion

  //#region : Properties :
  @Input('mbpNumberOnly') numberType: NumberOnlyType = NumberOnlyType.Standard;
  //#endregion

  //#region : Constructor :
  constructor(private renderer: Renderer2, private el: ElementRef) {}
  //#endregion

  //#region : Implementation Methods :

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    // Allow Backspace, tab, end, and home keys
    if (
      this.specialKeys.indexOf(event.key) !== -1 ||
      (event.ctrlKey && event.key.toLowerCase() === 'v') ||
      (event.ctrlKey && event.key.toLowerCase() === 'c')
    ) {
      return;
    }
    const currentRegex: RegExp = this.numberType !== NumberOnlyType.Zip ? this.regex : this.zipRegex;
    const current: string = this.el.nativeElement.value;
    const next: string = current.concat(event.key);
    if (next && !String(next).match(currentRegex)) {
      event.preventDefault();
    }
  }

  @HostListener('blur')
  onBlur() {
    this.formatValue();
  }

  @HostListener('focus')
  onFocus() {
    if (this.el.nativeElement.value.length > 0) {
      if (this.numberType !== NumberOnlyType.Zip) {
        this.el.nativeElement.value = this.currentValueToString();
      }
      this.el.nativeElement.select();
    }
  }
  //#endregion

  //#region : Methods :
  private currentValueToString(): string {
    const currentString: string = this.el.nativeElement.value;
    const currentNumber: number = this.getElementNumberValue();
    return isNaN(currentNumber) || currentString.length === 0 ? '' : currentNumber.toString();
  }

  private currentValueToFormattedString(): string {
    const currentNumber: number = this.getElementNumberValue();
    let formattedValue = '';

    if (isNaN(currentNumber) === false && this.numberType !== NumberOnlyType.Zip) {
      formattedValue = (this.numberType === NumberOnlyType.Currency ? '$' : '') + currentNumber.toLocaleString();
    } else if (this.numberType === NumberOnlyType.Zip) {
      formattedValue = this.el.nativeElement.value;
    }
    return formattedValue;
  }

  private formatValue() {
    if (this.el.nativeElement.value.length > 0) {
      this.el.nativeElement.value = this.currentValueToFormattedString();
    }
  }

  private getElementNumberValue(): number {
    const currentString: string = this.el.nativeElement.value;
    const currentNumber: number = Number(currentString.replace(/[^0-9.-]/g, ''));
    return currentNumber;
  }
  //#endregion
}
