<kendo-dialog *ngIf="dialogOpen" (close)="closeForm()">
  <kendo-dialog-titlebar> Void Contract </kendo-dialog-titlebar>

  <div class="table" style="width: 100%; padding-bottom: 3px">
    <div class="tableRow">
      <div class="tableCell" style="vertical-align: top; width: 100px; padding-right: 5px">
        <label style="font-weight: bold">Contract #:</label>
      </div>
      <div class="tableCell" style="vertical-align: top">
        <label style="font-weight: normal">{{ actionDataItem.contractNumber }}</label>
      </div>
      <!--<div class="tableCell" style="vertical-align:top;width:80px;"><label style="font-weight:bold;padding-left:10px;">Customer:</label></div>
            <div class="tableCell" style="vertical-align:top;"><label style="font-weight:normal;padding-left:5px;">{{ actionDataItem.firstName }} {{ actionDataItem.lastName }}</label></div>-->
    </div>
  </div>
  <div class="table" style="width: 100%; padding-bottom: 3px">
    <div class="tableRow">
      <div class="tableCell" style="vertical-align: top; width: 100px; padding-right: 5px">
        <label style="font-weight: bold">Customer:</label>
      </div>
      <div class="tableCell" style="vertical-align: top">
        <label style="font-weight: normal">{{ actionDataItem.firstName }} {{ actionDataItem.lastName }}</label>
      </div>
    </div>
  </div>
  <div class="table" style="padding-bottom: 10px; border-bottom: 1px solid gray; width: 100%">
    <div class="tableRow">
      <div class="tableCell" style="vertical-align: top; width: 100px; padding-right: 5px">
        <label style="font-weight: bold">Description:</label>
      </div>
      <div class="tableCell" style="vertical-align: top; text-align: left; width: 200px">
        <label style="font-weight: normal"
          >{{ actionDataItem.planDescription | titlecase }} - {{ actionDataItem.getCoverageDisplay() }}</label
        >
      </div>
    </div>
  </div>

  <div class="table" style="padding-top: 10px">
    <div class="tableRow">
      <div class="tableCell" style="vertical-align: top; padding-right: 5px">
        <label style="font-weight: bold">Please choose the reason for the void</label>
      </div>
    </div>
  </div>
  <form fxLayout="column" fxLayoutAlign="start stretch" [formGroup]="voidForm">
    <div class="table" style="padding-bottom: 5px">
      <div class="tableRow">
        <div class="tableCell" style="vertical-align: bottom; width: 120px; padding-right: 5px">
          <label style="font-weight: bold">Void Reason:</label>
        </div>
        <div class="tableCell" style="vertical-align: middle; text-align: left; width: 200px">
          <mat-form-field style="width: 200px">
            <mat-select formControlName="voidReasonType" required>
              <mat-option *ngFor="let voidReason of voidReasons" [value]="voidReason.voidReasonType">
                {{ voidReason.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </div>

    <div style="padding-top: 10px; text-align: center">
      <button class="k-button" style="width: 100px; border: 1px solid darkgray; margin-right: 10px" (click)="onCancel($event)">
        Cancel
      </button>
      <button
        class="k-button"
        style="width: 200px; border: 1px solid darkgray"
        [disabled]="!voidForm.valid || isLoading"
        (click)="onVoid($event)"
      >
        Void Contract
      </button>
    </div>
  </form>
</kendo-dialog>
