import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';
import { AppStateModel } from '../models/app-state.model';
import { Dispatchable } from './Dispatchable';
import { createPropertySelectors } from './ngxs-next';

export interface SaveFilePayload {
  data: string | Blob;
  filename: string;
  contentType?: string;
}

export class SaveFile extends Dispatchable {
  public static readonly type = '[SAVE FILE] Save file download response';
  constructor(public payload: SaveFilePayload) {
    super();
  }
}

export type XlsxRow = { [colName: string]: any };
export type XlsxSheet = { name: string; data: XlsxRow[] };

export interface XlsxExportPayload {
  fileName: string;
  sheets: XlsxSheet[];
}

export class XlsxExport extends Dispatchable {
  public static readonly type = '[XLSX] Export';
  constructor(public payload: XlsxExportPayload) {
    super();
  }
}

@State<null>({
  name: 'appStatePlaceholder',
})
@Injectable()
export class AppState {
  static props = createPropertySelectors((state: AppStateModel) => state);
  static routerProps = createPropertySelectors(AppState.props.router);
  static routerStateProps = createPropertySelectors(AppState.routerProps.state);

  constructor(private http: HttpClient) {}

  @Action(SaveFile)
  async saveFile(_: StateContext<AppState>, act: SaveFile) {
    if (act.payload.data instanceof Blob) {
      saveAs(act.payload.data, act.payload.filename);
    } else {
      const response = await this.http
        .get(`data:${act.payload.contentType};base64,${act.payload.data}`, {
          observe: 'response',
          responseType: 'blob',
        })
        .toPromise();
      saveAs(response.body, act.payload.filename);
    }
  }

  @Action(XlsxExport)
  async xlsxExport(_: StateContext<AppState>, act: XlsxExport) {
    const wb: XLSX.WorkBook = XLSX.utils.book_new();

    act.payload.sheets.forEach((sheet) => {
      const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(sheet.data);
      XLSX.utils.book_append_sheet(wb, ws, sheet.name);
    });

    XLSX.writeFile(wb, act.payload.fileName);
  }
}
