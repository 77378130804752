import { Agency } from './agency.model';
import { Program } from './program.model';

export class AgencyGrossCommissionReportRequest {
  //#region : Constructor :
  constructor() {
    this.agency = null;
  }
  //#endregion

  //#region : Properties :
  public userName: string;
  public programCode: string;
  public agentCode: string;
  public agency?: Agency;
  public priorMonthsToInclude: number;
  public forInceptionToDate: boolean;
  public useSefi: boolean;
  public program: Program = null;
  //#endregion
}
