import { BankAccountType } from './enums';

export class DealerBankAccount {
  //#region : Constructor :
  constructor() {}
  //#endregion

  //#region : Properties :
  public dealerBankAccountID: number;
  public dealerCode: string;
  public programCode: string;
  public bankName: string;
  public routingNumber: string;
  public accountNumber: string;
  public bankAccountType: BankAccountType;
  public bankAccountTypeName: string;
  public mbpiIsAuthorized: boolean;
  //#endregion
}
