import { VoidReasonType } from './enums';

export class VoidReason {
  //#region : Constructor :
  constructor(voidReasonType?: VoidReasonType, name?: string) {
    this.voidReasonType = voidReasonType;
    this.name = name;
  }
  //#endregion

  //#region : Properties :
  public voidReasonType: VoidReasonType;
  public name: string;
  //#endregion
}
