<ng-container *ngIf="viewModel$ | async as vm">
  <nb-reveal-card>
    <nb-card-front>
      <nb-card [accent]="vm.accent" [status]="vm.status" [size]="cardSize">
        <nb-card-header class="header">
          <div style="display: flex; justify-content: space-between">
            <div>{{ contract.customer?.firstName | titlecase }} {{ contract.customer?.lastName | titlecase }}</div>
            <span class="sub-header">{{ contract.contractNumber }}</span>
          </div>
          <div *ngIf="contract.dealer?.expirationDate && vm.currentUserIsMBPI" class="expired-dealer-header">
            <div style="text-align: center">Dealer Expired</div>
          </div>
        </nb-card-header>
        <nb-card-body class="card-body">
          <div>
            <label>VIN</label>
            <span>{{ contract.vehicle?.vin }}</span>
          </div>
          <div>
            <label>Year / Make</label>
            <span>{{ contract.vehicle?.vehicleYear }} / {{ contract.vehicle?.makeName }}</span>
          </div>
          <div>
            <label>Program</label>
            <span>{{ ProgramType[contract.programType] }}</span>
          </div>
          <div>
            <label>Plan</label>
            <span>{{ contract.planDescription }}</span>
          </div>
          <div>
            <label>Status</label>
            <span>{{ contract.contractStatusType | enumAsString: ContractStatusType }}</span>
          </div>
        </nb-card-body>
        <nb-card-footer class="footer">
          <nb-actions size="small">
            <nb-action [nbSpinner]="downloadClicked" title="Download" icon="download-outline" (click)="onDownload()"></nb-action>

            <nb-action
              *ngIf="vm.showPrintCancelForm"
              [nbSpinner]="printCancelFormClicked"
              title="Print Cancel Form"
              icon="slash-outline"
              (click)="onPrintCancelForm()"
            ></nb-action>
            <nb-action
              *ngIf="vm.showRefreshFromAs400"
              [nbSpinner]="refreshFromAs400Clicked"
              title="Refresh from AS400"
              icon="refresh-outline"
              (click)="onRefreshFromAs400()"
            ></nb-action>
            <nb-action
              *ngIf="vm.showCancelQuote"
              [nbSpinner]="cancelQuoteClicked"
              title="Start a Cancel Quote"
              icon="arrow-circle-right-outline"
              (click)="onStartCancelQuote()"
            ></nb-action>
            <nb-action
              *ngIf="vm.href"
              title="Start a Claim"
              [icon]="car_repair"
              [link]="vm.href"
              (click)="ContractSearchWindowClose.dispatch()"
            ></nb-action>
          </nb-actions>
        </nb-card-footer>
      </nb-card>
    </nb-card-front>
    <nb-card-back>
      <nb-card [accent]="vm.accent" [status]="vm.status" [size]="cardSize">
        <nb-card-header class="header">
          {{ contract.customer?.firstName | titlecase }} {{ contract.customer?.lastName | titlecase }}
          <span class="sub-header">{{ contract.contractNumber }}</span>
          <div *ngIf="contract.dealer?.expirationDate && vm.currentUserIsMBPI" class="expired-dealer-header">
            <div style="text-align: center">Dealer Expired</div>
          </div>
        </nb-card-header>
        <nb-card-body class="card-body">
          <div>
            <label>Secondary Name</label>
            <span> {{ contract.customer?.secondaryFirstName | titlecase }} {{ contract.customer?.secondaryLastName | titlecase }} </span>
          </div>
          <div>
            <label>Address</label>
            <span>{{ contract.customer?.address1 }}</span>
          </div>
          <div>
            <label>City, State, Zip</label>
            <span
              >{{ contract.customer?.city | titlecase }}, {{ contract.customer?.stateAbbreviation }} {{ contract.customer?.zipCode }}</span
            >
          </div>
          <div>
            <label>Phone</label>
            <span>{{ (contract.customer?.homePhone | phone) || (contract.customer?.homePhoneNumber | phone) }}</span>
          </div>
          <div>
            <label>Email</label>
            <span>{{ contract.customer?.emailAddress }}</span>
          </div>
          <hr />
          <div>
            <label>Dealer</label>
            <span>
              {{ contract.dealer?.name | titlecase }}
            </span>
          </div>
          <div>
            <label>Dealer Code</label>
            <span> {{ contract.dealer?.dealerCode }} </span>
          </div>
          <div>
            <label>Dealer Phone</label>
            <span>
              {{ contract.dealer?.phoneNumber | phone }}
            </span>
          </div>
          <ng-container *ngIf="contract.dealer?.expirationDate && vm.currentUserIsMBPI">
            <div>
              <label>Expiration</label>
              <span>
                {{ contract.dealer?.expirationDate | date: 'MM/dd/yyyy' }}
                <span class="expiration-date"><nb-icon icon="alert-circle-outline" pack="eva"></nb-icon></span>
              </span>
            </div>
          </ng-container>
          <hr />
          <div>
            <label>Program</label>
            <span>{{ ProgramType[contract.programType] }}</span>
          </div>
          <div>
            <label>Plan</label>
            <span>{{ contract.planDescription | titlecase }}</span>
          </div>
          <div>
            <label>Coverage</label>
            <span>{{ vm.coverageDisplay }}</span>
          </div>
          <div>
            <label>Deductible</label>
            <span>{{ contract.deductibleAmount | currency }}</span>
          </div>
          <div>
            <label>Purchase Date</label>
            <span>{{ contract.purchaseDate | date: 'MM/dd/yyyy' }}</span>
          </div>
          <div>
            <label>Expiration</label>
            <span>{{ contract?.expirationDate | date: 'MM/dd/yyyy' }} {{ vm.expirationDisplay }}</span>
          </div>
          <hr />
          <div *ngIf="contract.lender?.name">
            <label>Lender</label>
            <span>{{ contract.lender?.name | titlecase }}</span>
          </div>
          <div *ngIf="contract.lender?.totalFinanceAmount">
            <label>Financed</label>
            <span>{{ contract.lender?.totalFinanceAmount | numeral: '$0,0.00' }}</span>
          </div>
          <hr />
          <div>
            <label>VIN</label>
            <span>{{ contract.vehicle?.vin }}</span>
          </div>
          <div>
            <label>Vehicle</label>
            <span>
              <ng-container *ngIf="contract?.vehicle">
                {{ contract.vehicle?.vehicleYear }}
                {{ contract.vehicle?.makeName | titlecase }} {{ contract.vehicle?.modelName | titlecase }} ({{
                  contract.vehicle?.cylindersNumber
                }}
                Cyl, {{ contract.vehicle?.numberWheelsDriving }}-Wheel Drive, {{ contract.vehicle?.fuelTypeCode }} Engine)
              </ng-container>
            </span>
          </div>
          <div>
            <label>Odometer</label>
            <span>{{ contract.vehicle?.saleMileage | number }}</span>
          </div>
          <hr />
          <div *ngIf="contract.contractStatusType === ContractStatusType.Cancelled">
            <label>Cancel As Of</label>
            <span>{{ contract.cancelAsOfDate | date: 'MM/dd/yyyy' }}</span>
          </div>
          <div *ngIf="contract.contractStatusType === ContractStatusType.Cancelled">
            <label>Cancel Transaction</label>
            <span>{{ contract.cancelTransactionDate | date: 'MM/dd/yyyy' }}</span>
          </div>
          <div *ngIf="contract.contractStatusType === ContractStatusType.Cancelled">
            <label>Cancel Miles</label>
            <span>{{ contract.vehicleMilesWhenCancel | number }}</span>
          </div>
          <div *ngIf="contract.contractStatusType === ContractStatusType.Cancelled">
            <label>Cancel %</label>
            <span>{{ contract.cancelRefundPercent | percent: '1.4-4' }}</span>
          </div>
          <div *ngIf="contract.contractStatusType === ContractStatusType.Cancelled">
            <label>Cancel Fee</label>
            <span>{{ contract.cancelFeeAmount | currency }}</span>
          </div>
          <div *ngIf="contract.contractStatusType === ContractStatusType.Cancelled">
            <label>Cancel Reason</label>
            <span>{{ contract.cancelReason }}</span>
          </div>
          <div *ngIf="vm.currentUserIsMBPI || vm.currentUserIsAgent">
            <label>Customer Cost</label>
            <span>{{ contract.customerPrice | currency }}</span>
          </div>
          <div *ngIf="contract.contractStatusType === ContractStatusType.Cancelled && contract.isUserAllowedAccess">
            <label>Cust Refund Amt</label>
            <span>{{ contract.customerRefundAmount | currency }}</span>
          </div>
          <div *ngIf="contract.contractStatusType === ContractStatusType.Cancelled && contract.isUserAllowedAccess">
            <label>MBP Refund Amt</label>
            <span>{{ contract.mbpiRefundAmount | currency }}</span>
          </div>
          <div *ngIf="contract.contractStatusType === ContractStatusType.Cancelled && contract.isUserAllowedAccess">
            <label>Dlr Refund Amt</label>
            <span>{{ contract.dealerRefundAmount | currency }}</span>
          </div>
          <div *ngIf="vm.currentUserIsMBPI || contract.isUserAllowedAccess">
            <label>Dealer Cost</label>
            <span>{{ contract.dealerCost | currency }}</span>
          </div>
          <ng-container *ngIf="vm.currentUserIsMBPI || vm.currentUserIsAgent">
            <hr />
            <label>Notes</label>
            <div *ngFor="let note of contract.notes">
              <label>{{ note?.enteredDate | date: 'MM/dd/yyyy' }}</label>
              <span>{{ note?.text }}</span>
            </div>
          </ng-container>
          <ng-container *ngIf="vm.currentUserIsMBPI">
            <hr />
            <mbp-contract-card-form-code [contract]="contract"></mbp-contract-card-form-code>
          </ng-container>
        </nb-card-body>
      </nb-card>
    </nb-card-back>
  </nb-reveal-card>
</ng-container>
