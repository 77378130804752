import { SearchCriteriaPageType } from './enums';

export class ServicePageEvent {
  //#region : Constructor :
  constructor(searchCriteriaPageType?: SearchCriteriaPageType, eventData?: any) {
    this.searchCriteriaPageType = searchCriteriaPageType;
    this.eventData = eventData;
  }
  //#endregion

  //#region : Properties :
  public searchCriteriaPageType: SearchCriteriaPageType;
  public eventData: any;
  //#endregion
}
