import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import {
  ApiResponse,
  CancelQuoteContractSearchRequest,
  CancelQuoteRequest,
  CancelQuoteResult,
  Contract,
  ContractSearchRequest,
  CustomerContractsRequest,
  FileDownloadResponse,
  GenerateCancelFormRequest,
  RefreshContractRequest,
} from '@mbp/core';
import { PORTAL_API_BASE_URL } from '../core.module';
import { getApiResponseData } from '../rxjs/get-api-response-data';

@Injectable({
  providedIn: 'root',
})
export class ContractsService {
  constructor(private http: HttpClient, @Inject(PORTAL_API_BASE_URL) private portalApiBaseUrl: string) {}

  exportCancelQuotePdf(request: GenerateCancelFormRequest) {
    return this.http
      .put<ApiResponse<FileDownloadResponse>>(`${this.portalApiBaseUrl}/api/pdf/cancelquote`, request)
      .pipe(getApiResponseData());
  }

  generateCancelFormPdf(request: GenerateCancelFormRequest) {
    return this.http
      .put<ApiResponse<FileDownloadResponse>>(`${this.portalApiBaseUrl}/api/pdf/cancelform`, request)
      .pipe(getApiResponseData());
  }

  getCancelQuote(request: CancelQuoteRequest) {
    return this.http.put<ApiResponse<CancelQuoteResult>>(`api/contracts/cancelquote`, request).pipe(getApiResponseData());
  }

  getContracts(request: ContractSearchRequest) {
    return this.http.put<ApiResponse<Contract[]>>(`api/contracts/search`, request).pipe(getApiResponseData());
  }

  getContractsForCancelQuote(request: CancelQuoteContractSearchRequest) {
    return this.http.put<ApiResponse<Contract[]>>(`api/contracts/searchforcancelquote`, request).pipe(getApiResponseData());
  }

  getCustomerContracts(request: CustomerContractsRequest) {
    return this.http.put<ApiResponse<Contract[]>>(`api/contracts/forcustomer`, request).pipe(getApiResponseData());
  }

  refreshContract(request: RefreshContractRequest) {
    return this.http.put<ApiResponse<boolean>>(`api/contracts/flagforrefresh`, request).pipe(getApiResponseData());
  }

  getActualContractFormName(request: { contractNumber: string; dealerCode: string }) {
    return this.http.put<ApiResponse<any>>(`api/contracts/ActualContractFormName`, request).pipe(getApiResponseData());
  }
}
