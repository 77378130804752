import { DealerMinimal } from './dealer-minimal';

export class RaterHistoryRequest {
  //#region : Constructor :
  constructor(dealerCode?: string, programCode?: string, startDate?: Date, endDate?: Date, useSefi?: boolean) {
    this.dealerCode = dealerCode;
    this.programCode = programCode;
    this.startDate = startDate;
    this.endDate = endDate;
    this.dealer = null;
    this.useSefi = useSefi;
  }
  //#endregion

  //#region : Properties :
  public userName: string;
  public dealerCode: string;
  public programCode: string;
  public startDate: Date;
  public endDate: Date;
  public dealer: DealerMinimal;
  public useSefi: boolean;
  //#endregion
}
