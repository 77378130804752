import {
  MBPFinancePlan,
  MBPFinancePlansRequest,
  Program,
  ProgramsRequest,
  VehicleInformation,
  VehicleInformationRequest,
  VehicleMake,
  VehicleMakesRequest,
  VehicleModel,
  VehicleModelsRequest,
} from '@mbp/core';

export namespace LookupActions {
  export class FetchVehicleInformation {
    public static readonly type = '[Lookup API] Fetch Vehicle Information';
    constructor(public payload: VehicleInformationRequest) {}
  }
  export class FetchVehicleInformationSuccess {
    public static readonly type = '[Lookup API] Fetch Vehicle Information Successful';
    constructor(public payload: VehicleInformation) {}
  }
  export class FetchVehicleInformationError {
    public static readonly type = '[Lookup API] Fetch Vehicle Information Error';
    constructor(public payload: VehicleInformation | void) {}
  }

  export class FetchVehicleMakes {
    public static readonly type = '[Lookup API] Fetch Vehicle Makes';
    constructor(public payload: VehicleMakesRequest) {}
  }

  export class FetchVehicleMakesSuccess {
    public static readonly type = '[Lookup API] Fetch Vehicle Makes Success';
    constructor(public payload: VehicleMake[]) {}
  }

  export class FetchVehicleMakesError {
    public static readonly type = '[Lookup API] Fetch Vehicle Makes Error';
    constructor(public payload: Error | any) {}
  }

  export class FetchVehicleModels {
    public static readonly type = '[Lookup API] Fetch Vehicle Models';
    constructor(public payload: VehicleModelsRequest) {}
  }

  export class FetchVehicleModelsSuccess {
    public static readonly type = '[Lookup API] Fetch Vehicle Models Success';
    constructor(public payload: VehicleModel[]) {}
  }

  export class FetchVehicleModelsError {
    public static readonly type = '[Lookup API] Fetch Vehicle Models Error';
    constructor(public payload: Error | any) {}
  }

  export class FetchFinancePlans {
    public static readonly type = '[Lookup API] Fetch MBP Finance Plans';
    constructor(public payload: MBPFinancePlansRequest) {}
  }

  export class FetchFinancePlansSuccess {
    public static readonly type = '[Lookup API] Fetch MBP Finance Plans Success';
    constructor(public payload: MBPFinancePlan[]) {}
  }

  export class FetchFinancePlansError {
    public static readonly type = '[Lookup API] Fetch MBP Finance Plans Error';
    constructor(public payload: Error | any) {}
  }
  export class FetchProgramsForDealer {
    public static readonly type = '[Lookup API] Fetch Programs For Dealer';
    constructor(public payload: ProgramsRequest) {}
  }

  export class FetchProgramsForDealerSuccess {
    public static readonly type = '[Lookup API] Fetch Programs For Dealer Success';
    constructor(public payload: Program[]) {}
  }

  export class FetchProgramsForDealerError {
    public static readonly type = '[Lookup API] Fetch Programs For Dealer Error';
    constructor(public payload: Error | any) {}
  }
}
