export class GenerateRemittanceReportRequest {
  //#region : Constructor :
  constructor() {}
  //#endregion

  //#region : Properties :
  public userName: string;
  public dealerCode: string;
  public remitGroupIdentifier: string;
  public useSefi: boolean;
  //#endregion
}
