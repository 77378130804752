import { InternalMessage } from './internal-message.model';

export class SaveInternalMessageRequest {
  //#region : Constructor :
  constructor() {}
  //#endregion

  //#region : Properties :
  public userName: string;
  public message: InternalMessage;
  //#endregion
}
