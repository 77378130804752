import { RaterHistory } from './rater-history.model';

export class RaterHistoryActionEventItem {
  //#region : Constructor :
  constructor(dataItem?: RaterHistory, actionName?: string) {
    this.actionName = actionName;
    this.dataItem = dataItem;
  }
  //#endregion

  //#region : Properties :
  public actionName: string;
  public dataItem: RaterHistory;
  //#endregion
}
