import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  GetLegacyPolicyContractDocument,
  GetLegacyPolicyContractQueryVariables,
  GetPolicyContractDocument,
  GetPolicyContractForLinkDocument,
  GetPolicyContractForLinkQueryVariables,
  GetPolicyContractQueryVariables,
  GetPolicyContractsDocument,
  GetPolicyContractsQueryVariables,
} from './generated/graphql';
import { makeQuery } from './make-query';

@Injectable({
  providedIn: 'root',
})
export class ContractsGraphql {
  constructor(private http: HttpClient) {}

  getPolicyContracts = (variables: GetPolicyContractsQueryVariables) =>
    makeQuery({
      http: this.http,
      operation: GetPolicyContractsDocument,
      variables,
    });

  getPolicyContract = (variables: GetPolicyContractQueryVariables) =>
    makeQuery({
      http: this.http,
      operation: GetPolicyContractDocument,
      variables,
    });

  getLegacyPolicyContract = (variables: GetLegacyPolicyContractQueryVariables) =>
    makeQuery({
      http: this.http,
      operation: GetLegacyPolicyContractDocument,
      variables,
    });

  getPolicyContractForLink = (variables: GetPolicyContractForLinkQueryVariables) =>
    makeQuery({
      http: this.http,
      operation: GetPolicyContractForLinkDocument,
      variables,
    });
}
