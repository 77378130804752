import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { WINDOW } from '@mbp/core';

@Component({
  selector: 'mbp-ie-detector',
  templateUrl: './ie-detector.component.html',
  styleUrls: ['./ie-detector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IeDetectorComponent implements OnInit {
  isIE = false;

  constructor(@Inject(WINDOW) private window: Window) {}

  ngOnInit(): void {
    this.isIE = /Trident\/|MSIE/.test(this.window.navigator.userAgent);
  }
}
