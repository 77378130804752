import { Injectable } from '@angular/core';
import { environment } from '@mbp/environment';
// import { VERSION } from '@mbp/version';
import { NgxsNextPluginFn, NgxsPlugin, Store } from '@ngxs/store';
import * as LogRocket from 'logrocket';
import { filter } from 'rxjs/operators';
import { User } from '../models/user.model';
import { AUTH_STATE_TOKEN } from '../state/auth.state';

@Injectable({
  providedIn: 'root',
})
export class LogRocketService {
  constructor(private store: Store) {}
  init() {
    if (environment.logRocket.on === 'true') {
      LogRocket.init(environment.logRocket.appId, {
        // release: `v${VERSION.semver?.version}-${VERSION.hash}-${environment.env}`,
        // console: {
        // shouldAggregateConsoleErrors: true,
        // },
      });
      this.store
        .select(AUTH_STATE_TOKEN)
        .pipe(filter((authState) => !!authState?.user))
        .subscribe((authState) => logRocketIdentify(authState?.user));
    }
  }
}

export const logRocketIdentify = (user: User) =>
  !!user &&
  LogRocket.identify(user.id, {
    name: user.fullName,
    email: user.emailAddress,
    roleType: user.roleType,
    agentCode: user.agentCode,
    dealerCode: user.dealerCode,
    userProfileID: user.userProfileID,
    // appVersion: VERSION.semver?.version,
  });

@Injectable()
export class MbpLogRocketPlugin implements NgxsPlugin {
  handle(state: any, action: any, next: NgxsNextPluginFn) {
    return next(state, action);
    // .pipe(
    //   tap((nextState: object) => {
    //     LogRocket.log(getActionTypeFromInstance(action), nextState);
    //   }),
    //   catchError((error) => {
    //     LogRocket.error(getActionTypeFromInstance(action), error);
    //     return of(error);
    //   }),
    // );
  }
}
