import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { ControlValueAccessor, FormGroup, FormGroupDirective, NG_VALUE_ACCESSOR } from '@angular/forms';
import { AgenciesRequest, Agency, AppStateModel, fadeInOut, LookupService } from '@mbp/core';
import { Store } from '@ngxs/store';

@Component({
  selector: 'mbp-agent-autocomplete',
  templateUrl: './agent-autocomplete.component.html',
  styleUrls: ['./agent-autocomplete.component.scss'],
  animations: [fadeInOut],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AgentAutoCompleteComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AgentAutoCompleteComponent implements OnInit, OnDestroy, ControlValueAccessor {
  //#region : Constructor :
  constructor(
    private parent: FormGroupDirective,
    private store: Store,
    private lookupService: LookupService,
    private changeDetector: ChangeDetectorRef,
  ) {}
  //#endregion

  //#region : Properties :
  get value() {
    return this.selectedAgent;
  }

  set value(val) {
    this.selectedAgent = val;
    this.searchText = this.selectedAgent != null ? this.selectedAgent.name + ' (' + this.selectedAgent.agentCode + ')' : '';
    // this.onChange(val);
    // this.onTouched();
  }
  //#region : Variable Declarations :

  agentsRequest: AgenciesRequest = new AgenciesRequest();

  isLoadingAgents = true;
  loadFailed = false;
  agents: Agency[] = [];
  filteredAgents: Agency[] = [];
  parentFormGroup: FormGroup;
  // currentSearchText: string = "";

  @Input() placeholder = 'Select an agency';
  @Input('value') selectedAgent: Agency = null;
  @Input() selectedAgentCode: string = null;
  @Input() searchText: string = null;
  @Input() isRequired = true;
  @Output() agentChanged: EventEmitter<Agency> = new EventEmitter();
  @Output() singleAgentLoaded: EventEmitter<boolean> = new EventEmitter();

  onChange: any = () => {};
  onTouched: any = () => {};
  //#endregion

  //#region : Implementation Methods :
  ngOnInit() {
    this.parentFormGroup = this.parent.form;

    setTimeout(() => {
      this.loadLookupData();
    }, 500);
  }

  ngOnDestroy() {}

  registerOnChange(fn) {
    this.onChange = fn;
  }

  registerOnTouched(fn) {
    this.onTouched = fn;
  }

  writeValue(value) {
    if (value) {
      this.value = value;
    }
  }
  //#endregion

  //#region : Methods :
  agentSearchDisplay(agent: Agency) {
    if (agent) {
      return agent.name + ' (' + agent.agentCode + ')';
    }
  }

  loadLookupData() {
    this.isLoadingAgents = true;
    this.loadFailed = false;
    this.agentsRequest.userName = this.store.selectSnapshot((state: AppStateModel) => state.auth?.user?.username);
    this.lookupService.getAgencies(this.agentsRequest).subscribe(
      (data) => this.onAgentsLoadSuccessful(data),
      (error) => this.onAgentsLoadFailed(error),
    );
  }

  onAgentsLoadSuccessful(agentsData: Agency[]) {
    let data = [];

    // TEST CODE
    if (this.store.selectSnapshot((state: AppStateModel) => state.auth?.user?.username) == 'testagent') {
      agentsData.forEach((a, i) => {
        a.name = 'Test Agency';
      });
    }
    // END TEST CODE

    if (agentsData != null) {
      data = agentsData;
    }

    this.agents = data;
    this.isLoadingAgents = false;
    this.loadFailed = false;
    if (this.selectedAgentCode != null) {
      this.selectAgentByCode();
    }
    if (this.selectedAgentCode == null) {
      this.onValueChanged(this.searchText);
    }
    if (this.agents.length == 1) {
      this.singleAgentLoaded.emit(true);
      this.onAgentSelected(this.agents[0]);
    }
    this.changeDetector.detectChanges();
  }

  onAgentsLoadFailed(error: any) {
    this.isLoadingAgents = false;
    this.loadFailed = true;
    this.changeDetector.detectChanges();
  }

  onAgentSelected(agent: Agency) {
    this.selectedAgent = agent;
    // Going to set this inside this (child) control.  That way the parent doesn't have to do it.
    // But, the parent form needs to have the 'agent' and 'agentCode' keys
    // Otherwise, the parent needs to add this to the child control definition:  (selectedAgentChange)="onAgentChange($event)"
    // and then have the 'onAgentChange' function
    this.parentFormGroup.patchValue({ agency: agent, agentCode: agent != null ? agent.agentCode : null });

    this.agentChanged.emit(agent);
    this.changeDetector.detectChanges();
  }

  onValueChanged(searchText?: string) {
    if (searchText && searchText.length > 0) {
      const subset = this.agents.filter((agent) => {
        return (
          agent.name.toLowerCase().startsWith(searchText.toLowerCase()) ||
          agent.agentCode.toLowerCase().startsWith(searchText.toLowerCase()) ||
          searchText.toLowerCase().startsWith(agent.name.toLowerCase())
        );
      });
      this.filteredAgents = subset.length > 30 ? subset.slice(0, 30) : subset;
    } else {
      this.filteredAgents = [];
    }

    this.searchText = searchText;
    if (this.filteredAgents.length == 0 && this.selectedAgent != null) {
      this.onAgentSelected(null);
    }
  }

  reload() {
    this.loadLookupData();
    this.searchText = '';
    this.onAgentSelected(null);
    this.changeDetector.detectChanges();
  }

  public reset(setFocus?: boolean) {
    this.searchText = '';
    this.onAgentSelected(null);
    this.filteredAgents = [];
    this.changeDetector.detectChanges();
  }

  selectAgentByCode() {
    const foundAgency: Agency = this.agents.find((a) => a.agentCode == this.selectedAgentCode);
    if (foundAgency != null) {
      this.onAgentSelected(foundAgency);
    }
  }
}
