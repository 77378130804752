import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { DealerMinimal } from '../models/dealer-minimal';
import { SearchCriteriaPageType } from '../models/enums';
import { ServicePageEvent } from '../models/service-page-event.model';

@Injectable({
  providedIn: 'root',
})
export class RightSidebarService {
  private dealer = new BehaviorSubject<DealerMinimal>(null);
  public dealer$ = this.dealer.asObservable();

  private searchCriteria = new BehaviorSubject<ServicePageEvent>(null);
  public searchCriteria$ = this.searchCriteria.asObservable();

  private runImmediately = new BehaviorSubject<ServicePageEvent>(null);
  public runImmediately$ = this.runImmediately.asObservable();

  private data = new BehaviorSubject<ServicePageEvent>(null);
  public data$ = this.data.asObservable();

  public setDealer(dealer: DealerMinimal) {
    this.dealer.next(dealer);
  }

  public setData(pageType: SearchCriteriaPageType, data: any) {
    this.data.next(new ServicePageEvent(pageType, data));
  }

  public setRunImmediately(pageType: SearchCriteriaPageType, data?: any) {
    this.runImmediately.next(new ServicePageEvent(pageType, data));
  }

  public setSearchCriteria(pageType: SearchCriteriaPageType, searchCriteria: any): void {
    this.searchCriteria.next(new ServicePageEvent(pageType, searchCriteria));
  }
}
