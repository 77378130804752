export class RaterMenuRequest {
  //#region : Constructor :
  constructor(userName?: string, dealerCode?: string) {
    this.dealerCode = dealerCode;
    this.userName = userName;
  }
  //#endregion

  //#region : Properties :
  public userName: string;
  public dealerCode: string;
  //#endregion
}
