import { AfterViewInit, ChangeDetectionStrategy, Component, OnInit, ViewChild } from '@angular/core';
import { Contract, NoopAction, fadeInOut } from '@mbp/core';
import { NbPopoverDirective } from '@nebular/theme';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { ViewSelectSnapshot } from '@ngxs-labs/select-snapshot';
import { Actions, Select, Store, ofActionCompleted } from '@ngxs/store';
import {
  ContractSearchHelpHide,
  ContractSearchHelpToggle,
  ContractSearchOnClear,
  ContractSearchOnSearch,
} from 'app/core/state/contract-search.actions';
import { ContractSearchState } from 'app/core/state/contract-search.state';
import { BehaviorSubject, Observable } from 'rxjs';
import { distinctUntilChanged, withLatestFrom } from 'rxjs/operators';
import { ContractSearchHelpComponent } from '../contract-search-help/contract-search-help.component';
import { ContractSearchQueries } from './contract-search.queries';

@UntilDestroy()
@Component({
  selector: 'mbp-contract-search',
  templateUrl: './contract-search.component.html',
  styleUrls: ['./contract-search.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [fadeInOut],
})
export class ContractSearchComponent implements OnInit, AfterViewInit {
  @ViewSelectSnapshot(ContractSearchState.props.searchText) searchText: string;
  @Select(ContractSearchQueries.getViewModel) viewModel$: Observable<ReturnType<typeof ContractSearchQueries.getViewModel>>;
  @ViewChild(NbPopoverDirective) popover: NbPopoverDirective;

  constructor(private actions$: Actions, private store: Store) {}

  searchMessage$ = new BehaviorSubject<string>(null);
  ContractSearchHelpComponent = ContractSearchHelpComponent;

  ngOnInit(): void {
    this.actions$
      .pipe(ofActionCompleted(ContractSearchOnSearch), withLatestFrom(this.viewModel$), untilDestroyed(this))
      .subscribe(([_, vm]) => {
        if (!vm.contracts?.length) this.searchMessage$.next('No contracts found.');
        else this.searchMessage$.next(null);
      });
  }

  ngAfterViewInit(): void {
    this.store
      .select(ContractSearchState.props.showContractSearchHelp)
      .pipe(distinctUntilChanged(), untilDestroyed(this))
      .subscribe((showContractSearchHelp) => {
        if (showContractSearchHelp) {
          this.popover.show();
        } else {
          this.popover.hide();
        }
      });
  }

  trackByFn(_: number, contract: Contract) {
    return contract.contractNumber;
  }

  @Dispatch()
  search(searchText: string) {
    if (!searchText) return new NoopAction();
    this.searchMessage$.next('Searching...');
    return new ContractSearchOnSearch(searchText);
  }

  @Dispatch()
  clear() {
    this.searchMessage$.next(null);
    return new ContractSearchOnClear();
  }

  @Dispatch()
  onHelpToggle() {
    return new ContractSearchHelpToggle();
  }

  @Dispatch()
  onHelpHide() {
    return new ContractSearchHelpHide();
  }
}
