export class InternalMessagesRequest {
  //#region : Constructor :
  constructor() {}
  //#endregion

  //#region : Properties :
  public userName: string;
  public messageTypeID?: number;
  public messageStatusTypeID?: number;
  public searchText?: string;
  public startDate?: Date;
  public endDate?: Date;
  public onlyActiveMessages?: boolean;
  //#endregion
}
