import { RaterMenuProduct } from './rater-menu-product.model';

export class RaterSaveMenuProductsRequest {
  //#region : Constructor :
  constructor() {}
  //#endregion

  //#region : Properties :
  public userName: string;
  public dealerCode: string;
  public menuProducts: RaterMenuProduct[];
  //#endregion
}
