<div class="toolbar-notification-container">
  <button mat-icon-button (click)="isOpen = !isOpen" [ngClass]="[cssPrefix + '-btn']" [class.open]="isOpen">
    <mat-icon>filter_list</mat-icon>
    <span class="badge" *ngIf="hasActiveFilter">*</span>
  </button>

  <div class="dropdown mat-elevation-z4" [class.open]="isOpen">
    <div class="card">
      <div class="header" fxLayout="row" fxLayoutAlign="space-between center">
        <div class="title">
          <div class="name">Dashboard Filter</div>
          <!--<div class="extra">
                        You have the following filter setup
                    </div>-->
        </div>
        <!--<button type="button" mat-icon-button>
                    <mat-icon class="icon">settings</mat-icon>
                </button>-->
      </div>
      <div>
        <perfect-scrollbar class="content">
          <div>
            <div class="notification" fxLayout="row" fxLayoutAlign="start center" mat-ripple>
              <div class="title" fxLayout="column" (click)="viewDealerLookup()">
                <div *ngIf="hasDealerFilter" class="name">{{ currentFilteredDealer.name }} ({{ currentFilteredDealer.dealerCode }})</div>
                <div *ngIf="!hasDealerFilter" class="name">No dealer filter. Touch/click to add one.</div>
              </div>
              <span fxFlex></span>
              <button *ngIf="hasDealerFilter" type="button" mat-icon-button (click)="removeDealerFilter()">
                <mat-icon class="close">clear</mat-icon>
              </button>
              <button type="button" mat-icon-button (click)="viewDealerLookup()">
                <mat-icon class="close">navigate_next</mat-icon>
              </button>
            </div>
            <div class="divider" *ngIf="hasDealerFilter"></div>
          </div>
        </perfect-scrollbar>
      </div>
    </div>
  </div>
</div>

<mbp-dealer-lookup-form
  [isOpen]="showDealerFilter"
  [searchText]="cleanSearchText"
  (cancel)="cancelDealerFilter()"
  (dealerSelect)="onDealerSelected($event)"
></mbp-dealer-lookup-form>
