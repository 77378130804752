export enum AccountPaymentMethodType {
  CreditCard = 1,
  ACH = 2,
  Check = 3,
}

export enum BankAccountType {
  Checking = 1,
  Savings = 2,
}

export enum CancelReasonType {
  VehicleTraded = 1,
  VehicleTotaled,
  VehicleRepossessed,
  LoanPaidInFull,
  Nonpayment,
  CustomerRequest,
  Unwind,
  Other,
  DuplicateContract,
}

export enum CancelRuleType {
  OneDay = 1,
  FourteenDay,
  FifteenDay,
  ThirtyDay,
  UnusedMonths,
  UnusedMiles,
  FinalMonth,
  UnusedDays,
}

export enum ClaimStatusType {
  Approved = 'A',
  Paid = 'C',
  Denied = 'D',
  Pending = 'P',
  Void = 'V',
}

export enum ContractStatusType {
  All = 0,
  Active = 1,
  Cancelled = 2,
  Suspense = 3,
  Expired = 4,
  Denied = 5,
}

export enum CoverageDisplayType {
  Unknown = 0,
  Months = 1,
  Years = 2,
}

export enum CreditCardType {
  Visa = 1,
  AmericanExpress = 2,
  MasterCard = 3,
  Discover = 4,
}

export enum DeductibleType {
  FlatAmount = 1,
  Disappearing = 2,
  Reduced = 3,
}

export enum ElectiveSurchargeType {
  BusinessUse = 2,
  DualRearWheels = 4,
  LiftKit = 8,
}

export enum EnterVehicleByType {
  VIN,
  YearMakeModel,
}

export enum LoanContractType {
  BalloonPayment = 1,
  Lease = 2,
  InstallmentSalesContract = 3,
}

export enum MatchOperatorType {
  Equals = 1,
  NotEquals = 2,
}

export enum MenuGroupType {
  Financed = 1,
  Cash = 2,
}

export enum MenuFeeIncludeType {
  Unknown = 0,
  Yes = 1,
  No = 2,
  OnlyFinanceMenu = 3,
  OnlyCashMenu = 4,
}

export enum MessageStatusType {
  All = 0,
  Unassigned = 1,
  Assigned = 2,
  OnHold = 3,
  InProgress = 4,
  Closed = 5,
  Cancelled = 6,
}

export enum MessageType {
  All = 0,
  NewClaim = 1,
  InspectorCall = 2,
  NewClaimSupervisor = 3,
  ExistingClaim = 4,
  Other = 5,
  PhoneIssue = 6,
}

export enum Months {
  Jan = 1,
  Feb,
  Mar,
  Apr,
  May,
  Jun,
  Jul,
  Aug,
  Sep,
  Oct,
  Nov,
  Dec,
}

export enum ProgramType {
  VSC = 1,
  GAP = 2,
  DentGuard = 4,
  Combo = 8,
  TireAndWheel = 16,
  Technology = 32,
  Powersports = 64,
  VehicleReturnProgram = 128,
  ResistAll = 256,
  Lease = 512,
  Theft = 1024,
  MonthToMonth = 2048,
  PrepaidMaintenance = 4096,
  RVResistAll = 8192,
  IdentityTheft = 16384,
  LimitedWarranty = 32768,
  TheftRecovery = 65536,
  TotalLossCare = 131072,
}

export enum ProgramTypeCodeMap {
  MBP = 1,
  GAP = 2,
  DSD = 4,
  MCR = 8,
  TAW = 16,
  OEM = 32,
  MOT = 64,
  VRP = 128,
  CAL = 256,
  LCA = 512,
  TSW = 1024,
  PYG = 2048,
  PPM = 4096,
  RV = 8192,
  IDT = 16384,
  WAR = 32768,
  AVA = 65536,
  TLC = 131072,
}

export enum ProgramCode {
  MBP = 'MBP',
  GAP = 'GAP',
  DSD = 'DSD',
  MCR = 'MCR',
  TAW = 'TAW',
  TEJ = 'TEJ', // ?
  OEM = 'OEM',
  MOT = 'MOT',
  INS = 'INS',
  VRP = 'VRP',
  CAL = 'CAL',
  LCA = 'LCA',
  TSW = 'TSW',
  PYG = 'PYG',
  PPM = 'PPM',
  RV = 'RV',
  IDT = 'IDT',
  WAR = 'WAR',
  AVA = 'AVA',
  TLC = 'TLC',
}

export enum RoleNameType {
  None = 0,
  MasterAgent = 1,
  SubAgent = 2,
  MasterDealer = 3,
  FIUser = 4,
  ServiceUser = 5,
  MBPIUser = 6,
  MBPIAdministrator = 7,
  SiteAdministrator = 8,
  MBPFinanceUser = 9,
  Customer = 10,
  MBPIRateAdministrator = 11,
  ServiceUserLimited = 12,
  PayGoUser = 13,
}

export enum RoleType {
  MBPIUser = '1',
  Agent = '2',
  Dealer = '3',
  Customer = '4',
}

export enum SalesSummaryType {
  Dealer = 1,
  Agency,
}

export enum SalesSummaryReportType {
  Sales = 1,
  Quote,
}

export enum SalesSummaryReportLengthType {
  Day = 1,
  Week,
  Month,
  Year,
  PreviousDay,
  PreviousWeek,
  PreviousMonth,
  PreviousYear,
  SalesSummary,
}

export enum SearchCriteriaPageType {
  RaterHistory = 1,
  RaterQuote,
  PendingRemittance,
  RemittanceHistory,
  InternalMessages,
  AgencyGrossCommissionReport,
  DealerContractsReport,
  DealerClaimsReport,
  QuoteRequestReport,
  SalesSummaryReport,
  RemittanceSummaryReport,
  NewDealerDisbursementReport,
}

export enum TechnologyType {
  Portal = 1,
  Rater = 4,
}

export enum UserIdentifierType {
  Agent = 1,
  Dealer = 2,
}

export enum UserProfileType {
  StandardUser = 1,
  SubUser,
  SystemUser,
  MenuRateAndPaymentFormatType,
}

export enum MenuRateAndPaymentFormatType {
  OnlyPaymentAtBottom = 1,
  PaymentWithTermAndRateAtBottom = 2,
  PaymentWithTermAtBottom = 3,
  TermAndRateAtBottomAndPaymentAtBottom = 4,
  TermAndRateAtTopAndPaymentAtBottom = 5,
  TermAtTopAndPaymentAtBottom = 6,
}

export enum MenuVehicleOnlyPaymentDisplayPositionType {
  ShowUnderProducts = 1,
  ShowAboveProductsOnLeft = 2,
  ShowAboveProductsOnRight = 3,
  DoNotDisplay = 4,
  ShowAboveProductsInMiddle = 5,
}

export enum VehicleStatusType {
  New = 1,
  Used = 2,
  ManufacturerCertified = 4,
  MBPCertified = 16,
}

export enum VehicleType {
  Unknown = 0,
  Automobile = 1,
  OnRoadMotorcycle = 2,
  OffroadMotorcycleOrATV = 3,
  Watercraft = 4,
  Scooter = 5,
  Motorhome = 6,
  TravelTrailer = 7,
}

export enum VoidReasonType {
  Duplicate = 1,
  Cancellation,
  CustomerBackOut,
  UnableToFund,
}

export enum WeekDayShort {
  Sun,
  Mon,
  Tue,
  Wed,
  Thu,
  Fri,
  Sat,
}

export enum FormMode {
  Create,
  Edit,
  Delete,
}

export enum ContractLengthType {
  All,
  FullTerm,
  ShortTerm,
}
