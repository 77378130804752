import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiResponse, getApiResponseData, NewDealerDisbursement, RemittanceSummary, RemittanceSummaryRequest } from '..';
import { AgencyGrossCommissionReportRequest } from '../models/agency-gross-commission-report-request.model';
import { AgencyGrossCommissionReportSummary } from '../models/agency-gross-commission-report-summary.model';
import { DealerClaimReportRequest } from '../models/dealer-claim-report-request.model';
import { DealerClaimReportSummary } from '../models/dealer-claim-report-summary.model';
import { DealerContractReportRequest } from '../models/dealer-contract-report-request.model';
import { DealerContractReportSummary } from '../models/dealer-contract-report-summary.model';
import { EmailClickUpRequest } from '../models/email-clickup-request.model';
import { NewDealerDisbursementRequest } from '../models/new-dealer-disbursement-request.model';
import { PastWeekSalesSummaryRequest } from '../models/past-week-sales-summary-request.model';
import { PastWeekSalesSummaryResponse } from '../models/past-week-sales-summary-response.model';
import { QuoteRequestInformation } from '../models/quote-request-information.model';
import { QuoteRequestReportRequest } from '../models/quote-request-report-request.model';
import { SalesSummaryReportSummary } from '../models/sales-summary-report-summary.model';
import { SalesSummaryRequest } from '../models/sales-summary-request.model';
import { SCSClaimInfoReport } from '../models/scs-claim-info.model';

@Injectable({
  providedIn: 'root',
})
export class ReportingService {
  constructor(private http: HttpClient) {}

  getAgencyGrossCommissions(request: AgencyGrossCommissionReportRequest) {
    return this.http
      .put<ApiResponse<AgencyGrossCommissionReportSummary>>(`/api/reporting/agencygrosscommissions`, request)
      .pipe(getApiResponseData());
  }

  getDealerClaims(request: DealerClaimReportRequest) {
    return this.http
      .get<ApiResponse<DealerClaimReportSummary>>(`/api/reporting/dealerclaims`, {
        params: { ...request },
      })
      .pipe(getApiResponseData());
  }

  getDealerContracts(request: DealerContractReportRequest) {
    return this.http.put<ApiResponse<DealerContractReportSummary>>(`/api/reporting/dealercontracts`, request).pipe(getApiResponseData());
  }

  // Not currently used.
  getPastWeekSalesSummary(request: PastWeekSalesSummaryRequest) {
    return this.http
      .put<ApiResponse<PastWeekSalesSummaryResponse[]>>(`/api/reporting/pastweeksalessummary`, request)
      .pipe(getApiResponseData());
  }

  getQuoteRequests(request: QuoteRequestReportRequest) {
    return this.http.put<ApiResponse<QuoteRequestInformation[]>>(`/api/reporting/quoterequests`, request).pipe(getApiResponseData());
  }

  getRemittanceSummary(request: RemittanceSummaryRequest) {
    return this.http.put<ApiResponse<RemittanceSummary[]>>(`/api/reporting/remittancesummary`, request).pipe(getApiResponseData());
  }

  getNewDealerDisbursement(request: NewDealerDisbursementRequest) {
    return this.http.put<ApiResponse<NewDealerDisbursement[]>>(`/api/reporting/newdealerdisbursement`, request).pipe(getApiResponseData());
  }

  getSalesSummary(request: SalesSummaryRequest) {
    return this.http.put<ApiResponse<SalesSummaryReportSummary>>(`/api/reporting/salessummary`, request).pipe(getApiResponseData());
  }

  getScsClaimsInfo(claimNumber: string) {
    return this.http
      .get<ApiResponse<SCSClaimInfoReport>>(`/api/reporting/scsclaiminfo`, { params: { claimNumber } })
      .pipe(getApiResponseData());
  }

  getScsClaimList(contractNumber: string) {
    return this.http
      .get<ApiResponse<string[]>>(`/api/reporting/scsclaimlist`, { params: { contractNumber } })
      .pipe(getApiResponseData());
  }

  emailClickUp(request: EmailClickUpRequest) {
    return this.http.put<ApiResponse<null>>(`/api/reporting/emailClickUp`, request);
  }
}
