import { Injectable } from '@angular/core';
import { Action, State, StateContext, StateToken } from '@ngxs/store';
import { patch } from '@ngxs/store/operators';
import { createPropertySelectors } from './ngxs-next';
import {
  SetCustomerMode,
  SetPageHeader,
  SetRightSidenav,
  SidenavBackdropClicked,
  ToggleRightSidenav,
  ToggleSidenav,
  UIMediaQueryListEvent,
} from './ui.actions';

export enum SidenavState {
  closed,
  open,
}

export enum SidenavMode {
  over = 'over',
  side = 'side',
}

export interface UIStateModel {
  mobileView: boolean;
  sidenavOpened: boolean;
  sidenavMode: SidenavMode;
  showToggle: boolean;
  rightSidenavOpened: boolean;
  inCustomerMode: boolean;
  pageHeader: string;
}

export const UI_STATE_TOKEN = new StateToken<UIStateModel>('ui');

@State<UIStateModel>({
  name: UI_STATE_TOKEN,
  defaults: {
    mobileView: false,
    sidenavOpened: true,
    sidenavMode: SidenavMode.side,
    showToggle: false,
    rightSidenavOpened: false,
    inCustomerMode: false,
    pageHeader: null,
  },
})
@Injectable()
export class UIState {
  static props = createPropertySelectors(UI_STATE_TOKEN);

  @Action(UIMediaQueryListEvent)
  uIMediaQueryListEvent({ patchState }: StateContext<UIStateModel>, { payload }: UIMediaQueryListEvent) {
    patchState(payload);
  }

  @Action(ToggleSidenav)
  toggleSidenav({ getState, patchState }: StateContext<UIStateModel>) {
    const state = getState();
    patchState({
      sidenavOpened: !state.sidenavOpened,
    });
  }

  @Action(SetRightSidenav)
  setRightSidenav({ patchState }: StateContext<UIStateModel>, { payload }: SetRightSidenav) {
    patchState({
      rightSidenavOpened: !!payload,
    });
  }

  @Action(ToggleRightSidenav)
  toggleRightSidenav({ getState, patchState }: StateContext<UIStateModel>) {
    const state = getState();
    patchState({
      rightSidenavOpened: !state.rightSidenavOpened,
    });
  }

  @Action(SidenavBackdropClicked)
  sidenavBackdropClicked({ getState, patchState }: StateContext<UIStateModel>) {
    const state = getState();
    patchState({
      sidenavOpened: !state.mobileView,
      rightSidenavOpened: false,
    });
  }

  @Action(SetCustomerMode)
  setCustomerMode({ patchState }: StateContext<UIStateModel>, { payload }: SetCustomerMode) {
    patchState({
      inCustomerMode: payload,
    });
  }

  @Action(SetPageHeader)
  setPageHeader(ctx: StateContext<UIStateModel>, { payload: { pageHeader } }: SetPageHeader) {
    ctx.setState(
      patch<UIStateModel>({
        pageHeader,
      }),
    );
  }
}
