import { UserProfileType } from './enums';

export class PortalUsersRequest {
  //#region : Constructor :
  constructor(forUserName?: string, userProfileType?: UserProfileType, searchText?: string, onlyMBPUsers?: boolean) {
    this.forUserName = forUserName;
    this.userProfileType = userProfileType;
    this.searchText = searchText;
    this.onlyMBPUsers = onlyMBPUsers;
  }
  //#endregion

  //#region : Properties :
  public forUserName: string;
  public userProfileType: UserProfileType;
  public searchText: string;
  public onlyMBPUsers: boolean;
  //#endregion
}
