import { Injectable } from '@angular/core';
import { Action, createSelector, State, StateContext, StateToken, Store } from '@ngxs/store';
import { filter, take, tap } from 'rxjs/operators';
import { SystemService } from '../api/system.service';
import { AppStateModel } from '../models/app-state.model';
import { Setting } from '../models/setting.model';
import { InitSettings, UpdateSetting } from './settings.actions';

export interface SettingsStateModel {
  [name: string]: Setting;
}

export const SETTINGS_STATE_TOKEN = new StateToken<SettingsStateModel>('settings');

@State({
  name: SETTINGS_STATE_TOKEN,
  defaults: {},
})
@Injectable()
export class SettingsState {
  constructor(private api: SystemService, private store: Store) {}

  public static get(name: string) {
    return createSelector([SETTINGS_STATE_TOKEN], (settings: SettingsStateModel) => settings[name]);
  }

  ngxsAfterBootstrap({ dispatch }: StateContext<SettingsStateModel>): void {
    this.store
      .select((state: AppStateModel) => state.auth.accessToken)
      .pipe(
        filter((accessToken) => !!accessToken),
        take(1),
      )
      .subscribe((_) => dispatch(new InitSettings()));
  }

  @Action(InitSettings)
  toggleSidenav({ patchState }: StateContext<SettingsStateModel>) {
    return this.api.getSettings().pipe(
      tap((settings) => {
        patchState((settings ?? []).reduce((map, obj) => ((map[obj.name] = obj), map), {}));
      }),
    );
  }

  @Action(UpdateSetting)
  updateSetting({ patchState }: StateContext<SettingsStateModel>, { payload }: UpdateSetting) {
    patchState({
      [payload.name]: payload,
    });
  }
}
