import { MenuFeeIncludeType } from './enums';

export class RaterMenuDealerFee {
  //#region : Constructor :
  constructor() {
    this.name = '';
    this.feeAmount = 0;
    this.applySalesTax = false;
    this.includeType = MenuFeeIncludeType.Yes;
  }
  //#endregion

  //#region : Properties :

  public feeID: string;
  public name: string;
  public feeAmount: number;
  public applySalesTax: boolean;
  public includeType: MenuFeeIncludeType;

  //#endregion

  //#region : Methods :
  //#endregion
}
