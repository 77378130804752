import { NbWindowState } from '@nebular/theme';
import { Contract } from '../models/contract.model';
import { Dispatchable } from './Dispatchable';

export interface ContractSearchWindowOpenPayload {
  screenSize: NbWindowState;
}

export class ContractSearchWindowOpen {
  static readonly type = '[CONTRACT SEARCH] Contract Search Window Open';
  constructor(public payload: ContractSearchWindowOpenPayload) {}
}

export class ContractSearchWindowClose extends Dispatchable {
  static readonly type = '[CONTRACT SEARCH] Contract Search Window Close';
  constructor() {
    super();
  }
}

export class ContractSearchOnSearch {
  static readonly type = '[CONTRACT SEARCH] Contract Search On Search';
  constructor(public payload: string) {}
}

export class ContractSearchOnClear {
  static readonly type = '[CONTRACT SEARCH] Contract Search On Clear';
}

export class ContractSearchOnDownload {
  static readonly type = '[CONTRACT SEARCH] Contract Search On Download';
  constructor(public payload: Contract) {}
}

export class ContractSearchOnPrintCancelForm {
  static readonly type = '[CONTRACT SEARCH] Contract Search On Print Cancel Form';
  constructor(public payload: Contract) {}
}

export class ContractSearchOnRefreshFromAs400 {
  static readonly type = '[CONTRACT SEARCH] Contract Search On Refresh From As400';
  constructor(public payload: Contract) {}
}

export class ContractSearchFetchPolicyContract extends Dispatchable {
  static readonly type = '[CONTRACT SEARCH] Contract Search Fetch Policy Contract';
  constructor(public payload: { contract: Contract }) {
    super();
  }
}

export class ContractSearchOnStartCancelQuote {
  static readonly type = '[CONTRACT SEARCH] Contract Search On Start Cancel Quote';
  constructor(public payload: Contract) {}
}

export class ContractSearchHelpHide {
  static readonly type = '[CONTRACT SEARCH] Contract Search Help Hide';
}

export class ContractSearchHelpToggle {
  static readonly type = '[CONTRACT SEARCH] Contract Search Help Toggle';
}

export class ContractSearchWindowMinimize {
  static readonly type = '[CONTRACT SEARCH] Contract Search Minimize';
}

interface ContractSearchWindowSizeChangedPayload {
  contractSearchWindowSize: NbWindowState;
}
export class ContractSearchWindowSizeChanged {
  static readonly type = '[CONTRACT SEARCH] Contract Search Size Changed';
  constructor(public payload: ContractSearchWindowSizeChangedPayload) {}
}
