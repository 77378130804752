import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiResponse, DeletePortalUserRequest, PortalUser, PortalUsersRequest, SavePortalUserRequest } from '@mbp/core';
import { getApiResponseData } from '../rxjs/get-api-response-data';

@Injectable({
  providedIn: 'root',
})
export class AdminService {
  constructor(private http: HttpClient) {}

  deleteUser(request: DeletePortalUserRequest) {
    return this.http.put<ApiResponse<boolean>>(`api/mbpadmin/deleteportaluser`, request).pipe(getApiResponseData());
  }

  getUsers(request: PortalUsersRequest) {
    return this.http.put<ApiResponse<PortalUser[]>>(`api/mbpadmin/portalusers`, request).pipe(getApiResponseData());
  }

  saveUser(request: SavePortalUserRequest) {
    return this.http.put<ApiResponse<boolean>>(`api/mbpadmin/saveportaluser`, request).pipe(getApiResponseData());
  }

  usernameIsValid(username: string) {
    return this.http
      .put<ApiResponse<boolean>>(`api/mbpadmin/usernameisvalid`, { username })
      .pipe(getApiResponseData());
  }
}
