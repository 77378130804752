import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import {
  AgencyGrossCommissionReportRequest,
  LookupService,
  Program,
  RightSidebarService,
  SearchCriteriaPageType,
  ServicePageEvent,
} from '@mbp/core';
import { Store } from '@ngxs/store';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'mbp-agency-gross-commission-search-criteria',
  templateUrl: './agency-gross-commission-search-criteria.component.html',
  styleUrls: ['./agency-gross-commission-search-criteria.component.scss'],
})
export class AgencyGrossCommissionSearchCriteriaComponent implements OnInit, OnDestroy {
  //#region : Variable Declarations :
  formErrors = {
    agency: '',
    agentCode: '',
  };
  validationMessages = {
    agency: {
      required: 'Please select a agency',
      agency: 'Please select a valid agency',
    },
    agentCode: {
      required: 'Please select a agency',
      agentCode: 'Please select a valid agency',
    },
  };

  isLoading = false;
  isSingleAgent = false;
  searchCriteriaForm: FormGroup;

  programs: Program[] = [];
  searchCriteriaRequest = new AgencyGrossCommissionReportRequest();

  runImmediatelySubscription: Subscription;
  //#endregion

  //#region : Constructor :
  constructor(
    private formBuilder: FormBuilder,
    private store: Store,
    private lookupService: LookupService,
    private rightSidebarService: RightSidebarService,
  ) {}
  //#endregion

  //#region : Properties :
  //#endregion

  //#region : Implementation Methods :
  ngOnInit() {
    this.buildForm();

    setTimeout(() => {
      this.loadLookupData();
    }, 500);

    this.runImmediatelySubscription = this.rightSidebarService.runImmediately$
      .pipe(filter((d) => !!d))
      .subscribe((sc: ServicePageEvent) => {
        setTimeout(() => {
          if (sc.searchCriteriaPageType == SearchCriteriaPageType.AgencyGrossCommissionReport) {
            this.search();
          }
        }, 500);
      });
  }

  ngOnDestroy() {
    if (this.runImmediatelySubscription != null) {
      this.runImmediatelySubscription.unsubscribe();
    }
  }
  //#endregion

  //#region : Methods :
  buildForm() {
    const currentDate = new Date();
    const previousMonthDate = new Date();

    previousMonthDate.setMonth(previousMonthDate.getMonth() - 1);

    this.searchCriteriaRequest.agentCode = '';
    this.searchCriteriaRequest.programCode = '';

    this.searchCriteriaForm = this.formBuilder.group(this.searchCriteriaRequest);
    this.searchCriteriaForm.valueChanges.subscribe((data) => this.onFormValueChanged(data));
  }

  loadLookupData() {
    this.lookupService.getPrograms({}).subscribe(
      (data) => this.onProgamsLoadSuccessful(data),
      (error) => this.onProgramsLoadFailed(error),
    );
  }

  public onFormValueChanged(data?: any) {
    if (!this.searchCriteriaForm) {
      return;
    }
    const form = this.searchCriteriaForm;
    for (const field in this.formErrors) {
      if (Object.prototype.hasOwnProperty.call(this.formErrors, field)) {
        this.formErrors[field] = '';
        const control = form.get(field);
        if (control && control.dirty && !control.valid) {
          const messages = this.validationMessages[field];
          for (const key in control.errors) {
            if (Object.prototype.hasOwnProperty.call(control.errors, key)) {
              this.formErrors[field] += messages[key] + ' ';
            }
          }
        }
      }
    }
  }

  onProgamsLoadSuccessful(programsData: Program[]) {
    let data = [];

    if (programsData != null) {
      data = programsData;
    }

    this.programs = data;
  }

  onProgramsLoadFailed(error: any) {}

  public onSingleAgentLoaded() {
    this.isSingleAgent = true;
  }

  public search() {
    const searchCriteria: AgencyGrossCommissionReportRequest = this.searchCriteriaForm.value;
    searchCriteria.programCode = searchCriteria.program != null ? searchCriteria.program.programCode : null;
    this.rightSidebarService.setSearchCriteria(SearchCriteriaPageType.AgencyGrossCommissionReport, searchCriteria);
  }
  //#endregion
}
