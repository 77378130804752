import { DealerBankAccount } from './dealer-bank-account.model';

export class SaveDealerBankAccountRequest {
  //#region : Constructor :
  constructor() {}
  //#endregion

  //#region : Properties :
  public userName: string;
  public bankAccount: DealerBankAccount;
  //#endregion
}
