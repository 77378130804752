export class ContractStatus {
  //#region : Constructor :
  constructor(contractStatusTypeID?: number, name?: string) {
    this.contractStatusTypeID = contractStatusTypeID;
    this.name = name;
  }
  //#endregion

  //#region : Properties :
  public contractStatusTypeID: number;
  public name: string;
  //#endregion
}
