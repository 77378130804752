import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class DBkeys {
  public static readonly CURRENT_USER = 'current_user';
  public static readonly USER_PERMISSIONS = 'user_permissions';
  public static readonly ACCESS_TOKEN = 'access_token';
  public static readonly ID_TOKEN = 'id_token';
  public static readonly REFRESH_TOKEN = 'refresh_token';
  public static readonly TOKEN_EXPIRES_IN = 'expires_in';
  public static readonly ONESIGNAL_USER_ID_TOKEN = 'onesignal_user_id_token';
  public static readonly ONESIGNAL_NOTIFICATIONS_ENABLED_TOKEN = 'onesignal_notifications_enabled_token';
  public static readonly PORTAL_FILTERED_DEALER = 'portal_filtered_dealer';
  public static readonly PORTAL_FILTERED_AGENCY = 'portal_filtered_agency';

  public static readonly REMEMBER_ME = 'remember_me';

  public static readonly LANGUAGE = 'language';
  public static readonly HOME_URL = 'home_url';
  public static readonly THEME = 'theme';
}

export const STORAGE_KEYS = {
  CURRENT_USER: 'current_user',
  USER_PERMISSIONS: 'user_permissions',
  ACCESS_TOKEN: 'auth.accessToken',
  ID_TOKEN: 'id_token',
  REFRESH_TOKEN: 'refresh_token',
  TOKEN_EXPIRES_IN: 'expires_in',
  ONESIGNAL_USER_ID_TOKEN: 'onesignal_user_id_token',
  ONESIGNAL_NOTIFICATIONS_ENABLED_TOKEN: 'onesignal_notifications_enabled_token',
  PORTAL_FILTERED_DEALER: 'portal_filtered_dealer',
  PORTAL_FILTERED_AGENCY: 'portal_filtered_agency',
  REMEMBER_ME: 'remember_me',
  LANGUAGE: 'language',
  HOME_URL: 'home_url',
  THEME: 'theme',
};
