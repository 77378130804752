import { HttpResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { environment } from '@mbp/environment';
import { Store } from '@ngxs/store';
import { catchError, tap } from 'rxjs/operators';
import { UserProfileService } from './api/user-profile.service';
import { ApiResponseCode } from './models/ApiResponse';
import { LogRocketService } from './services/log-rocket.service';
import { MediaService } from './services/media-service';
import { VersionCheckService } from './services/version-check.service';
import { UserLoggedIn } from './state/auth.actions';
import { isApiResponse } from './utilities';

export function initializeApp(
  mediaService: MediaService,
  versionCheckService: VersionCheckService,
  logRocketService: LogRocketService,
  userProfileService: UserProfileService,
  AUTH_API_BASE_URL: string,
  store: Store,
  router: Router,
): () => Promise<any> {
  return () => {
    const authUrl = new URL(AUTH_API_BASE_URL);
    const return_to = authUrl + '?return_to=' + window.location.href;
    authUrl.searchParams.append('return_to', return_to);

    if (window.location.hostname === environment.legacyHostName) {
      window.location.href = authUrl.toString();
    }

    return userProfileService
      .getSession()
      .pipe(
        tap((user) => {
          mediaService.init();
          logRocketService.init();
          versionCheckService.init({ ...environment.version });
          return store.dispatch(new UserLoggedIn(user));
        }),
        catchError((error) => {
          if (error instanceof HttpResponse && isApiResponse(error.body)) {
            if (
              error.body.code == ApiResponseCode.KratosCookieMissing ||
              error.body.code == ApiResponseCode.KratosUnauthorized ||
              error.body.code == ApiResponseCode.MfaCookieMissing ||
              error.body.code == ApiResponseCode.MfaCookieInvalid ||
              error.body.code == ApiResponseCode.MfaCookieExists ||
              error.body.code == ApiResponseCode.FlowItemsExist
            ) {
              window.location.href = authUrl.toString();
            }
            if (error.body.code == ApiResponseCode.UserNotFound) {
              return router.navigate(['/not-found']);
            }
          }
          console.error(error);
          return router.navigate(['/']);
        }),
      )
      .toPromise();
  };
}
