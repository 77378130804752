import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { debounce } from 'helpful-decorators';

@UntilDestroy()
@Component({
  selector: 'mbp-filter-box',
  templateUrl: './filter-box.component.html',
  styleUrls: ['./filter-box.component.scss'],
})
export class FilterBoxComponent {
  @Input() placeholder = 'Search';
  @Output() onFilter: EventEmitter<string> = new EventEmitter();

  clearFilter() {
    this.onFilter.emit('');
  }

  @debounce(250)
  filter(text: string) {
    this.onFilter.emit(text);
  }
}
