<div style="padding: 10px; width: 330px">
  <div style="padding-top: 5px; border-bottom: 1px solid lightgray">
    <h2>Search Criteria</h2>
  </div>
  <mat-divider></mat-divider>
  <form
    fxLayout="column"
    fxLayoutAlign="space-evenly center"
    style="padding-top: 15px"
    (ngSubmit)="search(claimNumber.value, contractNumber.value); claimNumber.value = ''; contractNumber.value = ''"
  >
    <mat-form-field class="mbp-full-width">
      <mat-label>Claim Number</mat-label>
      <input matInput class="input" placeholder="Enter a value" #claimNumber />
    </mat-form-field>
    <div>- OR -</div>
    <mat-form-field class="mbp-full-width">
      <mat-label>Contract Number</mat-label>
      <input matInput class="input" placeholder="Enter a value" #contractNumber />
    </mat-form-field>
    <button mat-raised-button color="primary" type="submit">Search</button>
  </form>
</div>
