import { LoanContractType } from './enums';

export class Lender {
  //#region : Constructor :
  constructor() {}
  //#endregion

  //#region : Properties :
  public dealerLenderID: number = null;
  public programCode: string = null;
  public dealerCode: string = null;
  public name: string = null;
  public address1: string = null;
  public address2: string = null;
  public city: string = null;
  public stateAbbreviation: string = null;
  public zipCode: string = null;
  public phoneNumber: string = null;
  public isGenericLender: string = null;
  public isMBPFinanceLender = false;

  public monthlyPaymentAmount: number = null;
  public totalFinanceAmount: number = null;
  public interestRate: number = null;
  public loanContractType: LoanContractType = LoanContractType.InstallmentSalesContract;

  //#endregion
}
