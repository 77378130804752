import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ContractsService, Contract } from '@mbp/core';
import { Observable } from 'rxjs';

@Component({
  selector: 'mbp-contract-card-form-code',
  templateUrl: './contract-card-form-code.component.html',
  styleUrls: ['./contract-card-form-code.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContractCardFormCodeComponent {
  @Input() contract: Contract;
  actualContractFormName$: Observable<ActualContractFormNameResponse>;

  constructor(private contracts: ContractsService) {}

  ngOnInit(): void {
    this.actualContractFormName$ = this.contracts.getActualContractFormName({
      contractNumber: this.contract.contractNumber,
      dealerCode: this.contract.dealerCode,
    });
  }
}

interface ActualContractFormNameResponse {
  ActualContractFormName: any;
  ContractFormName: string;
}
