import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  AgencyIncentiveTrip,
  AgencyIncentiveTripRequest,
  ApiResponse,
  ClaimsPaidSummary,
  ClaimsPaidSummaryRequest,
  DailyQuotesCountRequest,
  DailySalesCountRequest,
  DealerClaimSummary,
  DealerClaimSummaryRequest,
  MonthlyProgramSalesSummary,
  MonthlyProgramSalesSummaryRequest,
  MonthlySalesCountRequest,
  MonthlySalesSummary,
  MonthlySalesSummaryRequest,
  ProgramPendingRemittanceSummary,
  ProgramPendingRemittanceSummaryRequest,
  RaterDailyActivity,
  SalesDetailRequest,
  SalesOverview,
  SalesOverviewRequest,
  WeeklySalesCountRequest,
  YearlyProgramClaimSummary,
  YearlyProgramClaimSummaryRequest,
  YearlyProgramSalesSummary,
  YearlyProgramSalesSummaryRequest,
  YearlySalesCountRequest,
  YearlyStateSalesSummary,
  YearlyStateSalesSummaryRequest,
} from '@mbp/core';
import { getApiResponseData } from '../rxjs/get-api-response-data';

@Injectable({
  providedIn: 'root',
})
export class DashboardService {
  constructor(private http: HttpClient) {}

  getAgencyIncentiveTrip(request: AgencyIncentiveTripRequest) {
    return this.http
      .put<ApiResponse<AgencyIncentiveTrip>>(`/api/dashboard/summary/agencyincentivetrip`, request)
      .pipe(getApiResponseData());
  }

  getSalesDetail(request: SalesDetailRequest) {
    const params = {};
    for (const [key, value] of Object.entries(request)) {
      if (value) params[key] = value.toString();
    }

    return this.http
      .get<ApiResponse<RaterDailyActivity>>(`/api/dashboard/summary/sales`, { params })
      .pipe(getApiResponseData());
  }

  getDailyQuotesCount(request: DailyQuotesCountRequest) {
    return this.http.put<ApiResponse<number>>(`/api/dashboard/daily/quotescount`, request).pipe(getApiResponseData());
  }

  getDailySalesCount(request: DailySalesCountRequest) {
    return this.http.put<ApiResponse<number>>(`/api/dashboard/daily/salescount`, request).pipe(getApiResponseData());
  }

  getDealerClaimSummary(request: DealerClaimSummaryRequest) {
    return this.http.put<ApiResponse<DealerClaimSummary[]>>(`/api/dashboard/all/dealerclaimsummary`, request).pipe(getApiResponseData());
  }

  getClaimsPaidSummary(request: ClaimsPaidSummaryRequest) {
    return this.http.put<ApiResponse<ClaimsPaidSummary[]>>(`/api/dashboard/monthly/claimspaidsummary`, request).pipe(getApiResponseData());
  }

  getMonthlyProgramSalesSummary(request: MonthlyProgramSalesSummaryRequest) {
    return this.http
      .put<ApiResponse<MonthlyProgramSalesSummary>>(`/api/dashboard/monthly/programsalessummary`, request)
      .pipe(getApiResponseData());
  }

  getMonthlySalesCount(request: MonthlySalesCountRequest) {
    return this.http.put<ApiResponse<number>>(`/api/dashboard/monthly/salescount`, request).pipe(getApiResponseData());
  }

  getMonthlySalesSummary(request: MonthlySalesSummaryRequest) {
    return this.http.put<ApiResponse<MonthlySalesSummary[]>>(`/api/dashboard/monthly/salessummary`, request).pipe(getApiResponseData());
  }

  getProgramPendingRemittanceSummary(request: ProgramPendingRemittanceSummaryRequest) {
    return this.http
      .put<ApiResponse<ProgramPendingRemittanceSummary[]>>(`/api/dashboard/all/programpendingremittancesummary`, request)
      .pipe(getApiResponseData());
  }

  getSalesOverview(request: SalesOverviewRequest) {
    return this.http.put<ApiResponse<SalesOverview>>(`/api/dashboard/summary/salesoverview`, request).pipe(getApiResponseData());
  }

  getWeeklySalesCount(request: WeeklySalesCountRequest) {
    return this.http.put<ApiResponse<number>>(`/api/dashboard/weekly/salescount`, request).pipe(getApiResponseData());
  }

  getYearlyProgramClaimSummary(request: YearlyProgramClaimSummaryRequest) {
    return this.http
      .put<ApiResponse<YearlyProgramClaimSummary[]>>(`/api/dashboard/yearly/programclaimsummary`, request)
      .pipe(getApiResponseData());
  }

  getYearlyProgramSalesSummary(request: YearlyProgramSalesSummaryRequest) {
    return this.http
      .put<ApiResponse<YearlyProgramSalesSummary[]>>(`/api/dashboard/yearly/programsalessummary`, request)
      .pipe(getApiResponseData());
  }

  getYearlySalesCount(request: YearlySalesCountRequest) {
    return this.http.put<ApiResponse<number>>(`/api/dashboard/yearly/salescount`, request).pipe(getApiResponseData());
  }

  getYearlyStateSalesSummary(request: YearlyStateSalesSummaryRequest) {
    return this.http
      .put<ApiResponse<YearlyStateSalesSummary[]>>(`/api/dashboard/yearly/statesalessummary`, request)
      .pipe(getApiResponseData());
  }
}
