<ng-container *ngIf="viewModel$ | async as vm">
  <div class="toolbar" fxLayout="row" fxLayoutAlign="center center">
    <ng-content></ng-content>
    <header *ngIf="vm.pageHeader" class="header">{{ vm.pageHeader }}</header>

    <ng-container *ngIf="vm.showDealerSearch; else spacer">
      <mbp-dealer-search
        (selected)="onDealerSelected($event)"
        fxFlex="0 1 32em"
        class="dealer-search"
        label="Search for dealers"
        [showHint]="false"
      >
      </mbp-dealer-search>
    </ng-container>
    <ng-template #spacer>
      <span class="spacer"></span>
    </ng-template>

    <mbp-portal-toolbar-filter *ngIf="vm.showToolbarFilter"></mbp-portal-toolbar-filter>
    <mbp-toolbar-notifications [notifications]="vm.notifications"></mbp-toolbar-notifications>
    <mbp-portal-user-menu [fullName]="vm.fullName"></mbp-portal-user-menu>
  </div>
</ng-container>
