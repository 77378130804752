<ng-container *ngIf="viewModel$ | async as vm">
  <div style="padding: 10px; width: 330px">
    <div fxLayout="row" style="padding-top: 5px; border-bottom: 1px solid lightgray">
      <h2 style="display: block">Search Criteria</h2>
    </div>
    <mat-divider></mat-divider>
    <div style="padding-top: 15px">
      <form fxLayout="column" fxLayoutAlign="start stretch" [formGroup]="searchCriteriaForm">
        <mbp-dealer-search *ngIf="vm.dealerCount > 1" label="Dealer" formControlName="dealer"></mbp-dealer-search>
        <mat-form-field class="mbp-full-width" style="padding-top: 5px">
          <mat-label>Program</mat-label>
          <mat-select placeholder="Select a program" formControlName="program">
            <mat-option [value]="null">[All Programs]</mat-option>
            <mat-option *ngFor="let program of programs" [value]="program">
              {{ program.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field class="mbp-full-width">
          <mat-label>Start Date</mat-label>
          <input
            matInput
            mbp-mask-date
            class="input"
            placeholder="Select a start date"
            formControlName="startDate"
            required
            [matDatepicker]="startDatePicker"
          />
          <mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
          <mat-datepicker touchUi #startDatePicker></mat-datepicker>
        </mat-form-field>
        <mat-form-field class="mbp-full-width">
          <mat-label>End Date</mat-label>
          <input
            matInput
            mbp-mask-date
            class="input"
            placeholder="Select an end date"
            formControlName="endDate"
            required
            [matDatepicker]="endDatePicker"
          />
          <mat-datepicker-toggle matSuffix [for]="endDatePicker"></mat-datepicker-toggle>
          <mat-datepicker touchUi #endDatePicker></mat-datepicker>
        </mat-form-field>
        <mat-form-field class="mbp-full-width">
          <mat-label>Claim Status</mat-label>
          <mat-select placeholder="Select a claim status" formControlName="claimStatus">
            <mat-option [value]="null">[All Statuses]</mat-option>
            <mat-option *ngFor="let claimStatus of claimStatuses" [value]="claimStatus">
              {{ claimStatus.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <button mat-raised-button color="primary" type="button" [disabled]="!searchCriteriaForm.valid" (click)="search()">Search</button>
      </form>

      <mat-divider style="margin: 2rem 0"></mat-divider>
      <div style="text-align: center">- OR -</div>
      <mat-divider style="margin: 2rem 0"></mat-divider>

      <form fxLayout="column" fxLayoutAlign="start stretch" [formGroup]="searchByContractCriteriaForm">
        <mat-form-field class="mbp-full-width">
          <mat-label>Contract Number</mat-label>
          <input matInput class="input" placeholder="Enter a Value" formControlName="contractNumber" required />
        </mat-form-field>
        <button
          mat-raised-button
          color="primary"
          type="button"
          [disabled]="!searchByContractCriteriaForm.valid"
          (click)="onSearchContract()"
        >
          Search Contracts
        </button>
      </form>
    </div>
  </div>
</ng-container>
