import { Pipe, PipeTransform } from '@angular/core';
import * as numeral from 'numeral';

@Pipe({
  name: 'withCommas',
})
export class WithCommasPipe implements PipeTransform {
  transform(value: any): string {
    if (value == null) {
      return null;
    }
    return numeral(value).format('0,0');
  }
}
