import { Component, Inject, Input } from '@angular/core';
import { AUTH_API_BASE_URL } from 'app/core/core.module';

@Component({
  selector: 'mbp-portal-user-menu',
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.scss'],
})
export class UserMenuComponent {
  logoutUrl: string;
  settingsUrl: string;
  constructor(@Inject(AUTH_API_BASE_URL) private authBaseUrl: string) {
    const logoutUrl = new URL('ui/logout', this.authBaseUrl);
    const settingsUrl = new URL('ui/settings', this.authBaseUrl);
    const return_to = window.location.href;
    logoutUrl.searchParams.append('return_to', return_to);
    this.logoutUrl = logoutUrl.toString();
    settingsUrl.searchParams.append('return_to', return_to);
    this.settingsUrl = settingsUrl.toString();
  }

  @Input() fullName: string;
  @Input() currentUserProfilePhotoUrl: string;
}
