import { VoidReasonType } from './enums';

export class VoidContractRequest {
  //#region : Constructor :
  constructor(
    userName?: string,
    programCode?: string,
    dealerCode?: string,
    contractNumber?: string,
    voidReasonType?: VoidReasonType,
    useSefi?: boolean,
  ) {
    this.userName = userName;
    this.programCode = programCode;
    this.dealerCode = dealerCode;
    this.contractNumber = contractNumber;
    this.voidReasonType = voidReasonType;
    this.useSefi = useSefi;
  }
  //#endregion

  //#region : Properties :
  public userName: string;
  public programCode: string;
  public dealerCode: string;
  public contractNumber: string;
  public voidReasonType: VoidReasonType;
  public pointOfSaleOrderIdentifier: string;
  public pointOfSaleTransactionNumber: string;
  public pointOfSalePaymentAmount: number;
  public useSefi: boolean;
  //#endregion
}
