import { MenuGroupType } from './enums';
import { RaterMenuGroup } from './rater-menu-group.model';

export class RaterSaveMenuGroupsRequest {
  //#region : Constructor :
  constructor() {}
  //#endregion

  //#region : Properties :
  public userName: string;
  public dealerCode: string;
  public menuGroupType: MenuGroupType;
  public menuGroups: RaterMenuGroup[];
  //#endregion
}
