import { NgModule } from '@angular/core';
import { CoverageDisplayPipe } from './coverage-display.pipe';
import { EnumAsStringPipe } from './enum-as-string.pipe';
import { ExistsPipe } from './exists.pipe';
import { IsEmptyPipe } from './is-empty.pipe';
import { MaskedPipe } from './masked.pipe';
import { MomentFromPipe } from './moment-from/moment-from.pipe';
import { NumeralPipe } from './numeral.pipe';
import { PhoneMaskPipe } from './phone-mask.pipe';
import { PhonePipe } from './phone.pipe';
import { ToDisplayPipe } from './to-display.pipe';
import { WithCommasPipe } from './with-commas/with-commas.pipe';

@NgModule({
  exports: [
    CoverageDisplayPipe,
    EnumAsStringPipe,
    ExistsPipe,
    IsEmptyPipe,
    MaskedPipe,
    MomentFromPipe,
    NumeralPipe,
    PhoneMaskPipe,
    PhonePipe,
    ToDisplayPipe,
    WithCommasPipe,
  ],
  declarations: [
    CoverageDisplayPipe,
    EnumAsStringPipe,
    ExistsPipe,
    IsEmptyPipe,
    MaskedPipe,
    MomentFromPipe,
    NumeralPipe,
    PhoneMaskPipe,
    PhonePipe,
    ToDisplayPipe,
    WithCommasPipe,
  ],
})
export class PipesModule {}
