import { AuthState, createMappedSelector, SidenavMode, UIState } from '@mbp/core';

export interface RootComponentStateModel {
  inCustomerMode: boolean;
  sidenavOpened: boolean;
  sidenavMode: SidenavMode;
  rightSidenavOpened: boolean;
  showToggle: boolean;
  isLoggedIn: boolean;
}

export class RootComponentSelector {
  static getState = createMappedSelector({
    inCustomerMode: UIState.props.inCustomerMode,
    sidenavOpened: UIState.props.sidenavOpened,
    sidenavMode: UIState.props.sidenavMode,
    rightSidenavOpened: UIState.props.rightSidenavOpened,
    showToggle: UIState.props.showToggle,
    isLoggedIn: AuthState.getIsLoggedIn,
  });
}
