import { currencyFormat } from '@mbp/core';
import { ValidatorDetails } from './validator-details';
import { Validator } from './validator';

export interface CurrencyRangeValidatorPayload {
  minimum: number;
  maximum: number;
  value: number | null;
}

export class CurrencyRangeValidator implements Validator {
  public error: string | null;
  constructor(public propertyDetails: ValidatorDetails, public payload: CurrencyRangeValidatorPayload) {}

  validate(): Validator {
    if (this.payload.maximum >= this.payload.value && this.payload.minimum <= this.payload.value) {
      this.error = null;
    } else {
      this.error = `Must be between ${currencyFormat.format(this.payload.minimum)} and ${currencyFormat.format(this.payload.maximum)}`;
    }
    return this;
  }
}
