import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { DynamicIoModule } from 'ng-dynamic-component';
import { DirectivesModule } from '../directives/directives.module';
import { PipesModule } from '../pipes/pipes.module';
import { VendorModule } from '../vendor.module';
import { AgentAutoCompleteComponent } from './agent-autocomplete/agent-autocomplete.component';
import { ContentCardComponent } from './content-card/content-card.component';
import { ContractCardComponent } from './contract-card/contract-card.component';
import { ContractLookupComponent } from './contract-lookup/contract-lookup-form.component';
import { ContractSearchHelpComponent } from './contract-search-help/contract-search-help.component';
import { ContractSearchOutletComponent } from './contract-search-outlet/contract-search-outlet.component';
import { ContractSearchComponent } from './contract-search/contract-search.component';
import { DealerLookupComponent } from './dealer-lookup/dealer-lookup-form.component';
import { DealerLossRatioComponent } from './dealer-loss-ratio/dealer-loss-ratio.component';
import { DealerSearchComponent } from './dealer-search/dealer-search.component';
import { DialogComponent } from './dialog/dialog.component';
import { FilterBoxComponent } from './filter-box/filter-box.component';
import { LabelWithTextComponent } from './label-with-text/label-with-text.component';
import { LabelComponent } from './label/label.component';
import { LoadingContainerComponent } from './loading-container/loading-container.component';
import { LoadingComponent } from './loading/loading.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { NumberCardComponent } from './number-card/number-card.component';
import { PageComponent } from './page/page.component';
import { SalesSummaryGridComponent } from './sales-summary-grid/sales-summary-grid.component';
import { SearchInputComponent } from './search-input/search-input.component';
import { SelectComponent } from './select/select.component';
import { TableComponent } from './table/table.component';
import { TestVehicleSelectComponent } from './test-vehicle-select/test-vehicle-select.component';
import { TextComponent } from './text/text.component';
import { ToolbarFilterComponent } from './toolbar-filter/toolbar-filter.component';
import { VoidContractComponent } from './void-contract/void-contract-form.component';
import { WidgetComponent } from './widget/widget.component';
import { ZipLookupComponent } from './zip-lookup/zip-lookup.component';
import { ContractCardFormCodeComponent } from './contract-card/contract-card-form-code/contract-card-form-code.component';

@NgModule({
  declarations: [
    AgentAutoCompleteComponent,
    // TODO: replace this lookup component with the search component
    ContractCardComponent,
    ContractLookupComponent,
    ContractSearchComponent,
    ContractSearchHelpComponent,
    ContractSearchOutletComponent,
    ContentCardComponent,
    DealerLookupComponent,
    DealerLossRatioComponent,
    DealerSearchComponent,
    FilterBoxComponent,
    LoadingComponent,
    LoadingContainerComponent,
    NotFoundComponent,
    NumberCardComponent,
    PageComponent,
    SalesSummaryGridComponent,
    SearchInputComponent,
    TestVehicleSelectComponent,
    ToolbarFilterComponent,
    VoidContractComponent,
    WidgetComponent,
    ZipLookupComponent,
    DialogComponent,
    LabelComponent,
    TextComponent,
    LabelWithTextComponent,
    SelectComponent,
    TableComponent,
    ContractCardFormCodeComponent,
  ],
  exports: [
    AgentAutoCompleteComponent,
    // TODO: replace this lookup component with the search component
    ContractCardComponent,
    ContractLookupComponent,
    ContractSearchComponent,
    ContractSearchHelpComponent,
    ContractSearchOutletComponent,
    ContentCardComponent,
    DealerLookupComponent,
    DealerLossRatioComponent,
    DealerSearchComponent,
    FilterBoxComponent,
    LoadingComponent,
    LoadingContainerComponent,
    NotFoundComponent,
    NumberCardComponent,
    PageComponent,
    SalesSummaryGridComponent,
    SearchInputComponent,
    TestVehicleSelectComponent,
    ToolbarFilterComponent,
    VoidContractComponent,
    WidgetComponent,
    ZipLookupComponent,
    DialogComponent,
    LabelComponent,
    TextComponent,
    LabelWithTextComponent,
    SelectComponent,
    TableComponent,
  ],
  imports: [
    CommonModule,
    DirectivesModule,
    DynamicIoModule,
    PipesModule,
    FormsModule,
    FlexLayoutModule,
    ReactiveFormsModule,
    RouterModule,
    VendorModule,
  ],
})
export class ComponentsModule {}
