<div *ngIf="(dealerDropdownCount$ | async) > DealerCountThreshold; else dropdown">
  <mat-form-field appearance="outline">
    <mat-label>{{ label }}</mat-label>
    <input
      feux-form-field-control
      matInput
      placeholder="Name, Code, Phone, State or Zip"
      [matAutocomplete]="dealers"
      (keyup)="onKeyup($event)"
      (focus)="onFocus()"
      [value]="text || (searchText | async)"
      [error]="error"
      [submitted]="submitted"
      [disabled]="disabled"
      (input)="inputted.emit($event.target.value)"
      (blur)="blurred.emit($event.target.value)"
    />
    <mat-icon matPrefix>search</mat-icon>
    <mat-icon *ngIf="(text || searchText.value.length) && !disabled" class="close" matSuffix (click)="onCleared()">close</mat-icon>
    <mat-error>Dealer is required</mat-error>
    <mat-hint *ngIf="showHint" align="end">{{ selectedDealerCode.value }}</mat-hint>
  </mat-form-field>

  <mat-autocomplete #dealers="matAutocomplete" (optionSelected)="onDealerSelected($event.option.value)">
    <mat-option *ngIf="searching$ | async" [disabled]="true">
      <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </mat-option>
    <ng-container *ngIf="filteredDealers$ | async as filteredDealers">
      <ng-container *ngIf="searchText.value.length > 0">
        <ng-container *ngIf="filteredDealers.length === 0; else elseTemplate">
          <mat-option [disabled]="true"> No dealers found. </mat-option>
        </ng-container>
        <ng-template #elseTemplate>
          <mat-option *ngFor="let dm of filteredDealers" [value]="dm">
            <div fxLayout="row" style="line-height: 1; width: 100%; white-space: pre-wrap">
              {{ dm.name }} ({{ dm.dealerCode }}){{ dm.expirationDate != null ? ' - Expired' : '' }}
            </div>
          </mat-option>
        </ng-template>
      </ng-container>
    </ng-container>
  </mat-autocomplete>
</div>
<ng-template #dropdown>
  <div *ngIf="filteredDealers$ | async as filteredDealers">
    <mat-form-field>
      <mat-select
        placeholder="Select a Dealer"
        (focus)="onFocus()"
        (selectionChange)="onDealerSelected($event.value)"
        [value]="dealerCode"
        [compareWith]="compareWithFn"
      >
        <mat-option value="">[All Dealers]</mat-option>
        <mat-option *ngFor="let dm of filteredDealers" [value]="dm">
          <div fxLayout="row">{{ dm.name }} ({{ dm.dealerCode }}){{ dm.expirationDate != null ? ' - Expired' : '' }}</div>
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</ng-template>
