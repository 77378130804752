import { MediaMatcher } from '@angular/cdk/layout';
import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { UIMediaQueryListEvent } from '../state/ui.actions';
import { SidenavMode } from '../state/ui.state';

@Injectable({
  providedIn: 'root',
})
export class MediaService {
  constructor(private media: MediaMatcher, private store: Store) {}
  init() {
    const mediaQueryList = this.media.matchMedia('(max-width: 959px)');
    const mobileView = mediaQueryList.matches;
    this.store.dispatch(
      new UIMediaQueryListEvent({
        mobileView,
        sidenavOpened: !mobileView,
        sidenavMode: mobileView ? SidenavMode.over : SidenavMode.side,
        showToggle: mobileView,
      }),
    );

    try {
      // Not safari
      mediaQueryList.addEventListener('change', (ev) => {
        const mobileView = ev.matches;
        this.store.dispatch(
          new UIMediaQueryListEvent({
            mobileView,
            sidenavOpened: !mobileView,
            sidenavMode: mobileView ? SidenavMode.over : SidenavMode.side,
            showToggle: mobileView,
          }),
        );
      });
    } catch (e) {
      try {
        // Safari
        // tslint:disable-next-line: deprecation
        mediaQueryList.addListener((ev) => {
          const mobileView = ev.matches;
          this.store.dispatch(
            new UIMediaQueryListEvent({
              mobileView,
              sidenavOpened: !mobileView,
              sidenavMode: mobileView ? SidenavMode.over : SidenavMode.side,
              showToggle: mobileView,
            }),
          );
        });
      } catch (e2) {
        console.error(e2);
      }
    }
  }
}
