import { Retail } from '../models/retail.model';

export class RetailSettingsInit {
  public static readonly type = '[Retail Settings] Init';
}

export class FetchRetailSettings {
  public static readonly type = '[Retail Settings] Fetch';
}

export class ClearRetailSettings {
  public static readonly type = '[Retail Settings] Clear';
}

export class RetailSettingsDealerCodeChanged {
  public static readonly type = '[Retail Settings] Dealer Code Changed';
  constructor(public payload: string) {}
}

export class RetailSettingsCreate {
  public static readonly type = '[Retail Settings] Form Submitted';
}

export class RetailSettingsStartCreate {
  public static readonly type = '[Retail Settings] Start Create Form';
}

export class RetailSettingsEdit {
  public static readonly type = '[Retail Setting] Edit Retail Setting';
}

export class RetailSettingsStartEdit {
  public static readonly type = '[Retail Setting] Start Edit Retail Setting';
  constructor(public payload: Retail) {}
}

export class RetailSettingsDelete {
  public static readonly type = '[Retail Setting] Delete Retail Setting';
  constructor(public payload: Retail) {}
}
