import { Customer } from './customer.model';
import { Lender } from './lender.model';
import { RaterMenu } from './rater-menu.model';
import { RaterQuoteRequest } from './rater-quote-request.model';
import { VehicleInformation } from './vehicle-information.model';

export class GenerateRaterMenuPdfRequest {
  //#region : Constructor :
  constructor() {}
  //#endregion

  //#region : Properties :
  public userName: string;
  public dealerCode: string;
  public dealerName: string;
  public dealerLogo: string;
  public agencyLogo: string;
  public menu: RaterMenu;
  public quoteRequestInformation: RaterQuoteRequest;
  public customer: Customer;
  public vehicleInformation: VehicleInformation;
  public lender: Lender;
  public useMBPFinance: boolean;
  public useLegacyMenu: boolean;
  public blankMenu: boolean;
  public generateAsWorksheet: boolean;

  //#endregion
}
