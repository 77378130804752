import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { makeQuery } from './make-query';
import { GetConfigurationItemDocument, GetConfigurationItemQueryVariables } from './generated/graphql';

@Injectable({
  providedIn: 'root',
})
export class AccountQueries {
  constructor(private http: HttpClient) { }

  getConfigurationItem = (variables: GetConfigurationItemQueryVariables) => makeQuery({
    http: this.http,
    operation: GetConfigurationItemDocument,
    variables
  })
}
