<div style="display: inline-block">
  <mat-form-field class="mbp-full-width">
    <mat-label>Filter</mat-label>
    <input matInput #filterInput (keyup)="filter(filterInput.value)" [placeholder]="placeholder" />
    <button
      mat-button
      *ngIf="filterInput.value"
      matSuffix
      mat-icon-button
      aria-label="Clear"
      (click)="clearFilter(); filterInput.value = ''"
    >
      <mat-icon>close</mat-icon>
    </button>
  </mat-form-field>
</div>
