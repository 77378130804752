import { NgModule } from '@angular/core';
import { AutoFocusDirective } from './auto-focus.directive';
import { CheckboxErrorStateDirective } from './checkbox.directive';
import { DateMaskFormFieldControlDirective } from './date-mask-form-field-control.directive';
import { DatePickerAutoOpenDirective } from './date-picker-auto-open.directive';
import { ExpirationMaskFormFieldControlDirective } from './expiration-mask-form-field-control.directive';
import { FeuxFormFieldControlDirective } from './feux-form-field-control.directive';
import { IfAuthDirective } from './if-auth.directive';
import { LabelControlDirective } from './label-control.directive';
import { MaskDateDirective } from './mask-date.directive';
import { MinMaxDateDirective } from './min-max-date.directive';
import { NumberFormatterFormFieldControlDirective } from './number-formatter.form-field-control.directive';
import { NumberOnlyDirective } from './number-only.directive';
import { RightSidenavToggleDirective } from './right-sidenav-toggle.directive';
import { TogglePasswordDirective } from './toggle-password.directive';
import { ZipLookupDirective } from './zip-lookup.directive';
import { RadioGroupErrorStateDirective } from './radio.directive';

@NgModule({
  declarations: [
    AutoFocusDirective,
    CheckboxErrorStateDirective,
    DateMaskFormFieldControlDirective,
    DatePickerAutoOpenDirective,
    ExpirationMaskFormFieldControlDirective,
    FeuxFormFieldControlDirective,
    IfAuthDirective,
    LabelControlDirective,
    MaskDateDirective,
    MinMaxDateDirective,
    NumberFormatterFormFieldControlDirective,
    NumberOnlyDirective,
    RadioGroupErrorStateDirective,
    RightSidenavToggleDirective,
    TogglePasswordDirective,
    ZipLookupDirective,
  ],
  exports: [
    AutoFocusDirective,
    CheckboxErrorStateDirective,
    DateMaskFormFieldControlDirective,
    DatePickerAutoOpenDirective,
    ExpirationMaskFormFieldControlDirective,
    FeuxFormFieldControlDirective,
    IfAuthDirective,
    LabelControlDirective,
    MaskDateDirective,
    MinMaxDateDirective,
    NumberFormatterFormFieldControlDirective,
    NumberOnlyDirective,
    RadioGroupErrorStateDirective,
    RightSidenavToggleDirective,
    TogglePasswordDirective,
    ZipLookupDirective,
  ],
})
export class DirectivesModule {}
