import { TreeEntry } from '..';

export class FetchDocuments {
  public static readonly type = '[Documents Page] Admin loaded';
}

export class FetchDocumentById {
  public static readonly type = '[Documents Page] Download by id';
  constructor(public payload: number) {}
}

export class ImportFromScs {
  public static readonly type = '[Documents List] Import From SCS';
  constructor(public payload: TreeEntry) {}
}

export class ExportToScs {
  public static readonly type = '[Documents List] Export To SCS';
  constructor(public payload: TreeEntry) {}
}

export class ExportReviewFilesToOldPortal {
  public static readonly type = '[Documents List] Export To Old Portal';
  constructor(public payload: TreeEntry) {}
}

export class GenerateChecks {
  public static readonly type = '[Documents List] Generate Checks';
  constructor(public payload: TreeEntry) {}
}
