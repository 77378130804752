import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import {
  AppStateModel,
  ClaimStatus,
  ClaimStatusType,
  DealerClaimReportSearchCriteria,
  LookupService,
  Program,
  RightSidebarService,
  SearchCriteriaPageType,
} from '@mbp/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Select, Store } from '@ngxs/store';
import { isEmpty, isEqual } from 'lodash';
import { Observable, Subscription } from 'rxjs';
import { distinctUntilChanged, filter } from 'rxjs/operators';
import { DealerClaimsSearchCriteriaQueries, DealerClaimsSearchCriteriaViewModel } from './dealer-claims-search-criteria.view-model';

@UntilDestroy()
@Component({
  selector: 'mbp-dealer-claims-search-criteria',
  templateUrl: './dealer-claims-search-criteria.component.html',
  styleUrls: ['./dealer-claims-search-criteria.component.scss'],
})
export class DealerClaimsSearchCriteriaComponent implements OnInit, OnDestroy {
  @Select(DealerClaimsSearchCriteriaQueries.getViewModel) viewModel$: Observable<DealerClaimsSearchCriteriaViewModel>;

  public claimStatuses = [
    new ClaimStatus(ClaimStatusType.Approved, 'Approved'),
    new ClaimStatus(ClaimStatusType.Paid, 'Paid'),
    new ClaimStatus(ClaimStatusType.Pending, 'Pending'),
    new ClaimStatus(ClaimStatusType.Denied, 'Denied'),
    new ClaimStatus(ClaimStatusType.Void, 'Void'),
  ];
  public searchCriteriaForm: FormGroup;
  public searchByContractCriteriaForm: FormGroup;
  public programs: Program[] = [];
  private subscription = new Subscription();

  constructor(
    private formBuilder: FormBuilder,
    private lookupService: LookupService,
    private rightSidebarService: RightSidebarService,
    private store: Store,
  ) {}

  ngOnInit() {
    const startDate = new Date();
    startDate.setDate(startDate.getDate() - 1);

    this.searchCriteriaForm = this.formBuilder.group({
      dealer: null,
      program: null,
      startDate,
      endDate: new Date(),
      claimStatus: null,
    });

    this.searchByContractCriteriaForm = this.formBuilder.group({
      contractNumber: null,
    });

    this.subscription.add(
      this.rightSidebarService.runImmediately$
        .pipe(
          filter((d) => !!d),
          filter(({ searchCriteriaPageType }) => searchCriteriaPageType === SearchCriteriaPageType.DealerClaimsReport),
          untilDestroyed(this),
        )
        .subscribe((sc) => {
          if (sc.eventData) {
            this.searchCriteriaForm.patchValue({ dealer: sc.eventData });

            const onlyDealer = this.store.selectSnapshot((appState: AppStateModel) => appState.dealer.dealerMinimals.length === 1);
            if (onlyDealer) {
              this.searchCriteriaForm.get('dealer').disable();
              this.searchCriteriaForm.updateValueAndValidity();
            }
          }
          this.search();
        }),
    );

    this.lookupService
      .getPrograms({})
      .pipe(untilDestroyed(this))
      .subscribe((data) => (this.programs = isEmpty(data) ? [] : data));
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  public onSearchContract() {
    const formValue = this.searchByContractCriteriaForm.getRawValue();
    const contractNumber = formValue.contractNumber;
    this.rightSidebarService.setSearchCriteria(SearchCriteriaPageType.DealerClaimsReport, {
      contractNumber,
    } as DealerClaimReportSearchCriteria);
  }

  public search() {
    const formValue = this.searchCriteriaForm.getRawValue();
    this.rightSidebarService.setSearchCriteria(SearchCriteriaPageType.DealerClaimsReport, {
      claimStatus: formValue.claimStatus,
      dealer: formValue.dealer,
      endDate: formValue.endDate,
      program: formValue.program,
      startDate: formValue.startDate,
    } as DealerClaimReportSearchCriteria);
  }
}
