import { Injectable } from '@angular/core';
import { Utilities } from '../utilities';

@Injectable({
  providedIn: 'root',
})
export class LocalStoreManager {
  public static readonly DBKEY_USER_DATA = 'user_data';
  private static readonly DBKEY_SYNC_KEYS = 'sync_keys';
  private syncKeys: string[] = [];

  private reservedKeys: string[] = [
    'sync_keys',
    'addToSyncKeys',
    'removeFromSyncKeys',
    'getSessionStorage',
    'setSessionStorage',
    'addToSessionStorage',
    'removeFromSessionStorage',
    'clearAllSessionsStorage',
  ];

  public saveSyncedSessionData(data: any, key = LocalStoreManager.DBKEY_USER_DATA) {
    this.testForInvalidKeys(key);

    localStorage.removeItem(key);
    this.addToSessionStorage(data, key);
  }

  public getData(key = LocalStoreManager.DBKEY_USER_DATA) {
    this.testForInvalidKeys(key);

    let data = Utilities.JSonTryParse(sessionStorage.getItem(key));

    if (data == null) {
      data = Utilities.JSonTryParse(localStorage.getItem(key));
    }

    return data;
  }

  public getDataObject<T>(key = LocalStoreManager.DBKEY_USER_DATA, isDateType = false): T {
    let data = this.getData(key);

    if (data != null) {
      if (isDateType) {
        data = new Date(data);
      }

      return data as T;
    } else {
      return null;
    }
  }

  public savePermanentData(data: any, key = LocalStoreManager.DBKEY_USER_DATA) {
    this.testForInvalidKeys(key);

    this.removeFromSessionStorage(key);
    localStorage.setItem(key, JSON.stringify(data));
  }

  private addToSessionStorage(data: any, key: string) {
    this.addToSessionStorageHelper(data, key);
    this.addToSyncKeysBackup(key);

    localStorage.setItem('addToSessionStorage', JSON.stringify({ key, data }));
    localStorage.removeItem('addToSessionStorage');
  }

  private addToSessionStorageHelper(data: any, key: string) {
    this.addToSyncKeysHelper(key);
    sessionStorage.setItem(key, JSON.stringify(data));
  }

  private addToSyncKeysBackup(key: string) {
    const storedSyncKeys = this.getSyncKeysFromStorage();

    if (!storedSyncKeys.some((x) => x === key)) {
      storedSyncKeys.push(key);
      localStorage.setItem(LocalStoreManager.DBKEY_SYNC_KEYS, JSON.stringify(storedSyncKeys));
    }
  }

  private addToSyncKeysHelper(key: string) {
    const hasKey = this.syncKeys.some((x) => x === key);
    if (!hasKey) {
      this.syncKeys.push(key);
    }
  }

  private getSyncKeysFromStorage(defaultValue: string[] = []): string[] {
    const data = Utilities.JSonTryParse(localStorage.getItem(LocalStoreManager.DBKEY_SYNC_KEYS));

    if (data == null) {
      return defaultValue;
    } else {
      return data as string[];
    }
  }

  private removeFromSessionStorage(keyToRemove: string) {
    sessionStorage.removeItem(keyToRemove);
    const syncKeyindex = this.syncKeys.indexOf(keyToRemove);

    if (syncKeyindex > -1) {
      this.syncKeys.splice(syncKeyindex, 1);
    }

    const storedSyncKeys = this.getSyncKeysFromStorage();

    const storedSyncKeyIndex = storedSyncKeys.indexOf(keyToRemove);

    if (storedSyncKeyIndex > -1) {
      storedSyncKeys.splice(storedSyncKeyIndex, 1);
      localStorage.setItem(LocalStoreManager.DBKEY_SYNC_KEYS, JSON.stringify(storedSyncKeys));
    }

    localStorage.setItem('removeFromSessionStorage', keyToRemove);
    localStorage.removeItem('removeFromSessionStorage');
  }

  private testForInvalidKeys(key: string) {
    if (!key) {
      throw new Error('key cannot be empty');
    }

    if (this.reservedKeys.some((x) => x === key)) {
      throw new Error(`The storage key "${key}" is reserved and cannot be used. Please use a different key`);
    }
  }
}
