import { ContractStatus } from './contract-status.model';
import { DealerMinimal } from './dealer-minimal';
import { ContractStatusType } from './enums';
import { Program } from './program.model';

export class DealerContractReportRequest {
  //#region : Constructor :
  constructor() {}
  //#endregion

  //#region : Properties :
  public userName: string;
  public programCode: string;
  public dealerCode: string;
  public contractStatusType: ContractStatusType;
  public startDate: Date;
  public endDate: Date;
  public dealer: DealerMinimal = null;
  public program: Program = null;
  public contractStatus: ContractStatus = null;
  public useSefi: boolean;
  //#endregion
}
