<perfect-scrollbar>
  <mbp-rater-history-search-criteria *ngIf="router.url.includes('/rater/history')"></mbp-rater-history-search-criteria>

  <mbp-pending-remittance-search-criteria *ngIf="router.url.includes('/remittance/pending')"></mbp-pending-remittance-search-criteria>

  <mbp-remittance-history-search-criteria *ngIf="router.url.includes('remittance/history')"></mbp-remittance-history-search-criteria>

  <mbp-internal-messages-search-criteria *ngIf="router.url.includes('/internalmessages')"></mbp-internal-messages-search-criteria>

  <mbp-agency-gross-commission-search-criteria
    *ngIf="router.url.includes('/reporting/agencygrosscommissionreport')"
  ></mbp-agency-gross-commission-search-criteria>

  <mbp-dealer-contracts-search-criteria
    *ngIf="router.url.includes('/reporting/dealercontractsreport')"
  ></mbp-dealer-contracts-search-criteria>

  <mbp-dealer-claims-search-criteria *ngIf="router.url.includes('/reporting/dealerclaimsreport')"></mbp-dealer-claims-search-criteria>

  <mbp-new-dealer-disbursement-search-criteria
    *ngIf="router.url.includes('/reporting/newdealerdisbursementreport')"
  ></mbp-new-dealer-disbursement-search-criteria>

  <mbp-quote-requests-search-criteria *ngIf="router.url.includes('/reporting/quoterequestsreport')"></mbp-quote-requests-search-criteria>

  <mbp-remittance-summary-search-criteria
    *ngIf="router.url.includes('/reporting/remittancesummaryreport')"
  ></mbp-remittance-summary-search-criteria>
  <mbp-sales-summary-search-criteria *ngIf="router.url.includes('/reporting/salessummaryreport')"></mbp-sales-summary-search-criteria>
  <mbp-scs-claim-info-search-criteria *ngIf="router.url.includes('/reporting/scsclaiminforeport')"></mbp-scs-claim-info-search-criteria>
</perfect-scrollbar>
