export class AgencyIncentiveTripRequest {
  //#region : Constructor :
  constructor(userName?: string, useSefi?: boolean) {
    this.userName = userName;
    this.useSefi = useSefi;
  }
  //#endregion

  //#region : Properties :
  public userName: string;
  public useSefi: boolean;
  //#endregion
}
