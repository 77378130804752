import { Directive, ElementRef, EventEmitter, HostListener, Optional, Output, Self } from '@angular/core';
import { NgControl } from '@angular/forms';
import { Utilities } from '@mbp/core';
import { DateTime } from 'luxon';
import { MaskApplierService } from 'ngx-mask';
@Directive({
  selector: '[mbp-mask-date]',
})
export class MaskDateDirective {
  @Output() valid = new EventEmitter<boolean>();

  maskExpression = '00/00/0000';
  private maskedValue = '';

  constructor(
    @Optional() @Self() public ngControl: NgControl,
    private elRef: ElementRef<HTMLInputElement>,
    private maskApplier: MaskApplierService,
  ) {}

  // TODO: Go through each day and determine slashes ie:
  // 2 => 02/
  // 02/5 => 02/05/
  // prevent user from entering '00'
  validateEnteredValue(val: string): void {
    if (val?.length <= 7 && val?.length > 0) {
      if (val == '1/') {
        val = '01/';
      }

      // only 1-9 single digit
      const monthAndSingleDay = /[0-9]{2}\/[1-9]\//;
      if (monthAndSingleDay.test(val)) {
        val = val.slice(0, 3) + '0' + val.slice(3);
      }

      const endsWithSlash = val.endsWith('/');
      const number = Number(val.replace(/[^0-9]+/g, ''));
      const numString = number.toString();
      const needsMonthDayPadding: boolean = /^[2-9][1-2]20/.test(numString) || /^[2-9][4-9]/.test(numString) || /^1[4-9]/.test(numString);
      Utilities.match(number)
        .on(
          (n) => n >= 2 && n <= 9,
          () => {
            val = '0' + numString + (endsWithSlash ? '/' : '');
          },
        )
        .on(
          (n) => n >= 130 && n <= 131,
          () => {
            val = '0' + numString;
          },
        );

      if (needsMonthDayPadding) {
        val = '0' + numString.slice(0, 1) + '0' + numString.slice(1, 4) + '/';
      }

      this.maskedValue = this.maskApplier.applyMask(val, this.maskExpression);
      this.elRef.nativeElement.value = this.maskedValue;
    }
  }

  @HostListener('input', ['$event.target.value']) onInput(val: string): void {
    this.validateEnteredValue(val);
  }

  @HostListener('blur', ['$event.target.value']) onBlur(val: string): void {
    const dateTime = DateTime.fromFormat(val, 'LL/dd/yyyy');
    this.valid.emit(dateTime.isValid);
  }
}
