import { MenuGroupType } from './enums';
import { RaterMenuProduct } from './rater-menu-product.model';

export class RaterMenuGroup {
  //#region : Constructor :
  constructor() {}
  //#endregion

  //#region : Properties :

  public menuGroupID: string;
  public menuGroupType: MenuGroupType;
  public menuGroupTypeName: string;
  public name: string;
  public products: RaterMenuProduct[];
  public isIncluded: boolean;
  public isIncludedInitially: boolean;
  public sortOrder: number;
  public isSelected: boolean;

  public totalMonthlyPaymentAmount: number;
  public totalMonthlyPaymentAmount2: number;
  public totalCostAmount: number;
  public totalCostAmount2: number;
  public totalTaxAmount: number;
  public totalTaxAmount2: number;
  public totalDealerCostAmount: number;
  public totalDealerCostAmount2: number;

  // Legacy menu properties
  public loanTerm: number;
  public loanTerm2: number;
  public loanInterestRate: number;
  public loanInterestRate2: number;
  public additionalDownPaymentAmount: number;
  public showTotal: boolean;
  public includeLoanTerm2: boolean;
  public vehicleOnlyMonthlyPaymentAmount: number;
  public vehicleOnlyMonthlyPaymentAmount2: number;
  //#endregion

  //#region : Methods :
  public static cloneForAdd(sourceMenuGroup: RaterMenuGroup): RaterMenuGroup {
    const newGroup: RaterMenuGroup = new RaterMenuGroup();

    Object.assign(newGroup, sourceMenuGroup);

    newGroup.menuGroupID = '00000000-0000-0000-0000-000000000000';
    newGroup.name = '';

    newGroup.products.forEach((p) => {
      p.isIncluded = true;
      p.isIncludedInitially = true;
    });

    return newGroup;
  }
  //#endregion
}
