<div style="padding: 10px; width: 330px">
  <div fxLayout="row" style="padding-top: 5px; border-bottom: 1px solid lightgray">
    <h2 style="display: block">Search Criteria</h2>
  </div>
  <mat-divider></mat-divider>
  <div style="padding-top: 15px">
    <form fxLayout="column" fxLayoutAlign="start stretch" [formGroup]="searchCriteriaForm">
      <mbp-dealer-search label="Dealer" formControlName="dealer"></mbp-dealer-search>
      <mat-form-field class="mbp-full-width" style="padding-top: 5px">
        <mat-select placeholder="Select a program" formControlName="program">
          <mat-option value="">[All Programs]</mat-option>
          <mat-option *ngFor="let program of programs" [value]="program">
            {{ program.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field class="mbp-full-width">
        <input
          matInput
          mbp-mask-date
          class="input"
          placeholder="Select a start date"
          formControlName="startDate"
          required
          [matDatepicker]="startDatePicker"
        />
        <mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
        <mat-datepicker touchUi #startDatePicker></mat-datepicker>
      </mat-form-field>
      <mat-form-field class="mbp-full-width">
        <input
          matInput
          mbp-mask-date
          class="input"
          placeholder="Select an end date"
          formControlName="endDate"
          required
          [matDatepicker]="endDatePicker"
        />
        <mat-datepicker-toggle matSuffix [for]="endDatePicker"></mat-datepicker-toggle>
        <mat-datepicker touchUi #endDatePicker></mat-datepicker>
      </mat-form-field>
      <mat-form-field class="mbp-full-width">
        <mat-select placeholder="Select a contract status" formControlName="contractStatus">
          <mat-option *ngFor="let contractStatusType of contractStatusTypes" [value]="contractStatusType">
            {{ contractStatusType.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <button mat-raised-button color="primary" type="button" [disabled]="!searchCriteriaForm.valid || isLoading" (click)="search()">
        <i *ngIf="isLoading" class="fa fa-circle-o-notch fa-spin"></i> {{ isLoading ? 'Searching...' : 'Search' }}
      </button>
    </form>
  </div>
</div>
