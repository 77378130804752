export class ChartHorizontalBarData {
  //#region : Constructor :
  constructor(label?: string, backgroundColor?: string, stack?: string, data?: any) {
    this.label = label;
    this.backgroundColor = backgroundColor;
    this.stack = stack;
    this.data = data;
  }
  //#endregion

  //#region : Properties :
  public label: string;
  public backgroundColor: string;
  public stack: string;
  public data: any;
  //#endregion
}
