import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Select } from '@ngxs/store';
import { AuthState } from 'app/core/state/auth.state';
import { Observable } from 'rxjs';

@Component({
  templateUrl: './contract-search-help.component.html',
  styleUrls: ['./contract-search-help.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContractSearchHelpComponent {
  @Select(AuthState.props.currentUserIsMBPI) currentUserIsMbpi: Observable<boolean>;
}
