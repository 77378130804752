import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { invert } from 'lodash';
import { of } from 'rxjs';
import { catchError, filter, map, switchMap, take, tap } from 'rxjs/operators';
import { AuthState } from '..';
import { SystemService } from '../api/system.service';
import { AnnouncementsRequest } from '../models/announcements-request.model';
import { AppStateModel } from '../models/app-state.model';
import { ClickupTaskItem } from '../models/clickup-task.model';
import { RoleType, TechnologyType } from '../models/enums';
import { AlertService } from '../services/alert.service';
import { FetchNotifications } from './notifications.actions';

export interface NotificationsStateModel {
  notifications: ClickupTaskItem[];
}

const defaultState: NotificationsStateModel = {
  notifications: [],
};

@State<NotificationsStateModel>({
  name: 'announcements',
  defaults: {
    ...defaultState,
  },
})
@Injectable()
export class NotificationsState {
  @Selector()
  public static notifications(state: NotificationsStateModel) {
    return state.notifications;
  }

  constructor(private store: Store, private system: SystemService, private alert: AlertService) {}

  @Action(FetchNotifications)
  fetchAnnouncements({ patchState }: StateContext<NotificationsStateModel>) {
    const userRoleType = this.store.selectSnapshot(AuthState.props.user).roleType;
    const reversedEnum = invert(RoleType);

    return this.store
      .select((state: AppStateModel) => state)
      .pipe(
        filter((state) => !!state.auth?.user?.username),
        take(1),
        map(
          (state) =>
            ({
              technologyType: TechnologyType.Portal,
              userName: state.auth?.user?.username,
            } as AnnouncementsRequest),
        ),
        switchMap((request) => this.system.getAnnouncements(request)),
        map((items) => items.tasks.filter((item) => item.tags.find((tag) => tag?.name === reversedEnum[userRoleType].toLocaleLowerCase()))),
        tap((notifications) => patchState({ notifications })),
        catchError((error) => {
          // NOTE: not showing the alert, until we can work around our rate limit in clickup
          // this.alert.showMessage('There was a problem getting announcements.', '', ToastType.error);
          return of(null);
        }),
      );
  }
}
