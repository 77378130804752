import { ContractMinimal } from './contract-minimal.model';

export class RaterHistory {
  //#region : Constructor :
  constructor(
    public purchasedContractID?: number,
    public contractStatusTypeID?: number,
    public historyDate?: Date,
    public contractNumber?: string,
    public firstName?: string,
    public lastName?: string,
    public vin?: string,
    public programTypeID?: number,
    public planDescription?: string,
    public coverageMiles?: number,
    public coverageMonths?: number,
    public selectedPlanCount?: number,
    public isSavedQuote?: boolean,
    public savedQuoteID?: string,
    public coverageDisplayTypeID?: number,
    public isEditable?: boolean,
    public isVoidedContract?: boolean,
    public dealerCode?: string,
    public menuGroupTypeID?: number,
    public activityXml?: string,
    public programCode?: string,
    public canBeVoided?: boolean,
    public contractStatusTypeName?: string,
    public createUsername?: string,
    public createUserFullName?: string,
    public dealerName?: string,
    public vehicleYear?: number,
    public vehicleMakeDescription?: string,
    public vehicleModelDescription?: string,
    public vehicleNumberWheelsDriving?: number,
    public vehicleCylinderNumber?: number,
    public vehicleFuelTypeDescription?: string,
    public vehicleHasTurbo?: boolean,
    public vehicleHasSupercharger?: boolean,
    public groupKey?: string,
    public planGroupName?: string,
    public isPurchased?: boolean,
  ) {}
  //#endregion

  //#region : Properties :

  public isLimitedLifetimeWarrantyPlan: boolean;
  public pointOfSaleOrderIdentifier: string;
  public pointOfSaleTransactionNumber: string;
  public pointOfSalePaymentAmount: number;
  public quoteResponseID: string;
  public quoteDealerCode: string;
  //#endregion

  //#region : Methods :
  static createFromObject(obj: object) {
    const raterHistory = new RaterHistory();
    Object.assign(raterHistory, obj);
    return raterHistory;
  }

  getCoverageDisplay(): string {
    let display = '';

    if (this.isLimitedLifetimeWarrantyPlan === true) {
      return '';
    }

    if (this.coverageDisplayTypeID === 1) {
      // Months
      display += this.coverageMonths > 0 ? this.coverageMonths.toString() + ' month' + (this.coverageMonths > 1 ? 's' : '') : 'Unlimited';
    } else {
      // Years
      const years = this.coverageMonths / 12;
      display += years.toString() + ' year' + (years > 1 ? 's' : '');
    }

    if (this.coverageMiles < 999999) {
      display += ' / ' + (this.coverageMiles != null && this.coverageMiles > 0 ? this.coverageMiles.toFixed(0) : '0') + ' miles';
    }

    return display;
  }

  toContractMinimal() {
    const contractMinimal = new ContractMinimal();
    Object.assign(contractMinimal, this);
    return contractMinimal;
  }
  //#endregion
}
