import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'mbp-loading-container',
  templateUrl: './loading-container.component.html',
  styleUrls: ['./loading-container.component.scss'],
})
export class LoadingContainerComponent {
  //#region : Variable Declarations :
  @Input() showReload = false;
  @Input() isLoading = false;
  @Input() hasError = false;
  @Output() reload: EventEmitter<boolean> = new EventEmitter();
  //#endregion

  //#region : Constructor :
  constructor() {}
  //#endregion

  //#region : Properties :

  //#endregion

  //#region : Implementation Methods :
  //#endregion

  //#region : Methods :
  public refresh() {
    this.reload.emit(true);
  }
  //#endregion
}
