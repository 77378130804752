import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgxsActionsExecutingModule } from '@ngxs-labs/actions-executing';
import { NgxsFormPluginModule } from '@ngxs/form-plugin';
import { ComponentsModule } from './components/components.module';
import { DirectivesModule } from './directives/directives.module';
import { PipesModule } from './pipes/pipes.module';
import { VendorModule } from './vendor.module';

@NgModule({
  exports: [
    CommonModule,
    ComponentsModule,
    DirectivesModule,
    FlexLayoutModule,
    FormsModule,
    NgxsActionsExecutingModule,
    NgxsFormPluginModule,
    PipesModule,
    ReactiveFormsModule,
    RouterModule,
    VendorModule,
  ],
})
export class SharedModule {}
