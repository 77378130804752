import { DealerMinimal, FormState } from '@mbp/core';
import {
  GetLegacyPolicyContractQuery,
  GetPolicyContractQuery,
  GraphQlResponse,
  InsertPreAuthorizationMutation,
  SelectPreAuthorizationQuery,
} from '@mbp/core/graphql';

export const DENT_REPAIR_FEE_MAX = 250;
export const DISPOSAL_FEE_MAX = 5;
export const MOUNT_AND_BALANCE_FEE_MAX = 30;
export const MOUNT_AND_BALANCE_FEE_MAX_RUN_FLAT = 40;
export const NON_OEM_PART_MAX = 400;
export const TIRE_REPAIR_FEE_MAX = 35;
export const TIRE_REPAIR_FEE_MAX_RUN_FLAT = 45;
export const TIRE_TAX_FEE_MAX = 5;
export const TOTAL_MAX = 400;
export const WINDSHIELD_REPAIR_FEE_MAX = 150;

export type PayViaType = keyof typeof PayVia;
export const PayVia = {
  Check: 'Check',
  CreditCard: 'CreditCard',
} as const;

export interface ClaimsAuthorizationFormModel {
  account: DealerMinimal;
  name: string;
  phone: string;
  roNumber: string;
  salesTaxRate: number;
  descriptionOfFailure: string;
  mileage: number;
  repairCosts: number[];
  payment: {
    payVia: PayViaType;
    faxOrEmail: string;
  };
  tireAndWheel: TireAndWheelFormModel;
}

export interface ClaimsAuthorizationStateModel {
  accountSalesTax: number;
  form: FormState<ClaimsAuthorizationFormModel>;
  insertPreAuthorizationResponse: GraphQlResponse<InsertPreAuthorizationMutation>;
  legacyPolicyContract: GraphQlResponse<GetLegacyPolicyContractQuery>;
  contractNotFound: boolean;
  policyContract: GraphQlResponse<GetPolicyContractQuery>;
  preAuthorization: GraphQlResponse<SelectPreAuthorizationQuery>;
}

export interface TireAndWheelFormModel {
  disposalFee: number;
  isRunFlatTire: boolean;
  mountAndBalanceFee: number;
  numberOfTires: number;
  parts: Part[];
  tireRepairFees: TireRepairFee[];
  tireSize: string;
  tireTaxFee: number;
}

export interface Part {
  id: string;
  quantity: number;
  number: string;
  description: string;
  cost: number;
  retailPrice: number;
  found: boolean;
}

export interface TireRepairFee {
  quantity: number;
  description: string;
  cost: number;
}

export interface Submit {
  submit(): void;
}

export const ClaimsFormRoutes = {
  TireAndWheel: 'tire-and-wheel',
  Dent: 'dent',
  Windshield: 'windshield',
  Choose: 'choose',
} as const;
