import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { AccountPaymentMethodType, AppStateModel, RemittanceSummaryRequest, RightSidebarService, SearchCriteriaPageType } from '@mbp/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngxs/store';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

@UntilDestroy()
@Component({
  selector: 'mbp-remittance-summary-search-criteria',
  templateUrl: './remittance-summary-search-criteria.component.html',
  styleUrls: ['./remittance-summary-search-criteria.component.scss'],
})
export class RemittanceSummarySearchCriteriaComponent implements OnInit {
  //#region : Variable Declarations :
  formErrors = {
    dealer: '',
    dealerCode: '',
    startDate: '',
    endDate: '',
  };
  validationMessages = {
    dealer: {
      required: 'Please select a dealer',
      dealer: 'Please select a valid dealer',
    },
    dealerCode: {
      required: 'Please select a dealer',
      dealer: 'Please select a valid dealer',
    },
    startDate: {
      required: 'Please enter a start date',
    },
    endDate: {
      required: 'Please enter a end date',
    },
  };

  isLoading = false;
  defaultDealerSearchText: string = null;
  searchCriteriaForm: FormGroup;

  searchCriteriaRequest = new RemittanceSummaryRequest();

  hasPortalFilter = false;
  paymentMethodTypes: AccountPaymentMethodType[] = [AccountPaymentMethodType.ACH, AccountPaymentMethodType.Check];
  AccountPaymentMethodType = AccountPaymentMethodType;
  private subscription = new Subscription();
  //#endregion

  //#region : Constructor :
  constructor(private formBuilder: FormBuilder, private store: Store, private rightSidebarService: RightSidebarService) {}
  //#endregion

  //#region : Properties :
  //#endregion

  //#region : Implementation Methods :
  ngOnInit() {
    this.buildForm();

    setTimeout(() => {
      this.loadLookupData();
    }, 500);

    this.subscription.add(
      this.store
        .select((appState: AppStateModel) => appState.dealer.currentDealerMinimal)
        .pipe(
          filter((dm) => !!dm),
          untilDestroyed(this),
        )
        .subscribe((dealerMinimal) => {
          this.searchCriteriaForm.patchValue({
            dealer: dealerMinimal,
          });
          this.searchCriteriaForm.get('dealer').disable();
          this.search();
        }),
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  //#endregion

  //#region : Methods :
  buildForm() {
    const startDate = new Date();
    const endDate = new Date();

    this.searchCriteriaForm = this.formBuilder.group({
      startDate,
      endDate,
      dealer: null,
      paymentMethodType: '',
    });
    this.searchCriteriaForm.valueChanges.subscribe((data) => this.onFormValueChanged(data));
  }

  loadLookupData() {}

  public onFormValueChanged(data?: any) {
    if (!this.searchCriteriaForm) {
      return;
    }
    const form = this.searchCriteriaForm;
    for (const field in this.formErrors) {
      if (Object.prototype.hasOwnProperty.call(this.formErrors, field)) {
        this.formErrors[field] = '';
        const control = form.get(field);
        if (control && control.dirty && !control.valid) {
          const messages = this.validationMessages[field];
          for (const key in control.errors) {
            if (Object.prototype.hasOwnProperty.call(control.errors, key)) {
              this.formErrors[field] += messages[key] + ' ';
            }
          }
        }
      }
    }
  }

  public search() {
    const formValue = this.searchCriteriaForm.getRawValue();
    const searchCriteria = new RemittanceSummaryRequest();

    searchCriteria.dealer = formValue.dealer;
    searchCriteria.dealerCode = formValue.dealer && formValue.dealer.dealerCode;
    searchCriteria.endDate = formValue.endDate;
    searchCriteria.paymentMethodType = formValue.paymentMethodType;
    searchCriteria.startDate = formValue.startDate;

    this.rightSidebarService.setSearchCriteria(SearchCriteriaPageType.RemittanceSummaryReport, searchCriteria);
  }
  //#endregion
}
