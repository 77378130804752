import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { AuthState } from '../state/auth.state';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(private store: Store, private router: Router) {}

  canActivate() {
    const loggedIn = this.store.selectSnapshot(AuthState.props.user);
    if (loggedIn) {
      return true;
    }
    console.log('not authorized!');
    this.router.navigate(['']);
    return false;
  }
}
