<nb-card *ngFor="let notification of notifications" accent="info">
  <nb-card-header class="header">
    <div style="display: flex; justify-content: space-between">
      <div>{{ notification.name }}</div>
      <span class="sub-header">{{ notification.date_updated | momentFrom }}</span>
    </div>
  </nb-card-header>
  <nb-card-body class="card-body">
    <div></div>
    <div [innerHTML]="notification.description"></div>
  </nb-card-body>
</nb-card>
