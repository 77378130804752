<div style="padding: 10px; width: 330px;">
  <div fxLayout="row" style="padding-top: 5px; border-bottom: 1px solid lightgray;">
    <h2 style="display: block;">Search Criteria</h2>
  </div>
  <mat-divider></mat-divider>
  <div style="padding-top: 15px;">
    <form fxLayout="column" fxLayoutAlign="start stretch" [formGroup]="searchCriteriaForm">
      <mbp-agent-autocomplete
        #agentList
        [style.display]="isSingleAgent ? 'none' : ''"
        (singleAgentLoaded)="onSingleAgentLoaded()"
        class="mbp-full-width"
        formControlName="agency"
        style="padding-bottom: 15px;"
        required
      ></mbp-agent-autocomplete>
      <mat-form-field class="mbp-full-width">
        <mat-select placeholder="Select a program" formControlName="program">
          <mat-option value="">[All Programs]</mat-option>
          <mat-option *ngFor="let program of programs" [value]="program">
            {{ program.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <button mat-raised-button color="primary" type="button" [disabled]="!searchCriteriaForm.valid || isLoading" (click)="search()">
        <i *ngIf="isLoading" class="fa fa-circle-o-notch fa-spin"></i> {{ isLoading ? 'Searching...' : 'Search' }}
      </button>
    </form>
  </div>
</div>
