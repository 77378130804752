import { Injectable } from '@angular/core';
import { FormControl, FormGroupDirective, NgForm } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';

@Injectable({ providedIn: 'root' })
export class MbpErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    const hasBeenInteractedWith = control.dirty || control.touched || isSubmitted;
    const invalid = control && control.invalid;
    return !!(invalid && hasBeenInteractedWith);
  }
}
