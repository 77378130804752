import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  ApiResponse,
  InternalMessage,
  InternalMessageRequest,
  InternalMessagesRequest,
  MBPIUser,
  MBPIUsersRequest,
  SaveInternalMessageRequest,
} from '@mbp/core';
import { getApiResponseData } from '../rxjs/get-api-response-data';

@Injectable({
  providedIn: 'root',
})
export class MBPInternalService {
  constructor(private http: HttpClient) {}

  getInternalMessage(request: InternalMessageRequest) {
    return this.http.put<ApiResponse<InternalMessage>>(`/api/mbpinternal/message`, request).pipe(getApiResponseData());
  }

  getInternalMessages(request: InternalMessagesRequest) {
    return this.http.put<ApiResponse<InternalMessage[]>>(`/api/mbpinternal/messages`, request).pipe(getApiResponseData());
  }

  getMBPIUsers(request: MBPIUsersRequest) {
    return this.http.put<ApiResponse<MBPIUser[]>>(`/api/mbpinternal/mbpiusers`, request).pipe(getApiResponseData());
  }

  saveInternalMessage(request: SaveInternalMessageRequest) {
    return this.http.post<ApiResponse<boolean>>(`/api/mbpinternal/savemessage`, request).pipe(getApiResponseData());
  }
}
