<nb-card>
  <nb-card-header>Contract Search Help</nb-card-header>
  <nb-card-body>
    <b>Cancel Quote</b>
    <p>To start a cancel quote search for a contract first.</p>
    <p><b>Contract Card Icons</b></p>
    <ul class="ul-no-bullets">
      <li><nb-icon icon="download-outline"></nb-icon> Download</li>
      <li><nb-icon icon="slash-outline"></nb-icon> Print Cancel Form</li>
      <li *ngIf="currentUserIsMbpi | async"><nb-icon icon="refresh-outline"></nb-icon> Refresh from AS400</li>
      <li><nb-icon icon="arrow-circle-right-outline"></nb-icon> Start a Cancel Quote</li>
    </ul>
    <p><b>Advanced Search Filtering</b></p>
    <p>
      Start with Contract #, VIN, or Last Name.<br />
      Add additional filters to your search.<br />
      Separate them with a pipe: | without spaces.
    </p>
    <code class="italic"> fn ln dealer make model year state zip status </code>
    <div class="mt-1">Examples</div>
    <code>
      <pre>
smith|make:ford
80715|dealer:motors
15138628|model:f150|state:mo
smith|status:active
smith|fn:john
80715|ln:smith
      </pre>
    </code>
  </nb-card-body>
</nb-card>
