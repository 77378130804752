import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Router } from '@angular/router';
import { DealerMinimal } from '@mbp/core';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { ToolbarQueries, ToolbarViewModel } from './toolbar.queries';

@Component({
  selector: 'mbp-portal-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ToolbarComponent {
  @Select(ToolbarQueries.getViewModel) viewModel$: Observable<ToolbarViewModel>;

  constructor(private router: Router) {}

  onDealerSelected(dealerMinimal: DealerMinimal) {
    this.router.navigate(['dealers', dealerMinimal.dealerGroupIdentifier]);
  }
}
