import { RaterMenuDealerSetting } from './rater-menu-dealer-setting.model';

export class RaterSaveMenuSettingRequest {
  //#region : Constructor :
  constructor() {}
  //#endregion

  //#region : Properties :
  public userName: string;
  public dealerSetting: RaterMenuDealerSetting;
  //#endregion
}
