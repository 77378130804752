import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { catchError, filter, mergeMap, switchMap, take, tap } from 'rxjs/operators';
import { UserProfileService } from '../api/user-profile.service';
import { ApiResponse, ApiResponseCode } from '../models/ApiResponse';
import { isApiResponse } from '../utilities';

@Injectable({
  providedIn: 'root',
})
export class ApiInterceptor implements HttpInterceptor {
  private refreshingInProgress = false;
  private accessTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(private store: Store, private router: Router, private userProfileService: UserProfileService) {}

  intercept<T>(request: HttpRequest<ApiResponse<T>>, next: HttpHandler): Observable<HttpEvent<any>> {
    return of('').pipe(
      mergeMap(() => next.handle(request)),
      mergeMap((response: HttpEvent<ApiResponse<T>>) => {
        if (!(response instanceof HttpResponse)) {
          return of(response);
        }

        if (!isApiResponse(response.body)) {
          return of(response);
        }

        if (response.body.code == ApiResponseCode.Success) {
          return of(response);
        }
        if (response.body.code == ApiResponseCode.ExpiredJwt) {
          if (!this.refreshingInProgress) {
            this.refreshingInProgress = true;
            this.accessTokenSubject.next(null);
            return this.userProfileService.getSession().pipe(
              tap((res) => {
                // this.store.dispatch(new RefreshedToken(res))
              }),
              switchMap((profileSession) => {
                this.refreshingInProgress = false;
                this.accessTokenSubject.next(profileSession.accessToken);
                return next.handle(
                  request.clone({
                    setHeaders: {
                      Authorization: `Bearer ${profileSession.accessToken}`,
                      'x-custom-authorization': profileSession.accessToken,
                    },
                  }),
                );
              }),
              catchError((error) => {
                // if we couldn't get a new auth token, something's wrong...
                this.router.navigate(['user', 'logout']);
                return of(error);
              }),
            );
          } else {
            return this.accessTokenSubject.pipe(
              filter((token) => token != null),
              take(1),
              switchMap((token) => {
                return next.handle(
                  request.clone({
                    setHeaders: {
                      Authorization: `Bearer ${token}`,
                      'x-custom-authorization': token,
                    },
                  }),
                );
              }),
            );
          }
        }
        if (response.body.code == ApiResponseCode.Unauthorized) {
          this.router.navigate(['user', 'logout']);
          return of(response);
        }
        return throwError(response);
      }),
      // TODO: put back in once we're more confident in the new portal
      // retryBackoff({
      //   // 30 seconds total
      //   initialInterval: 5000,
      //   maxRetries: 6,
      //   resetOnSuccess: true,
      //   shouldRetry: (error: any) => {
      //     const httpError =
      //       isHttpError(error) && (error.status == 500 || error.status == 501 || error.status == 502 || error.status == 503);
      //     return httpError;
      //   },
      // }),
    );
  }
}
