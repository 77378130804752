import { Time } from '@angular/common';

export class InternalMessage {
  //#region : Constructor :
  constructor() {}
  //#endregion

  //#region : Properties :
  public messageID: string;
  public messageTypeID: number = null;
  public messageTypeName: string;
  public contractNumber = '';
  public vin = '';
  public contactCompanyName = '';
  public contactName = '';
  public contactPhoneNumber = '';
  public contactEmail = '';
  public subject = 'Please Call';
  public notes = '';
  public comments = '';
  public messageStatusTypeID: number = null;
  public messageStatusTypeName = '';
  public messageDate: Date;
  public messageTime: Time;
  public assignedToUsername = '';
  public createUsername = '';
  public createDate: Date;
  public updateUsername = '';
  public updateDate: Date;
  public assignedToFirstName = '';
  public assignedToLastName = '';
  public messageQueueType = '';
  public hasAssignedUser = false;
  public successfullyAssigned: boolean;
  public isNew = false;
  public history: InternalMessageHistory[];
  //#endregion

  //#region : Methods :
  static createFromObject(obj: object) {
    const message = new InternalMessage();
    Object.assign(message, obj);
    return message;
  }

  static toMinimal(obj: object) {
    const message = new InternalMessage();
    Object.assign(message, obj);
    message.history = null;
    return message;
  }
  //#endregion
}

export class InternalMessageHistory {
  //#region : Constructor :
  constructor() {}
  //#endregion

  //#region : Properties :
  public messageHistoryID: string;
  public messageTypeID: number;
  public messageTypeName: string;
  public messageStatusTypeID: number;
  public messageStatusTypeName: string;
  public notes: string;
  public comments: string;
  public updateUsername: string;
  public updateDate: Date;
  public updateUserFirstName: string;
  public updateUserLastName: string;
  //#endregion
}
