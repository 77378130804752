export class NewDealerDisbursementRequest {
  //#region : Constructor :
  constructor() {}
  //#endregion

  //#region : Properties :
  public dealerCode?: string;
  public programCode?: string;
  //#endregion
}
