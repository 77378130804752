import { isDate, isString, min } from 'lodash';
import { DateTime } from 'luxon';
import { Validator } from './validator';
import { ValidatorDetails } from './validator-details';

export interface DateValidatorPayload {
  value: string | Date | null;
  minValue: string | Date | null;
  maxValue: string | Date | null;
}

/**
 * This is best effort
 */
export class DateValidator implements Validator {
  public error: string | null;
  constructor(public propertyDetails: ValidatorDetails, public payload: DateValidatorPayload) {}

  public validate(): Validator {
    var dateValue = isDate(this.payload.value)
      ? DateTime.fromJSDate(this.payload.value)
      : isString(this.payload.value)
      ? DateTime.fromISO(this.payload.value)
      : null;
    var minDate = isDate(this.payload.minValue)
      ? DateTime.fromJSDate(this.payload.minValue)
      : isString(this.payload.minValue)
      ? DateTime.fromISO(this.payload.minValue)
      : null;
    var maxDate = isDate(this.payload.maxValue)
      ? DateTime.fromJSDate(this.payload.maxValue)
      : isString(this.payload.maxValue)
      ? DateTime.fromISO(this.payload.maxValue)
      : null;

    dateValue = dateValue.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
    minDate = minDate.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
    maxDate = maxDate.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });

    if (dateValue && !dateValue.isValid) {
      this.error = `${this.propertyDetails.displayName} is not a valid date.`;
    }

    if (dateValue && minDate && dateValue < minDate) {
      const formattedMinDate = minDate.toLocaleString(DateTime.DATE_SHORT);
      this.error = `${this.propertyDetails.displayName} is before the min date of ${formattedMinDate}.`;
    }

    if (dateValue && maxDate && dateValue > maxDate) {
      const formattedMaxDate = maxDate.toLocaleString(DateTime.DATE_SHORT);
      this.error = `${this.propertyDetails.displayName} is after the max date of ${formattedMaxDate}.`;
    }

    return this;
  }
}
