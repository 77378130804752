import { Component } from '@angular/core';
import { fadeInOut } from '@mbp/core';

@Component({
  selector: 'mbp-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss'],
  animations: [fadeInOut],
})
export class NotFoundComponent {}
