import { MessageType } from './enums';

export class InternalMessageType {
  //#region : Constructor :
  constructor(messageTypeID?: MessageType, name?: string) {
    this.messageTypeID = messageTypeID;
    this.name = name;
  }
  //#endregion

  //#region : Properties :
  public messageTypeID: MessageType;
  public name: string;
  //#endregion

  //#region : Methods :
  public static arrayList() {
    const messageTypes: InternalMessageType[] = [];

    messageTypes.push(new InternalMessageType(MessageType.All, '[All Types]'));
    messageTypes.push(new InternalMessageType(MessageType.ExistingClaim, 'Existing Claim'));
    messageTypes.push(new InternalMessageType(MessageType.InspectorCall, 'Inspector Call'));
    messageTypes.push(new InternalMessageType(MessageType.NewClaim, 'New Claim'));
    messageTypes.push(new InternalMessageType(MessageType.NewClaimSupervisor, 'New Claim - Supervisor'));
    messageTypes.push(new InternalMessageType(MessageType.Other, 'Other'));
    // messageTypes.push(new InternalMessageType(MessageType.PhoneIssue, 'Phone Issue'));

    return messageTypes;
  }
  //#endregion
}
