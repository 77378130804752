import { AuthState, AuthStateModel, BrandingState, BrandingStateModel } from '@mbp/core';
import { Selector } from '@ngxs/store';

export interface SidemenuComponentStateModel {
  brandLogo: string;
  isAgent: boolean;
  isDealer: boolean;
  isCustomer: boolean;
  isAgentOrDealer: boolean;
  canDoUserMaintenance: boolean;
  canDoRemittance: boolean;
  canDoRater: boolean;
  canDoReporting: boolean;
  isMBPIUser: boolean;
  hasAdministratorRole: boolean;
  hasDocuments: boolean;
  isAllowedClaimsPreAuth: boolean;
  isUserManagementEnabled: boolean;
}

export class SidemenuComponentSelector {
  @Selector([
    AuthState.getIsMasterAgent,
    AuthState.getIsMasterDealer,
    AuthState.props.currentUserIsMBPI,
    AuthState.getIsAdministrator,
    AuthState.getIsServiceUser,
    AuthState.getIsServiceUserLimited,
    AuthState.props.allowedClaimsPreAuth,
    AuthState,
    BrandingState,
  ])
  static getState(
    isMasterAgent: boolean,
    isMasterDealer: boolean,
    isMbpi: boolean,
    isAdministrator: boolean,
    isServiceUser: boolean,
    isServiceUserLimited: boolean,
    isAllowedClaimsPreAuth: boolean,
    auth: AuthStateModel,
    brand: BrandingStateModel,
  ): SidemenuComponentStateModel {
    const isMbpiOrMasterAgentDealer = isMbpi || isMasterDealer || isMasterAgent;
    const canDoUserMaintenance = isMbpiOrMasterAgentDealer && !isMbpi;
    const canDoRemittance = !isServiceUser && !isServiceUserLimited;
    const canDoRater = !isServiceUserLimited;
    const canDoReporting = !isServiceUser && !isServiceUserLimited;

    return {
      brandLogo: brand.logoFilename,
      isAgent: auth.currentUserIsAgent,
      isDealer: auth.currentUserIsDealer,
      isCustomer: auth.currentUserIsCustomer,
      isAgentOrDealer: auth.currentUserIsAgent || auth.currentUserIsDealer,
      canDoUserMaintenance,
      canDoRemittance,
      canDoRater,
      canDoReporting,
      isMBPIUser: auth.currentUserIsMBPI,
      hasAdministratorRole: isAdministrator,
      hasDocuments: auth.currentUserIsAgent || auth.currentUserIsDealer || isAdministrator,
      isAllowedClaimsPreAuth,
      isUserManagementEnabled: auth.userManagementEnabled,
    };
  }
}
