import { InjectionToken } from '@angular/core';
import * as UsaStatesPackage from 'usa-states';
import { State } from '../models/state.model';

export const UsaStates = new InjectionToken<State>('usa-states', {
  factory: () => {
    const usaStates = new (UsaStatesPackage as any).UsaStates();
    return usaStates.states;
  },
  providedIn: 'root',
});
