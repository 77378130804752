import { Directive, DoCheck, Input, OnInit, Optional, Self } from '@angular/core';
import { FormGroupDirective, NgControl, NgForm } from '@angular/forms';
import { MatCheckbox } from '@angular/material/checkbox';
import { ErrorStateMatcher, mixinErrorState } from '@angular/material/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

const _CustomCheckboxErrorStateBase = mixinErrorState(
  class {
    constructor(
      public _defaultErrorStateMatcher: ErrorStateMatcher,
      public _parentForm: NgForm,
      public _parentFormGroup: FormGroupDirective,
      public ngControl: NgControl,
    ) {}
  },
);

@UntilDestroy()
@Directive({
  selector: 'mat-checkbox',
  host: {
    class: 'mbp-directive-error-state',
    '[class.has-error-state]': 'errorState',
  },
})
export class CheckboxErrorStateDirective extends _CustomCheckboxErrorStateBase implements OnInit, DoCheck {
  @Input() errorStateMatcher: ErrorStateMatcher;

  constructor(
    private checkbox: MatCheckbox,
    defaultErrorStateMatcher: ErrorStateMatcher,
    @Optional() @Self() ngControl: NgControl,
    @Optional() parentForm: NgForm,
    @Optional() parentFormGroup: FormGroupDirective,
  ) {
    super(defaultErrorStateMatcher, parentForm, parentFormGroup, ngControl);
  }

  ngOnInit(): void {
    this.checkbox.change.pipe(untilDestroyed(this)).subscribe(() => this.updateErrorState());
  }

  ngDoCheck() {
    if (this.ngControl) {
      // We need to re-evaluate this on every change detection cycle, because there are some
      // error triggers that we can't subscribe to (e.g. parent form submissions). This means
      // that whatever logic is in here has to be super lean or we risk destroying the performance.
      this.updateErrorState();
    }
  }
}
