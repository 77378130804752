import { Validator } from './validator';
import { ValidatorDetails } from './validator-details';

export interface MinimumValidatorPayload {
  minimum: number;
  value: number | null;
}

export class MinimumValidator implements Validator {
  public error: string | null;
  constructor(public propertyDetails: ValidatorDetails, public payload: MinimumValidatorPayload) {}

  validate(): Validator {
    if (this.payload.minimum <= this.payload.value) {
      this.error = null;
    } else {
      this.error = this.propertyDetails.customMessage ? this.propertyDetails.customMessage : `Must be at least ${this.payload.minimum}.`;
    }
    return this;
  }
}
