import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ApiResponse, Retail } from '@mbp/core';
import { PORTAL_API_BASE_URL } from '../core.module';
import { getApiResponseData } from '../rxjs/get-api-response-data';

@Injectable({
  providedIn: 'root',
})
export class RetailService {
  constructor(private http: HttpClient, @Inject(PORTAL_API_BASE_URL) private baseUrl: string) {}

  get(dealerCode: string) {
    return this.http
      .get<ApiResponse<Retail[]>>(`${this.baseUrl}/api/retail`, { params: { dealerCode } })
      .pipe(getApiResponseData());
  }

  create(request: Retail) {
    return this.http.post<ApiResponse<Retail>>(`${this.baseUrl}/api/retail`, request).pipe(getApiResponseData());
  }

  update(request: Retail) {
    return this.http.put<ApiResponse<Retail>>(`${this.baseUrl}/api/retail`, request).pipe(getApiResponseData());
  }

  delete(request: Retail) {
    return this.http
      .delete<ApiResponse<void>>(`${this.baseUrl}/api/retail`, { params: { id: request.id } })
      .pipe(getApiResponseData());
  }
}
