import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate } from '@angular/router';
import { NbWindowState } from '@nebular/theme';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { ContractSearchWindowOpen } from '../state/contract-search.actions';
@Injectable({
  providedIn: 'root',
})
export class ContractSearchGuard implements CanActivate {
  canActivate(route: ActivatedRouteSnapshot): boolean {
    this.open(route.params.screenSize);
    return true;
  }

  @Dispatch()
  open(screenSize: NbWindowState) {
    return new ContractSearchWindowOpen({ screenSize });
  }
}
