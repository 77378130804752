export class PendingContractHistory {
  //#region : Constructor :
  constructor() {}
  //#endregion

  //#region : Properties :

  public remitGroupIdentifier: string;
  public purchasedContractID: number;
  public programCode: string;
  public programName: string;
  public programTypeID: number;
  public dealerCode: string;
  public dealerName: string;
  public contractNumber: number;
  public customerFirstName: string;
  public customerLastName: string;
  public vin: string;
  public lenderName: string;
  public planCode: string;
  public planDescription: string;
  public coverageDisplayTypeID: number;
  public coverageMiles: number;
  public coverageMonths: number;
  public retailAmount: number;
  public customerPrice: number;
  public discountAmount: number;
  public dealerRemitAmount: number;
  public createDate: Date;
  public hasBeenImportedIntoAS400: boolean;
  public isRatingEngineContract: boolean;
  public isMBPIFinanced: boolean;
  public isVoided: boolean;
  public isRemitted: boolean;
  public createUser: boolean;
  public activityDate: Date;
  public activityDateTime: Date;
  public voidReason: string;

  //#endregion

  static createFromObject(obj: object) {
    const remittanceHistory = new PendingContractHistory();
    Object.assign(remittanceHistory, obj);
    return remittanceHistory;
  }

  getCoverageDisplay(): string {
    let display = '';

    if (this.coverageDisplayTypeID === 1) {
      // Months
      display += this.coverageMonths > 0 ? this.coverageMonths.toString() + ' month' + (this.coverageMonths > 1 ? 's' : '') : 'Unlimited';
    } else {
      // Years
      const years = this.coverageMonths / 12;
      display += years.toString() + ' year' + (years > 1 ? 's' : '');
    }

    if (this.coverageMiles < 999999) {
      display += ' / ' + (this.coverageMiles != null && this.coverageMiles > 0 ? this.coverageMiles.toFixed(0) : '0') + ' miles';
    }

    return display;
  }
}
