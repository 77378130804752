export class FetchVehicleYears {
  public static readonly type = '[Lookup] Fetch Vehicle Years';
}

interface FetchVehicleMakesPayload {
  year: string;
}

export class FetchVehicleMakes {
  public static readonly type = '[Lookup] Fetch Vehicle Makes';
  constructor(public payload: FetchVehicleMakesPayload) {}
}

interface FetchVehicleModelsPayload {
  year: string;
  makeCode: string;
}

export class FetchVehicleModels {
  public static readonly type = '[Lookup] Fetch Vehicle Models';
  constructor(public payload: FetchVehicleModelsPayload) {}
}

interface FetchVehicleCylindersPayload {
  year: string;
  makeCode: string;
  modelName: string;
}

export class FetchVehicleCylinders {
  public static readonly type = '[Lookup] Fetch Vehicle Cylinders';
  constructor(public payload: FetchVehicleCylindersPayload) {}
}

interface FetchVehicleDrivingWheelsPayload {
  year: string;
  makeCode: string;
  modelName: string;
  cylinders: string;
}

export class FetchVehicleDrivingWheels {
  public static readonly type = '[Lookup] Fetch Vehicle Driving Wheels';
  constructor(public payload: FetchVehicleDrivingWheelsPayload) {}
}

interface FetchVehicleFuelTypePayload {
  year: string;
  makeCode: string;
  modelName: string;
  cylinders: string;
  drivingWheelsCode: string;
}

export class FetchVehicleFuelTypes {
  public static readonly type = '[Lookup] Fetch Vehicle Fuel Type';
  constructor(public payload: FetchVehicleFuelTypePayload) {}
}

interface FetchDealerRepresentativePayload {
  dealerCode: string;
}

export class FetchDealerRepresentatives {
  public static readonly type = '[Lookup] Fetch Dealer Representatives';
  constructor(public payload: FetchDealerRepresentativePayload) {}
}

export class FetchPrograms {
  public static readonly type = '[Lookup] Fetch Programs';
}
