import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { makeQuery } from './make-query';
import {
  InsertPreAuthorizationDocument,
  InsertPreAuthorizationMutationVariables,
  SelectPreAuthorizationDocument,
  SelectPreAuthorizationQueryVariables,
} from './generated/graphql';

@Injectable({
  providedIn: 'root',
})
export class ClaimsAuthorizationQueries {
  constructor(private http: HttpClient) {}

  insertPreAuthorization = (variables: InsertPreAuthorizationMutationVariables) =>
    makeQuery({
      http: this.http,
      operation: InsertPreAuthorizationDocument,
      variables,
    });

  getPreAuthorization = (variables: SelectPreAuthorizationQueryVariables) =>
    makeQuery({
      http: this.http,
      operation: SelectPreAuthorizationDocument,
      variables,
    });
}
