import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import {
  AppStateModel,
  DealerMinimal,
  LookupService,
  NewDealerDisbursementRequest,
  PortalFilterService,
  Program,
  RightSidebarService,
  SearchCriteriaPageType,
} from '@mbp/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngxs/store';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

@UntilDestroy()
@Component({
  selector: 'mbp-new-dealer-disbursement-search-criteria',
  templateUrl: './new-dealer-disbursement-search-criteria.component.html',
})
export class NewDealerDisbursementSearchCriteriaComponent implements OnInit, OnDestroy {
  //#region : Variable Declarations :
  formErrors = {
    dealer: '',
    dealerCode: '',
  };
  validationMessages = {
    dealer: {
      required: 'Please select a dealer',
      dealer: 'Please select a valid dealer',
    },
    dealerCode: {
      required: 'Please select a dealer',
      dealer: 'Please select a valid dealer',
    },
  };

  isLoading = false;
  searchCriteriaForm: FormGroup;

  programs: Program[] = [];
  selectedDealer: DealerMinimal = null;
  selectedDealerCode: string = null;
  private subscription = new Subscription();
  //#endregion

  //#region : Constructor :
  constructor(
    private formBuilder: FormBuilder,
    private store: Store,
    private lookupService: LookupService,
    private rightSidebarService: RightSidebarService,
    private portalFilterService: PortalFilterService,
  ) {}
  //#endregion

  //#region : Properties :
  //#endregion

  //#region : Implementation Methods :
  ngOnInit() {
    this.buildForm();
    this.updateWithPortalFilter();

    setTimeout(() => {
      this.loadLookupData();
    }, 500);

    this.subscription.add(
      this.rightSidebarService.runImmediately$
        .pipe(
          filter((sc) => !!sc),
          filter(({ searchCriteriaPageType }) => searchCriteriaPageType === SearchCriteriaPageType.NewDealerDisbursementReport),
          untilDestroyed(this),
        )
        .subscribe((sc) => {
          this.updateWithPortalFilter(sc.eventData);
          this.search();
        }),
    );

    if (this.selectedDealer != null) {
      this.search();
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
  //#endregion

  //#region : Methods :
  buildForm() {
    this.searchCriteriaForm = this.formBuilder.group({
      dealer: null,
      program: '',
    });
    this.searchCriteriaForm.valueChanges.subscribe((data) => this.onFormValueChanged(data));
  }

  loadLookupData() {
    this.lookupService.getPrograms({}).subscribe(
      (data) => this.onProgamsLoadSuccessful(data),
      (error) => this.onProgramsLoadFailed(error),
    );
  }

  public onFormValueChanged(data?: any) {
    if (!this.searchCriteriaForm) {
      return;
    }
    const form = this.searchCriteriaForm;
    for (const field in this.formErrors) {
      if (Object.prototype.hasOwnProperty.call(this.formErrors, field)) {
        this.formErrors[field] = '';
        const control = form.get(field);
        if (control && control.dirty && !control.valid) {
          const messages = this.validationMessages[field];
          for (const key in control.errors) {
            if (Object.prototype.hasOwnProperty.call(control.errors, key)) {
              this.formErrors[field] += messages[key] + ' ';
            }
          }
        }
      }
    }
  }

  onProgamsLoadSuccessful(programsData: Program[]) {
    this.programs = Array.isArray(programsData) ? programsData : [];
  }

  onProgramsLoadFailed(error: any) {}

  public search() {
    const formValue = this.searchCriteriaForm.getRawValue();
    const searchCriteria = new NewDealerDisbursementRequest();
    searchCriteria.dealerCode = formValue.dealer && formValue.dealer.dealerCode;
    searchCriteria.programCode = formValue.program && formValue.program.programCode;

    this.rightSidebarService.setSearchCriteria(SearchCriteriaPageType.NewDealerDisbursementReport, searchCriteria);
  }

  public updateWithPortalFilter(overrideDealer?: DealerMinimal) {
    this.selectedDealer =
      overrideDealer != null
        ? overrideDealer
        : this.portalFilterService.currentFilteredDealer != null
        ? this.portalFilterService.currentFilteredDealer
        : null;

    this.searchCriteriaForm.patchValue({
      dealer: this.selectedDealer,
    });

    const onlyDealer = this.store.selectSnapshot((appState: AppStateModel) => appState.dealer.dealerMinimals.length === 1);
    if (onlyDealer) {
      this.searchCriteriaForm.get('dealer').disable();
      this.searchCriteriaForm.updateValueAndValidity();
    }
  }
  //#endregion
}
