import { HttpClient } from '@angular/common/http';
import { ChangeDetectionStrategy, Component, Inject, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiResponse, getApiResponseData } from '@mbp/core';
import { PORTAL_API_BASE_URL } from 'app/core/core.module';
import * as moment from 'moment';
import { BehaviorSubject, concat, Observable, of, timer } from 'rxjs';
import { delay, map, skip, switchMap, tap } from 'rxjs/operators';

const delayInSeconds = 60 * 5;

@Component({
  selector: 'mbp-number-card',
  templateUrl: './number-card.component.html',
  styleUrls: ['./number-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NumberCardComponent implements OnInit {
  @Input() title: string;
  @Input() icon: string;
  @Input() color: string;
  @Input() body: any;
  @Input() endpoint: string;
  @Input() actionEndpoint: string;

  private lastUpdated = moment();
  updateTime$ = timer(0, 15000).pipe(map((_) => this.lastUpdated.fromNow()));
  loading = true;
  value$: Observable<any>;
  load$ = new BehaviorSubject('');

  constructor(private http: HttpClient, private router: Router, @Inject(PORTAL_API_BASE_URL) private baseUrl: string) {}

  ngOnInit(): void {
    const request$ = this.http.put<ApiResponse<number>>(`${this.endpoint}`, JSON.stringify(this.body)).pipe(
      getApiResponseData(),
      tap((_) => (this.loading = false)),
    );

    const whenToRefresh$ = of('').pipe(
      delay(1000 * delayInSeconds),
      tap((_) => this.load$.next('')),
      skip(1),
    );

    const poll$ = concat(request$, true ? whenToRefresh$ : of());

    this.value$ = this.load$.pipe(
      tap((_) => ((this.loading = true), (this.lastUpdated = moment()))),
      switchMap((_) => poll$),
    );
  }

  public cardClicked() {
    if (this.actionEndpoint != null) {
      this.router.navigate([this.actionEndpoint]);
    }
  }
}
