import { Component, Input, OnInit } from '@angular/core';
import { FetchNotifications } from '@mbp/core';
import { NbWindowRef, NbWindowService, NbWindowState } from '@nebular/theme';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngxs/store';
import { interval } from 'rxjs';
import { take } from 'rxjs/operators';
import { NotificationsWindowComponent } from '../notifications-window/notifications-window.component';

// TODO: possibly abstract these
function getReadNotificationIds(): string[] {
  const str = localStorage.getItem('readNotificationIds');
  return JSON.parse(str || `[]`);
}

function setReadNotificationIds(ids: string[]) {
  localStorage.setItem('readNotificationIds', JSON.stringify(ids));
}

const INTERVAL = 1000 * 60 * 5;

@UntilDestroy()
@Component({
  selector: 'mbp-toolbar-notifications',
  templateUrl: './toolbar-notifications.component.html',
  styleUrls: ['./toolbar-notifications.component.scss'],
})
export class ToolbarNotificationsComponent implements OnInit {
  unread: any[];

  private _notifications: any[];
  @Input() set notifications(value: any[]) {
    this._notifications = value;
    this.unread = this._notifications.filter((n) => {
      const readNotificationIds = getReadNotificationIds();
      const included = readNotificationIds.includes(n.id);
      return !included;
    });

    if (this.unread.length > 0) {
      this.toggleWindow(NbWindowState.FULL_SCREEN, true);
    }
  }
  get notifications() {
    return this._notifications;
  }

  windowRef: NbWindowRef;

  constructor(private windowService: NbWindowService, private store: Store) {}

  ngOnInit(): void {
    this.store.dispatch(new FetchNotifications());
    interval(INTERVAL)
      .pipe(untilDestroyed(this))
      .subscribe(() => this.store.dispatch(new FetchNotifications()));
  }

  toggleWindow(windowState: NbWindowState = NbWindowState.MAXIMIZED, open = false) {
    setReadNotificationIds(this.notifications.map((n) => n.id));
    if (!this.windowRef || open) {
      // if we're forcing it to stay open close the previous windowRef
      if (this.windowRef) {
        this.windowRef.close();
      }
      this.unread = this.notifications.filter((n) => {
        const readNotificationIds = getReadNotificationIds();
        const included = readNotificationIds.includes(n.id);
        return !included;
      });

      this.windowRef = this.windowService.open(NotificationsWindowComponent, {
        title: `Announcements`,
        initialState: windowState,
        windowClass: 'notifications-window',
        context: {
          notifications: this.notifications,
        },
      });
      this.windowRef.onClose.pipe(take(1)).subscribe(() => (this.windowRef = null));
    } else {
      this.windowRef.close();
    }
  }

  unreadCount() {
    return this.unread.length;
  }
}
