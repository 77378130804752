import { AfterContentInit, Directive, Input } from '@angular/core';
import { MatDatepicker } from '@angular/material/datepicker';

@Directive({
  selector: '[mbpAutoOpen]',
})
export class DatePickerAutoOpenDirective implements AfterContentInit {
  @Input() public mbpAutoOpen: boolean;

  public constructor(private el: MatDatepicker<Date>) {}

  public ngAfterContentInit() {
    setTimeout(() => {
      this.el.open();
    }, 0);
  }
}
