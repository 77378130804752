import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { LookupService } from '../api/lookup.service';
import { ZipLookupPlace, ZipLookupResponse } from '../models/zip-lookup-response.model';
import { AlertService, ToastType } from '../services/alert.service';
import { ZipCodeFindPlaces } from './zip-code.actions';

export interface ZipCodeLookupCache {
  [key: string]: ZipLookupPlace[];
}

export interface ZipCodeStateModel {
  zipCodeLookup: ZipCodeLookupCache;
  zipCodeLookupError: any;
}

const defaultState: ZipCodeStateModel = {
  zipCodeLookup: {},
  zipCodeLookupError: null,
};

@State<ZipCodeStateModel>({
  name: 'zipCode',
  defaults: {
    ...defaultState,
  },
})
@Injectable()
export class ZipCodeState {
  @Selector()
  static zipCodeLookup(state: ZipCodeStateModel) {
    return state.zipCodeLookup;
  }

  constructor(private lookupService: LookupService, private alertService: AlertService) {}

  @Action(ZipCodeFindPlaces)
  zipCodeFindPlaces({ getState, patchState }: StateContext<ZipCodeStateModel>, { payload }: ZipCodeFindPlaces) {
    if (payload && payload.length === 5) {
      const state = getState();
      const found = state.zipCodeLookup[payload];
      if (found) {
        return state.zipCodeLookup[payload];
      }
      return this.lookupService.getZipInformation(payload).pipe(
        map((response) => {
          const places = ZipLookupResponse.toPlaces(response.places);

          if (places != null) {
            patchState({
              zipCodeLookup: {
                ...state.zipCodeLookup,
                [payload]: places,
              },
            });
          }
        }),
        catchError((error: HttpErrorResponse) => {
          if (error.status === 404) {
            patchState({
              zipCodeLookup: {
                ...state.zipCodeLookup,
                [payload]: [{ city: '', stateAbbreviation: '', stateName: '' }],
              },
            });
          } else {
            this.alertService.showMessage('There was a problem locating zip code!', '', ToastType.error);
            patchState({
              zipCodeLookupError: error,
            });
          }
          return of(null);
        }),
      );
    }
  }
}
