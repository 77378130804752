import { AuthState, Contract, ContractSearchState, ContractStatusType, RoleType } from '@mbp/core';
import { createSelector } from '@ngxs/store';

export class ContractCardQueries {
  public static getViewModel(contract: Contract) {
    return createSelector(
      [
        AuthState.props.currentUserIsMBPI,
        AuthState.props.currentUserIsAgent,
        AuthState.props.user,
        ContractSearchState.getLinkForContract(contract),
      ],
      (currentUserIsMBPI: boolean, currentUserIsAgent: boolean, user: ReturnType<typeof AuthState.props.user>, href: string) => {
        const accent = accentMap[contract.contractStatusType] ?? 'primary';
        const status = null;

        const showPrintCancelForm = user.roleType === RoleType.MBPIUser;
        const showRefreshFromAs400 = user.roleType === RoleType.MBPIUser;
        const showCancelQuote = contract.contractStatusType == ContractStatusType.Active;

        const contractObj = Contract.createFromObject(contract);
        const coverageDisplay = contractObj.getCoverageDisplay();
        const expirationDisplay = contractObj.getExpirationMilesDisplay();

        return {
          accent,
          status,
          currentUserIsMBPI,
          currentUserIsAgent,
          showPrintCancelForm,
          showRefreshFromAs400,
          showCancelQuote,
          coverageDisplay,
          expirationDisplay,
          href,
        };
      },
    );
  }
}

type ThemeColors = 'basic' | 'primary' | 'info' | 'success' | 'warning' | 'danger' | 'control' | null;

const accentMap: Record<ContractStatusType, ThemeColors> = {
  [ContractStatusType.All]: 'success',
  [ContractStatusType.Active]: 'success',
  [ContractStatusType.Cancelled]: 'danger',
  [ContractStatusType.Denied]: 'danger',
  [ContractStatusType.Expired]: 'danger',
  [ContractStatusType.Suspense]: 'warning',
};
