import { InjectionToken } from '@angular/core';
import { TextMaskConfig } from 'angular2-text-mask';

export const PhoneMask = new InjectionToken<TextMaskConfig>('Phone Text Mask', {
  factory: () => ({
    mask: (value) => {
      if (value && value.length === 11) {
        // This is really only helpful if the phone number is auto filled and includes the 1 before the area code
        return [/\d/, ' ', '(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
      } else {
        return ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
      }
    },
  }),
  providedIn: 'root',
});
