<div style="padding: 10px; width: 330px">
  <div fxLayout="row" style="padding-top: 5px; border-bottom: 1px solid lightgray">
    <h2 style="display: block">Search Criteria</h2>
  </div>
  <mat-divider></mat-divider>
  <div style="padding-top: 15px">
    <form fxLayout="column" fxLayoutAlign="start stretch" [formGroup]="searchCriteriaForm">
      <mat-form-field class="mbp-full-width">
        <input matInput class="input" placeholder="Search text" formControlName="searchText" />
      </mat-form-field>
      <mat-form-field class="mbp-full-width" style="padding-top: 5px">
        <mat-select placeholder="Select a message type" formControlName="messageTypeID">
          <mat-option *ngFor="let messageType of messageTypes" [value]="messageType.messageTypeID">
            {{ messageType.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field class="mbp-full-width" style="padding-top: 5px">
        <mat-select placeholder="Select a message status" formControlName="messageStatusTypeID">
          <mat-option *ngFor="let messageStatus of messageStatuses" [value]="messageStatus.messageStatusTypeID">
            {{ messageStatus.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field class="mbp-full-width">
        <input
          matInput
          mbp-mask-date
          class="input"
          placeholder="Select a start date"
          formControlName="startDate"
          [matDatepicker]="startDatePicker"
        />
        <mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
        <mat-datepicker touchUi #startDatePicker></mat-datepicker>
      </mat-form-field>
      <mat-form-field class="mbp-full-width">
        <input
          matInput
          mbp-mask-date
          class="input"
          placeholder="Select an end date"
          formControlName="endDate"
          [matDatepicker]="endDatePicker"
        />
        <mat-datepicker-toggle matSuffix [for]="endDatePicker"></mat-datepicker-toggle>
        <mat-datepicker touchUi #endDatePicker></mat-datepicker>
      </mat-form-field>
      <mat-checkbox formControlName="onlyActiveMessages">Only Active Messages</mat-checkbox>
      <br />
      <button mat-raised-button color="primary" type="button" [disabled]="!searchCriteriaForm.valid || isLoading" (click)="search()">
        <i *ngIf="isLoading" class="fa fa-circle-o-notch fa-spin"></i> {{ isLoading ? 'Searching...' : 'Search' }}
      </button>
    </form>
  </div>
</div>
