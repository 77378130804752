import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { DealerMinimal, DealerSelectedEventItem, DealersService } from '@mbp/core';
import { DataResult, process } from '@progress/kendo-data-query';

@Component({
  selector: 'mbp-dealer-lookup-form',
  templateUrl: './dealer-lookup-form.component.html',
  styleUrls: ['./dealer-lookup-form.component.scss'],
})
export class DealerLookupComponent {
  //#region : Variable Declarations :
  isLoading = false;
  hasSearched = false;
  dialogOpen = false;
  dealers: DealerMinimal[] = [];
  gridViewData: DataResult;

  @Input() public searchText = '';
  @ViewChild('searchInput') searchInput: ElementRef;
  //#endregion

  //#region : Constructor :
  constructor(private dealersService: DealersService) {}
  //#endregion

  //#region : Properties/Events :
  @Input() public set isOpen(active: boolean) {
    this.dialogOpen = active;

    if (active) {
      setTimeout(() => {
        this.searchInput.nativeElement.focus();

        if (this.searchText != null && this.searchText.length > 0) {
          this.onSearch(this.searchText);
        } else {
          this.dealers = [];
          this.hasSearched = false;
        }
      }, 0);
    }
  }

  @Output() cancel: EventEmitter<any> = new EventEmitter();
  @Output() dealerSelect: EventEmitter<DealerSelectedEventItem> = new EventEmitter();
  //#endregion

  //#region : Implementation Methods :
  //#endregion

  //#region : Methods :
  closeForm(): void {
    this.cancel.emit();
  }

  public onCancel(e): void {
    e.preventDefault();
    this.closeForm();
  }

  onDealerSearchLoadSuccessful(dealersData: DealerMinimal[]) {
    let data = [];

    if (dealersData != null) {
      data = dealersData;
    }

    this.dealers = data;
    this.gridViewData = process(this.dealers, {});
    this.isLoading = false;
  }

  onDealerSearchLoadFailed(error: any) {
    this.isLoading = false;
  }

  public onSearch(searchText: string): void {
    if (searchText != null && searchText.length > 0) {
      this.isLoading = true;
      this.hasSearched = true;
      this.dealersService.dealers(searchText).subscribe(
        (data) => this.onDealerSearchLoadSuccessful(data),
        (error) => this.onDealerSearchLoadFailed(error),
      );
    }
  }

  public onSelectDealer(dealer: DealerMinimal): void {
    this.dealerSelect.emit(new DealerSelectedEventItem(dealer));
  }

  //#endregion
}
