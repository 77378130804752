<nb-form-field [nbSpinner]="isSearching">
  <button nbButton ghost nbPrefix (click)="search.emit(searchInput.value)">
    <nb-icon icon="search-outline" pack="eva"></nb-icon>
  </button>
  <input
    type="text"
    fullWidth
    nbInput
    [placeholder]="placeholder"
    #searchInput
    (keyup.enter)="search.emit(searchInput.value)"
    [value]="searchText"
  />
  <button *ngIf="!!searchText" nbButton ghost nbSuffix (click)="clear.emit(); onFocus()">
    <nb-icon icon="close-outline" pack="eva"></nb-icon>
  </button>
</nb-form-field>
