import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  ApiResponse,
  FileUploadResponse,
  ProfileSession,
  SendPushNotificationRequest,
  UpdateProfilePasswordRequest,
  UserAssociateContractRequest,
  UserUnassociateContractRequest,
} from '@mbp/core';
import { getApiResponseData } from '../rxjs/get-api-response-data';

@Injectable({
  providedIn: 'root',
})
export class UserProfileService {
  constructor(private http: HttpClient) {}

  getSession() {
    return this.http.get<ApiResponse<ProfileSession>>(`/api/profile/getsession`).pipe(getApiResponseData());
  }

  assumeIdentity(body: { username: string }) {
    return this.http.post<ApiResponse<ProfileSession>>(`/api/profile/assumeidentity`, body).pipe(getApiResponseData());
  }

  getAllowedClaimsPreAuth() {
    return this.http.get<ApiResponse<boolean>>(`/api/profile/allowedClaimsPreAuth`).pipe(getApiResponseData());
  }

  changePasswordRequest(request: { username: string }) {
    return this.http
      .post<ApiResponse<{ passwordSent: boolean }>>('/api/profile/change-password/request', request)
      .pipe(getApiResponseData());
  }

  changePasswordChange(request: { password: string; rid: string; uid: string }) {
    return this.http.post<ApiResponse<{ username: string }>>('/api/profile/change-password/change', request).pipe(getApiResponseData());
  }

  associateContract(request: UserAssociateContractRequest) {
    return this.http.post<ApiResponse<boolean>>(`/api/profile/associatecontract`, request).pipe(getApiResponseData());
  }

  unassociateContract(request: UserUnassociateContractRequest) {
    return this.http.post<ApiResponse<boolean>>(`/api/profile/unassociatecontract`, request).pipe(getApiResponseData());
  }

  updateAgencyLogo(request: FormData) {
    return this.http.post<ApiResponse<FileUploadResponse>>(`/api/profile/updateagencylogo`, request).pipe(getApiResponseData());
  }

  updateDealerLogo(request: FormData) {
    return this.http.post<ApiResponse<FileUploadResponse>>(`/api/profile/updatedealerlogo`, request).pipe(getApiResponseData());
  }

  updateProfilePassword(request: UpdateProfilePasswordRequest) {
    return this.http.patch<ApiResponse<boolean>>(`/api/profile/updateprofilepassword`, request).pipe(getApiResponseData());
  }

  updateProfilePhoto(request: FormData) {
    return this.http.post<ApiResponse<FileUploadResponse>>(`/api/profile/updateprofilephoto`, request).pipe(getApiResponseData());
  }

  sendPushNotification(request: SendPushNotificationRequest) {
    return this.http.put<ApiResponse<boolean>>(`/api/profile/sendpushnotification`, request).pipe(getApiResponseData());
  }
}
