import { Dispatch } from '@ngxs-labs/dispatch-decorator';

export class SetDealerCode {
  static readonly type = '[DEALER] SetDealerCode';
  constructor(public payload: string) {}
}

export class FetchDealerCount {
  static readonly type = '[DEALER STATE INIT] Fetch dealer count';
}

export class FetchDealerMinimals {
  static readonly type = '[DEALER STATE] Fetch dealer minimals';
}

export class DealerStateActions {
  @Dispatch()
  public static fetchDealerMinimals() {
    return new FetchDealerMinimals();
  }
}
