import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { AppStateModel, DEALER_COUNT_THRESHOLD, DealerMinimal, DealerSearchStateModel, DealerState, FetchDealerMinimals } from '@mbp/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { actionsExecuting, ActionsExecuting } from '@ngxs-labs/actions-executing';
import { Select, Store } from '@ngxs/store';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map } from 'rxjs/operators';

@UntilDestroy()
@Component({
  selector: 'mbp-dealer-search',
  templateUrl: './dealer-search.component.html',
  styleUrls: ['./dealer-search.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DealerSearchComponent),
      multi: true,
    },
  ],
})
export class DealerSearchComponent implements OnInit, ControlValueAccessor, AfterViewInit {
  @Select(actionsExecuting([FetchDealerMinimals])) searching$: Observable<ActionsExecuting>;
  @Select(DealerState.dealerSearch) dealerSearch$: Observable<DealerSearchStateModel>;
  @Select(DealerState.props.dealerMinimals) dealerMinimals$: Observable<DealerMinimal[]>;
  @Select(DealerState.props.dealerCount) dealerDropdownCount$: Observable<number>;

  @Input() label: string;
  @Input() error = false;
  @Input() submitted = false;
  @Input() text: string;
  @Input() dealerCode: string;
  @Input() showHint = true;
  @Output() selected = new EventEmitter<DealerMinimal>();
  @Output() cleared = new EventEmitter<void>();
  @Output() blurred = new EventEmitter<string>();
  @Output() inputted = new EventEmitter<string>();
  public DealerCountThreshold: number = DEALER_COUNT_THRESHOLD;
  @ViewChild(MatAutocompleteTrigger) autoTrigger: MatAutocompleteTrigger;

  searchText = new BehaviorSubject<string>('');
  selectedDealerCode = new BehaviorSubject<string>('');
  disabled = false;

  filteredDealers$: Observable<DealerMinimal[]>;

  onChange(value: DealerMinimal) {}
  onBlur(value: DealerMinimal) {}

  constructor(private store: Store) {}

  ngOnInit() {
    this.dealerDropdownCount$.pipe(untilDestroyed(this)).subscribe((num) => {
      if (num > DEALER_COUNT_THRESHOLD) {
        this.filteredDealers$ = combineLatest([this.searchText, this.dealerSearch$]).pipe(
          debounceTime(350),
          distinctUntilChanged(([stx], [sty]) => stx.trim() === sty.trim()),
          filter(([searchText, dealerSearch]) => !!dealerSearch && searchText.length > 0),
          filter(([_, dealerSearch]) => dealerSearch.fetchedDealerMinimals),
          map(([searchText, dealerSearch]) =>
            dealerSearch.dealerMinimals.reduce((acc, cur) => {
              const { name, dealerCode, phoneNumber, stateAbbreviation, zip } = cur;
              const term = searchText.trim().toLowerCase();

              if (searchText.length === 2 && stateAbbreviation.toLowerCase() === term) {
                acc.push(cur);
                return acc;
              } else if (
                name.toLowerCase().includes(term) ||
                dealerCode.toLowerCase().includes(term) ||
                phoneNumber.toLowerCase().includes(term) ||
                zip.toLowerCase().includes(term)
              ) {
                acc.push(cur);
              }
              return acc;
            }, []),
          ),
          untilDestroyed(this),
        );
      } else {
        this.filteredDealers$ = this.dealerMinimals$;
      }
    });
  }

  ngAfterViewInit() {
    this.autoTrigger?.panelClosingActions.pipe(untilDestroyed(this)).subscribe((_) => {
      if (this.autoTrigger.activeOption) {
        const dealerMinimal: DealerMinimal = this.autoTrigger.activeOption.value;
        const viewValue = this.autoTrigger.activeOption.viewValue;
        this.autoTrigger.writeValue(viewValue);
        this.autoTrigger._onChange(viewValue);
        this.onDealerSelected(dealerMinimal);
      }
    });
  }

  compareWithFn(dealer: DealerMinimal, dealerCode: string) {
    return dealer.dealerCode === dealerCode;
  }

  onKeyup(event: KeyboardEvent) {
    if (event.key === 'Enter' || event.key === 'ArrowDown' || event.key === 'ArrowUp') {
      return;
    }
    this.searchText.next((event.target as HTMLInputElement).value);
  }

  onFocus() {
    const needToFetch = this.store.selectSnapshot((state: AppStateModel) => !state.dealer.fetchedDealerMinimals);
    if (needToFetch) {
      this.store.dispatch(new FetchDealerMinimals());
    }
  }

  onDealerSelected(dm: DealerMinimal) {
    // Hack: send out the dealer minimal again...
    setTimeout(() => {
      this.searchText.next(`${dm.name} (${dm.dealerCode})${dm.expirationDate != null ? ' - Expired' : ''}`);
      this.selectedDealerCode.next(`${dm.dealerCode}${dm.expirationDate != null ? ' - Expired' : ''}`);
      this.onChange(dm);
      this.onBlur(dm);
      this.selected.emit(dm);
    }, 0);
  }
  onCleared() {
    this.searchText.next('');
    this.selectedDealerCode.next('');
    this.onChange(null);
    this.cleared.emit();
  }

  writeValue(obj: any): void {
    if (obj == null) {
      return;
    }
    if (typeof obj === 'string') {
      this.searchText.next(obj);
      return;
    }
    if (typeof obj === 'object') {
      this.searchText.next(`${obj.name} (${obj.dealerCode})${obj.expirationDate != null ? ' - Expired' : ''}` || '');
      return;
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onBlur = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}
