import { HttpResponse } from '@angular/common/http';
import { Inject, Injectable, Injector } from '@angular/core';
import { isApiResponse } from '@mbp/core';
import { ActiveToast, IndividualConfig, ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root',
})
export class AlertService {
  constructor(@Inject(Injector) private injector: Injector) {}

  showMessage(title: string, message?: string, severity = ToastType.info): ActiveToast<any> {
    return this.showMessageHelper({ message, title, toastType: severity, isSticky: false });
  }

  showStickyMessage(title: string, message?: string, toastType = ToastType.info): ActiveToast<any> {
    return this.showMessageHelper({ message, title, toastType, isSticky: true });
  }

  showCustomMessage({
    message,
    title,
    config,
    type,
  }: {
    message: string;
    title: string;
    config: Partial<IndividualConfig>;
    type: ToastType;
  }): ActiveToast<any> {
    switch (type) {
      case ToastType.error:
        return this.toastr.error(message, title, config);
      case ToastType.info:
        return this.toastr.info(message, title, config);
      case ToastType.success:
        return this.toastr.success(message, title, config);
      case ToastType.warning:
        return this.toastr.warning(message, title, config);
    }
  }

  startLoadingMessage(message = 'Loading...', caption = ''): ActiveToast<any> {
    return this.showMessageHelper({ message, title: caption, toastType: ToastType.info, isSticky: true });
  }

  stopLoadingMessage(): void {
    this.toastr.clear();
  }

  showApiError<T>(error: HttpResponse<T>) {
    if (error instanceof HttpResponse && isApiResponse(error.body)) {
      const message = error.body.errors?.[0]?.friendlyMessage ?? error.body.errors?.[0]?.message ?? "We're sorry, but an error occured.";
      return this.showMessageHelper({ message, title: 'Error', toastType: ToastType.error, isSticky: true });
    }
  }

  private showMessageHelper({
    message,
    title,
    toastType,
    isSticky = false,
  }: {
    message: string;
    title: string;
    toastType: ToastType;
    isSticky?: boolean;
  }): ActiveToast<any> {
    const dupe = this.toastr.findDuplicate(message, true, false);
    if (dupe) {
      return dupe;
    }

    switch (toastType) {
      case ToastType.error:
        return this.toastr.error(message, title, {
          closeButton: true,
          progressBar: true,
          disableTimeOut: isSticky,
          onActivateTick: true,
        });
      case ToastType.info:
        return this.toastr.info(message, title, {
          closeButton: true,
          progressBar: true,
          disableTimeOut: isSticky,
          onActivateTick: true,
        });
      case ToastType.success:
        return this.toastr.success(message, title, {
          closeButton: true,
          progressBar: true,
          disableTimeOut: isSticky,
          onActivateTick: true,
        });
      case ToastType.warning:
        return this.toastr.warning(message, title, {
          closeButton: true,
          progressBar: true,
          disableTimeOut: isSticky,
          onActivateTick: true,
        });
    }
  }

  // Need to get ToastrService from injector rather than constructor injection to avoid cyclic dependency error
  private get toastr(): ToastrService {
    return this.injector.get(ToastrService);
  }
}

export enum ToastType {
  error = 'Error',
  info = 'Info',
  success = 'Success',
  warning = 'Warning',
}
