import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, StateToken, Store } from '@ngxs/store';
import { of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { RetailService } from '../api/retail.service';
import { FormMode } from '../models/enums';
import { FormState } from '../models/form-state.model';
import { Retail, RetailWithDisplay } from '../models/retail.model';
import { AlertService } from '../services/alert.service';
import { AuthState } from './auth.state';
import {
  ClearRetailSettings,
  FetchRetailSettings,
  RetailSettingsCreate,
  RetailSettingsDelete,
  RetailSettingsEdit,
  RetailSettingsInit,
  RetailSettingsStartCreate,
  RetailSettingsStartEdit,
} from './retail-settings.actions';

export interface RetailSettingsModel {
  retailSettings: RetailWithDisplay[];
  form: FormState<Retail>;
  formMode: FormMode;
}

const defaultFormState: FormState<Retail> = {
  dirty: false,
  errors: null,
  model: {
    baseMarkup: null,
    customerPrice: null,
    dealerCode: null,
    endCoverageMonths: null,
    id: null,
    maxMarkup: null,
    minMarkup: null,
    programType: null,
    startCoverageMonths: null,
  },
  status: '',
};

const defaultState: RetailSettingsModel = {
  retailSettings: null,
  form: defaultFormState,
  formMode: FormMode.Create,
};

export const RETAIL_SETTINGS_STATE_TOKEN = new StateToken<RetailSettingsModel>('retail');

@State({
  name: RETAIL_SETTINGS_STATE_TOKEN,
  defaults: defaultState,
})
@Injectable()
export class RetailSettingsState {
  constructor(private store: Store, private api: RetailService, private alert: AlertService) {}

  @Selector()
  static retailSettings(state: RetailSettingsModel): Retail[] {
    return state.retailSettings;
  }

  @Selector()
  static formMode(state: RetailSettingsModel): FormMode {
    return state.formMode;
  }

  @Action(RetailSettingsInit)
  public init({ dispatch }: StateContext<RetailSettingsModel>) {
    dispatch(new FetchRetailSettings());
  }

  @Action(FetchRetailSettings)
  public fetchRetailSettings({ patchState }: StateContext<RetailSettingsModel>) {
    const dealerCode = this.store.selectSnapshot(AuthState.usersDealerCode);
    if (!dealerCode) return;

    return this.api.get(dealerCode).pipe(
      map((retailSettings) =>
        retailSettings.sort((a, b) => {
          if (a.programType > b.programType) return 1;
          if (a.programType < b.programType) return -1;
          if (a.startCoverageMonths > b.startCoverageMonths) return 1;
          if (a.startCoverageMonths < b.startCoverageMonths) return -1;
        }),
      ),
      map((retailSettings) =>
        retailSettings.map(
          (rs) =>
            ({
              ...rs,
              monthsRange: this.getMonthsRange(rs),
            } as RetailWithDisplay),
        ),
      ),
      tap((retailSettings) => patchState({ retailSettings })),
      catchError((error) => {
        this.alert.showStickyMessage(`Unable to get retail settings.`);
        return of(error);
      }),
    );
  }

  @Action(ClearRetailSettings)
  public clearRetailSettings({ patchState }: StateContext<RetailSettingsModel>) {
    patchState({
      retailSettings: [],
    });
  }

  @Action(RetailSettingsCreate)
  public retailSettingsFormSubmitted({ getState, dispatch, patchState }: StateContext<RetailSettingsModel>) {
    const { id, ...retail } = this.createRetailFromForm(getState);
    return this.api.create(retail as Retail).pipe(
      tap((_) => patchState({ form: defaultFormState })),
      tap((_) => dispatch(new FetchRetailSettings())),
      catchError((error) => {
        this.alert.showStickyMessage(`Unable to add retail settings.`);
        return of(error);
      }),
    );
  }

  @Action(RetailSettingsStartCreate)
  public retailSettingsStartCreate({ patchState }: StateContext<RetailSettingsModel>) {
    patchState({
      formMode: FormMode.Create,
      form: defaultFormState,
    });
  }

  @Action(RetailSettingsStartEdit)
  public retailSettingsStartEdit({ patchState }: StateContext<RetailSettingsModel>, { payload }: RetailSettingsStartEdit) {
    patchState({
      formMode: FormMode.Edit,
      form: {
        dirty: false,
        errors: null,
        status: '',
        model: { ...payload },
      },
    });
  }

  @Action(RetailSettingsEdit)
  public retailSettingsEdit({ getState, dispatch }: StateContext<RetailSettingsModel>) {
    const retail = this.createRetailFromForm(getState);

    return this.api.update(retail).pipe(
      tap((_) => dispatch(new FetchRetailSettings())),
      catchError((error) => {
        this.alert.showStickyMessage(`Unable to edit retail settings.`);
        return of(error);
      }),
    );
  }

  @Action(RetailSettingsDelete)
  public retailSettingsDelete({ dispatch }: StateContext<RetailSettingsModel>, { payload }: RetailSettingsDelete) {
    return this.api.delete(payload).pipe(
      tap((_) => dispatch(new FetchRetailSettings())),
      catchError((error) => {
        this.alert.showStickyMessage(`Unable to delete retail settings.`);
        return of(error);
      }),
    );
  }

  private getMonthsRange(retail: Retail) {
    if (retail.startCoverageMonths === 0 && retail.endCoverageMonths === 0) {
      return '';
    }
    if (retail.startCoverageMonths === retail.endCoverageMonths) {
      return `${retail.startCoverageMonths} Months`;
    }
    return `${retail.startCoverageMonths}${retail.endCoverageMonths > 0 && ` - ${retail.endCoverageMonths}`} Months`;
  }

  private createRetailFromForm(getState: () => RetailSettingsModel) {
    const dealerCode = this.store.selectSnapshot(AuthState.usersDealerCode);

    const state = getState();
    return {
      id: state.form.model.id,
      dealerCode,
      baseMarkup: +state.form.model.baseMarkup,
      customerPrice: state.form.model.customerPrice,
      endCoverageMonths: +state.form.model.endCoverageMonths,
      maxMarkup: +state.form.model.maxMarkup,
      minMarkup: +state.form.model.minMarkup,
      programType: +state.form.model.programType,
      startCoverageMonths: +state.form.model.startCoverageMonths,
    } as Retail;
  }
}
