export class DeletePortalUserRequest {
  //#region : Constructor :
  constructor(userName?: string, userProfileID?: number) {
    this.userName = userName;
    this.userProfileID = userProfileID;
  }
  //#endregion

  //#region : Properties :
  public userName: string;
  public userProfileID: number;
  //#endregion
}
