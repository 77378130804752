import { HttpClient } from '@angular/common/http';
import type { TypedDocumentNode } from '@graphql-typed-document-node/core';
import { print } from 'graphql';
import { GraphQlResponse } from './types';

type Input<TData, TVariables> = {
  http: HttpClient;
  operation: TypedDocumentNode<TData, TVariables>;
  variables?: TVariables;
};

export function makeQuery<TData, TVariables>({ http, operation, variables }: Input<TData, TVariables>) {
  return http
    .post<GraphQlResponse<TData>>(
      `v1/graphql`,
      {
        query: print(operation),
        variables,
      },
      {
        headers: {
          credentials: 'include',
        },
      },
    )
    .toPromise();
}
