import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AppStateModel, LookupService, Program, RaterHistoryRequest, RightSidebarService, SearchCriteriaPageType } from '@mbp/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngxs/store';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

@UntilDestroy()
@Component({
  selector: 'mbp-rater-history-search-criteria',
  templateUrl: './rater-history-search-criteria.component.html',
  styleUrls: ['./rater-history-search-criteria.component.scss'],
})
export class RaterHistorySearchCriteriaComponent implements OnInit, OnDestroy {
  //#region : Variable Declarations :
  formErrors = {
    dealer: '',
    dealerCode: '',
    startDate: '',
    endDate: '',
  };
  validationMessages = {
    dealer: {
      required: 'Please select a dealer',
      dealer: 'Please select a valid dealer',
    },
    dealerCode: {
      required: 'Please select a dealer',
      dealer: 'Please select a valid dealer',
    },
    startDate: {
      required: 'Please enter a start date',
    },
    endDate: {
      required: 'Please enter a end date',
    },
  };

  isLoading = false;
  searchCriteriaForm: FormGroup;

  programs: Program[] = [];
  searchCriteriaRequest = new RaterHistoryRequest();
  private subscription = new Subscription();
  //#endregion

  //#region : Constructor :
  constructor(
    private formBuilder: FormBuilder,
    private store: Store,
    private lookupService: LookupService,
    private rightSidebarService: RightSidebarService,
  ) {}
  //#endregion

  //#region : Properties :
  //#endregion

  //#region : Implementation Methods :
  ngOnInit() {
    this.buildForm();

    setTimeout(() => {
      this.loadLookupData();
    }, 500);

    this.subscription.add(
      this.rightSidebarService.runImmediately$
        .pipe(
          filter((d) => !!d),
          filter(({ searchCriteriaPageType }) => searchCriteriaPageType === SearchCriteriaPageType.RaterHistory),
          untilDestroyed(this),
        )
        .subscribe((sc) => {
          if (sc.eventData) {
            this.searchCriteriaForm.patchValue({ dealer: sc.eventData });

            const onlyDealer = this.store.selectSnapshot((appState: AppStateModel) => appState.dealer.dealerMinimals.length === 1);
            if (onlyDealer) {
              this.searchCriteriaForm.get('dealer').disable();
              this.searchCriteriaForm.updateValueAndValidity();
            }
          }
          this.search();
        }),
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
  //#endregion

  //#region : Methods :
  buildForm() {
    const startDate = new Date();
    const endDate = new Date();

    startDate.setMonth(startDate.getMonth() - 1);

    this.searchCriteriaForm = this.formBuilder.group({
      startDate,
      endDate,
      dealer: [null, Validators.required],
      program: '',
    });

    this.searchCriteriaForm.valueChanges.pipe(untilDestroyed(this)).subscribe((data) => this.onFormValueChanged(data));
  }

  loadLookupData() {
    this.lookupService.getPrograms({}).subscribe(
      (data) => this.onProgamsLoadSuccessful(data),
      (error) => this.onProgramsLoadFailed(error),
    );
  }

  public onFormValueChanged(data?: any) {
    if (!this.searchCriteriaForm) {
      return;
    }
    const form = this.searchCriteriaForm;
    for (const field in this.formErrors) {
      if (Object.prototype.hasOwnProperty.call(this.formErrors, field)) {
        this.formErrors[field] = '';
        const control = form.get(field);
        if (control && control.dirty && !control.valid) {
          const messages = this.validationMessages[field];
          for (const key in control.errors) {
            if (Object.prototype.hasOwnProperty.call(control.errors, key)) {
              this.formErrors[field] += messages[key] + ' ';
            }
          }
        }
      }
    }
  }

  onProgamsLoadSuccessful(programsData: Program[]) {
    this.programs = programsData != null ? programsData : [];
  }

  onProgramsLoadFailed(error: any) {}

  public search() {
    const formValue = this.searchCriteriaForm.getRawValue();
    const searchCriteria = new RaterHistoryRequest();

    searchCriteria.dealer = formValue.dealer;
    searchCriteria.dealerCode = formValue.dealer && formValue.dealer.dealerCode;
    searchCriteria.endDate = formValue.endDate;
    searchCriteria.programCode = formValue.program && formValue.program.programCode;
    searchCriteria.startDate = formValue.startDate;

    this.rightSidebarService.setSearchCriteria(SearchCriteriaPageType.RaterHistory, searchCriteria);
  }
  //#endregion
}
