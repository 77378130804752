export class DealerRequest {
  //#region : Constructor :
  constructor(dealerCode?: string, userName?: string, useSefi?: boolean) {
    this.dealerCode = dealerCode;
    this.userName = userName;
    this.useSefi = useSefi;
  }
  //#endregion

  //#region : Properties :
  public userName: string;
  public dealerCode: string;
  public useSefi: boolean;
  //#endregion
}
