export class InternalMessageRequest {
  //#region : Constructor :
  constructor() {}
  //#endregion

  //#region : Properties :
  public userName: string;
  public messageID: string;
  public assignToCurrentUser: boolean;
  //#endregion
}
