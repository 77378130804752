import { Inject, Injectable } from '@angular/core';
import { Action, NgxsAfterBootstrap, Selector, State, StateContext, StateToken } from '@ngxs/store';
import { WINDOW } from '../providers/window.provider';
import { SetBranding } from './branding.actions';

const mbpiBranding = {
  hostname: 'portal.mbpnetwork.com',
  isDirectConsumer: true,
  logoFilename: 'logo-white.mbpi.png',
  name: 'MBPI',
  title: 'MBPI',
};

const siteBrands: { [key: string]: Partial<BrandingStateModel> } = {
  localhost: mbpiBranding,
  'dev.mbpnetwork.com': mbpiBranding,
  'portal.mbpnetwork.com': mbpiBranding,
  'insurian.mbpnetwork.com': {
    hostname: 'insurian.mbpnetwork.com',
    isDirectConsumer: false,
    logoFilename: 'insurian-logo-small.png',
    name: 'Insurian',
    title: '',
  },
  'rtda.mbpnetwork.com': {
    hostname: 'risktheory.mbpnetwork.com',
    isDirectConsumer: false,
    logoFilename: 'risk-theory-logo-small.png',
    name: 'Risk Theory',
    title: '',
  },
  'paygo.mbpnetwork.com': {
    hostname: 'paygo.mbpnetwork.com',
    isDirectConsumer: false,
    logoFilename: 'paygologo.png',
    name: 'payGO by RTDA',
    title: '',
  },
  'cardealerbenefits.mbpnetwork.com': {
    hostname: 'cardealerbenefits.mbpnetwork.com',
    isDirectConsumer: false,
    logoFilename: 'cardealerbenefits.png',
    name: 'Car Dealer Benefits',
    title: '',
  },
  'lockton.mbpnetwork.com': {
    hostname: 'lockton.mbpnetwork.com',
    isDirectConsumer: true,
    logoFilename: 'locktonlogo.png',
    name: 'Lockton',
    title: 'Quote',
  },
  'lockton.localhost': {
    hostname: 'lockton.localhost',
    isDirectConsumer: true,
    logoFilename: 'locktonlogo.png',
    name: 'Lockton',
    title: 'Quote',
  },
};
export interface BrandingStateModel {
  hostname: string;
  name: string;
  title: string;
  logoFilename: string;
  isDirectConsumer: boolean;
}

export const BRANDING_STATE_TOKEN = new StateToken<BrandingStateModel>('branding');

@State<BrandingStateModel>({
  name: BRANDING_STATE_TOKEN,
  defaults: {
    ...mbpiBranding,
  },
})
@Injectable()
export class BrandingState implements NgxsAfterBootstrap {
  @Selector()
  public static state(state: BrandingStateModel) {
    return state;
  }
  constructor(@Inject(WINDOW) private window: Window) {}

  ngxsAfterBootstrap({ dispatch }: StateContext<BrandingStateModel>) {
    const { hostname } = this.window.location;
    dispatch(new SetBranding(hostname.toLowerCase()));
  }

  @Action(SetBranding)
  setBranding({ patchState }: StateContext<BrandingStateModel>, { payload }: SetBranding) {
    const brand = siteBrands[payload];
    patchState({ ...brand } || { ...mbpiBranding, hostname: payload });
  }
}
