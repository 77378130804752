<div style="padding: 10px; width: 330px">
  <div fxLayout="row" style="padding-top: 5px; border-bottom: 1px solid lightgray">
    <h2 style="display: block">Search Criteria</h2>
  </div>
  <mat-divider></mat-divider>
  <div style="padding-top: 15px">
    <form fxLayout="column" fxLayoutAlign="start stretch" [formGroup]="searchCriteriaForm">
      <mbp-agent-autocomplete
        *ngIf="includeAgencyFilter"
        #agentList
        class="mbp-full-width"
        formControlName="agency"
        [value]="selectedAgency"
        style="padding-bottom: 15px"
      ></mbp-agent-autocomplete>
      <mbp-dealer-search label="Dealer" formControlName="dealer" style="padding-bottom: 15px"></mbp-dealer-search>
      <mat-form-field class="mbp-full-width">
        <mat-select placeholder="Select a program" formControlName="program">
          <mat-option value="">All Programs</mat-option>
          <mat-option *ngFor="let program of programs" [value]="program">
            {{ program.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field class="mbp-full-width">
        <input
          matInput
          mbp-mask-date
          class="input"
          placeholder="Select a start date"
          formControlName="startDate"
          required
          [matDatepicker]="startDatePicker"
        />
        <mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
        <mat-datepicker touchUi #startDatePicker></mat-datepicker>
      </mat-form-field>
      <mat-form-field class="mbp-full-width">
        <input
          matInput
          mbp-mask-date
          class="input"
          placeholder="Select an end date"
          formControlName="endDate"
          required
          [matDatepicker]="endDatePicker"
        />
        <mat-datepicker-toggle matSuffix [for]="endDatePicker"></mat-datepicker-toggle>
        <mat-datepicker touchUi #endDatePicker></mat-datepicker>
      </mat-form-field>
      <mat-form-field class="mbp-full-width">
        <mbp-loading-container [isLoading]="isLoadingVehicleYears">
          <mat-select
            placeholder="Select a vehicle year"
            formControlName="vehicleYear"
            (selectionChange)="onVehicleYearChange($event.value)"
          >
            <mat-option *ngFor="let year of vehicleYears$ | async" [value]="year">{{ year }}</mat-option>
          </mat-select>
        </mbp-loading-container>
      </mat-form-field>
      <mat-form-field class="mbp-full-width">
        <mbp-loading-container [isLoading]="isLoadingVehicleMakes">
          <mat-select
            #vehicleMakeSelect
            placeholder="Select a make"
            formControlName="vehicleMake"
            (selectionChange)="onVehicleMakeChange($event.value.makeCode)"
          >
            <mat-option *ngFor="let vehicleMake of vehicleMakes" [value]="vehicleMake">
              {{ vehicleMake.makeName }}
            </mat-option>
          </mat-select>
        </mbp-loading-container>
      </mat-form-field>
      <mat-form-field class="mbp-full-width">
        <mbp-loading-container [isLoading]="isLoadingVehicleModels">
          <mat-select #vehicleModelSelect placeholder="Select a model" formControlName="vehicleModelName">
            <mat-option *ngFor="let vehicleModel of vehicleModels" [value]="vehicleModel.modelName">
              {{ vehicleModel.modelName }}
            </mat-option>
          </mat-select>
        </mbp-loading-container>
      </mat-form-field>
      <button mat-raised-button color="primary" type="button" [disabled]="!searchCriteriaForm.valid || isLoading" (click)="search()">
        <i *ngIf="isLoading" class="fa fa-circle-o-notch fa-spin"></i> {{ isLoading ? 'Searching...' : 'Search' }}
      </button>
    </form>
  </div>
</div>
