export class DailyQuotesCountRequest {
  //#region : Constructor :
  constructor(userName?: string, agentCode?: string, dealerCode?: string, useSefi?: boolean) {
    this.userName = userName;
    this.agentCode = agentCode;
    this.dealerCode = dealerCode;
    this.useSefi = useSefi;
  }
  //#endregion

  //#region : Properties :
  public userName: string;
  public agentCode: string;
  public dealerCode: string;
  public useSefi: boolean;
  //#endregion
}
