export class CustomerContractsRequest {
  //#region : Constructor :
  constructor(userName?: string) {
    this.userName = userName;
  }
  //#endregion

  //#region : Properties :
  public userName: string;
  //#endregion
}
