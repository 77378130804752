import { Directive, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ZipCodeFindPlaces, ZipCodeLookupCache, ZipCodeState } from '@mbp/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Select, Store } from '@ngxs/store';
import { combineLatest, Observable, Subject } from 'rxjs';
import { filter, map } from 'rxjs/operators';

@UntilDestroy()
@Directive({
  selector: '[mbpZipLookup]',
})
export class ZipLookupDirective implements OnInit {
  @Select(ZipCodeState.zipCodeLookup) zipCodeLookup$: Observable<ZipCodeLookupCache>;

  @Input('mbpZipLookup')
  set zipCode(value) {
    this.zipSubject.next(value);
    this.store.dispatch(new ZipCodeFindPlaces(value));
  }

  @Output() zipFound = new EventEmitter<any>();

  zipSubject = new Subject<string>();

  constructor(private store: Store) {}

  ngOnInit(): void {
    combineLatest([this.zipCodeLookup$, this.zipSubject])
      .pipe(
        filter(([zipLookupPlaces, zipCode]) => !!zipLookupPlaces[zipCode]),
        map(([zipLookupPlaces, zipCode]) => zipLookupPlaces[zipCode]),
        untilDestroyed(this),
      )
      .subscribe((lookups) => this.zipFound.emit(lookups));
  }
}
