import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { DBkeys } from '../db-keys';
import { Agency } from '../models/agency.model';
import { DealerMinimal } from '../models/dealer-minimal';
import { LocalStoreManager } from './local-store-manager.service';

@Injectable({
  providedIn: 'root',
})
export class PortalFilterService {
  //#region : Variable Declarations :
  private filterStatus = new Subject<boolean>();

  //#endregion

  //#region : Constructor :
  constructor(private localStorage: LocalStoreManager) {
    this.evaluateFilterStatus();
  }
  //#endregion

  //#region : Events :
  // public OnAgencyChange: EventEmitter<Agency> = new EventEmitter();
  // public OnDealerChange: EventEmitter<DealerMinimal> = new EventEmitter();
  //#endregion

  //#region : Properties :
  public get currentFilteredAgentCode(): string {
    const code = this.currentFilteredAgency != null ? this.currentFilteredAgency.agentCode : null;
    return code;
  }

  public get currentFilteredAgency(): Agency {
    const agency = this.localStorage.getDataObject<Agency>(DBkeys.PORTAL_FILTERED_AGENCY);
    return agency;
  }

  public get currentFilteredDealer(): DealerMinimal {
    const dealer = this.localStorage.getDataObject<DealerMinimal>(DBkeys.PORTAL_FILTERED_DEALER);
    return dealer;
  }

  public get currentFilteredDealerCode(): string {
    const code = this.currentFilteredDealer != null ? this.currentFilteredDealer.dealerCode : null;
    return code;
  }

  public get hasFilter(): boolean {
    return this.currentFilteredAgency != null || this.currentFilteredDealer != null;
  }

  getPortalFilterStatusEvent(): Observable<boolean> {
    return this.filterStatus.asObservable();
  }
  //#endregion

  //#region : Methods :
  public agencyChanged(agency: Agency) {
    this.localStorage.saveSyncedSessionData(agency, DBkeys.PORTAL_FILTERED_AGENCY);
    this.evaluateFilterStatus();
  }

  public clearAgency() {
    this.agencyChanged(null);
  }

  public clearDealer() {
    this.dealerChanged(null);
  }

  public dealerChanged(dealer: DealerMinimal) {
    this.localStorage.saveSyncedSessionData(dealer, DBkeys.PORTAL_FILTERED_DEALER);
    this.evaluateFilterStatus();
  }

  private evaluateFilterStatus() {
    const hasFilter = this.currentFilteredDealer != null || this.currentFilteredAgency != null;

    setTimeout(() => {
      this.filterStatus.next(hasFilter);
    });
  }
  //#endregion
}
