import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  ApiResponse,
  DealerRepresentative,
  DealerRepresentativesRequest,
  Program,
  toCamelCase,
  VehicleCylinder,
  VehicleCylindersRequest,
  VehicleCylindersResponse,
  VehicleDrivingWheel,
  VehicleDrivingWheelsRequest,
  VehicleDrivingWheelsResponse,
  VehicleFuelType,
  VehicleFuelTypesRequest,
  VehicleFuelTypesResponse,
  VehicleMake,
  VehicleMakesRequest,
  VehicleMakesResponse,
  VehicleModel,
  VehicleModelsRequest,
  VehicleModelsResponse,
  VehicleYearsResponse,
} from '@mbp/core';
import { Action, State, StateContext, StateToken } from '@ngxs/store';
import { patch } from '@ngxs/store/operators';
import { getApiResponseData } from 'app/core/rxjs/get-api-response-data';
import { isEmpty } from 'lodash';
import { map } from 'rxjs/operators';
import { createPropertySelectors } from '../ngxs-next';
import {
  FetchDealerRepresentatives,
  FetchPrograms,
  FetchVehicleCylinders,
  FetchVehicleDrivingWheels,
  FetchVehicleFuelTypes,
  FetchVehicleMakes,
  FetchVehicleModels,
  FetchVehicleYears,
} from './lookup.actions';
import {
  DealerRepresentatives,
  LookupStateModel,
  VehicleCylinders,
  VehicleDrivingWheels,
  VehicleFuelTypes,
  VehicleMakes,
  VehicleModels,
} from './lookup.models';

const defaultState: LookupStateModel = {
  vehicleYears: [],
  vehicleMakes: {},
  vehicleModels: {},
  vehicleCylinders: {},
  vehicleDrivingWheels: {},
  vehicleFuelTypes: {},
  dealerRepresentatives: {},
  programs: [],
};

const LOOKUP_STATE_TOKEN = new StateToken<LookupStateModel>('lookup');

@State<LookupStateModel>({
  name: LOOKUP_STATE_TOKEN,
  defaults: { ...defaultState },
})
@Injectable()
export class LookupState {
  public static props = createPropertySelectors(LOOKUP_STATE_TOKEN);
  public static getMakeKey = (year: string) => year;
  public static getModelKey = (year: string, makeCode: string) => `${year}_${makeCode}`;
  public static getCylindersKey = (year: string, makeCode: string, modelName: string) => `${year}_${makeCode}_${modelName}`;
  public static getDrivingWheelsKey = (year: string, makeCode: string, modelName: string, cylinders: string) =>
    `${year}_${makeCode}_${modelName}_${cylinders}`;
  public static getFuelTypeKey = (year: string, makeCode: string, modelName: string, cylinders: string, drivingWheelsCode: string) =>
    `${year}_${makeCode}_${modelName}_${cylinders}_${drivingWheelsCode}`;

  constructor(private http: HttpClient) {}

  @Action(FetchVehicleYears, { cancelUncompleted: true })
  fetchVehicleYears(ctx: StateContext<LookupStateModel>) {
    const state = ctx.getState();
    if (!isEmpty(state.vehicleYears)) {
      return;
    }
    return this.http.get<ApiResponse<VehicleYearsResponse>>(`/api/lookup/vehicleyears`).pipe(
      getApiResponseData(),
      map((years) => {
        return ctx.setState(
          patch<LookupStateModel>({
            vehicleYears: years.vehicleYears.map((year) => year.VehicleYear.toString()),
          }),
        );
      }),
    );
  }

  @Action(FetchVehicleMakes, { cancelUncompleted: true })
  fetchVehicleMakes(ctx: StateContext<LookupStateModel>, act: FetchVehicleMakes) {
    const key = LookupState.getMakeKey(act.payload.year);
    const state = ctx.getState();
    if (!isEmpty(state.vehicleMakes[key])) {
      return;
    }
    const request: VehicleMakesRequest = { vehicleYear: act.payload.year };
    return this.http.put<ApiResponse<VehicleMakesResponse>>(`/api/lookup/vehiclemakes`, request).pipe(
      getApiResponseData(),
      map((res) => {
        return res.vehicleMakes.map(toCamelCase) as VehicleMake[];
      }),
      map((vehicleMakes) => {
        return ctx.setState(
          patch<LookupStateModel>({
            vehicleMakes: patch<VehicleMakes>({
              [key]: vehicleMakes,
            }),
          }),
        );
      }),
    );
  }

  @Action(FetchVehicleModels, { cancelUncompleted: true })
  fetchVehicleModels(ctx: StateContext<LookupStateModel>, act: FetchVehicleModels) {
    const key = LookupState.getModelKey(act.payload.year, act.payload.makeCode);
    const state = ctx.getState();
    if (!isEmpty(state.vehicleModels[key])) {
      return;
    }
    const request: VehicleModelsRequest = { makeCode: act.payload.makeCode, vehicleYear: act.payload.year };
    return this.http.put<ApiResponse<VehicleModelsResponse>>(`/api/lookup/vehiclemodels`, request).pipe(
      getApiResponseData(),
      map((res) => {
        return res.vehicleModels.map(toCamelCase) as VehicleModel[];
      }),
      map((vehicleModel) => {
        return ctx.setState(
          patch<LookupStateModel>({
            vehicleModels: patch<VehicleModels>({
              [key]: vehicleModel,
            }),
          }),
        );
      }),
    );
  }

  @Action(FetchVehicleCylinders, { cancelUncompleted: true })
  fetchVehicleCylinders(ctx: StateContext<LookupStateModel>, act: FetchVehicleCylinders) {
    const key = LookupState.getCylindersKey(act.payload.year, act.payload.makeCode, act.payload.modelName);
    const state = ctx.getState();
    if (!isEmpty(state.vehicleCylinders[key])) {
      return;
    }
    const request: VehicleCylindersRequest = {
      makeCode: act.payload.makeCode,
      vehicleYear: act.payload.year,
      modelName: act.payload.modelName,
    };
    return this.http.put<ApiResponse<VehicleCylindersResponse>>(`/api/lookup/vehiclecylinders`, request).pipe(
      getApiResponseData(),
      map((res) => {
        return res.vehicleCylinders.map(toCamelCase) as VehicleCylinder[];
      }),
      map((cylinders) => {
        return ctx.setState(
          patch<LookupStateModel>({
            vehicleCylinders: patch<VehicleCylinders>({ [key]: cylinders }),
          }),
        );
      }),
    );
  }

  @Action(FetchVehicleDrivingWheels, { cancelUncompleted: true })
  fetchVehicleDrivingWheels(ctx: StateContext<LookupStateModel>, act: FetchVehicleDrivingWheels) {
    const key = LookupState.getDrivingWheelsKey(act.payload.year, act.payload.makeCode, act.payload.modelName, act.payload.cylinders);

    const state = ctx.getState();
    if (!isEmpty(state.vehicleDrivingWheels[key])) {
      return;
    }
    const request: VehicleDrivingWheelsRequest = {
      makeCode: act.payload.makeCode,
      vehicleYear: act.payload.year,
      modelName: act.payload.modelName,
      cylinders: act.payload.cylinders,
    };

    return this.http.put<ApiResponse<VehicleDrivingWheelsResponse>>(`/api/lookup/vehicledrivingwheels`, request).pipe(
      getApiResponseData(),
      map((res) => {
        return res.vehicleDrivingWheels.map(toCamelCase) as VehicleDrivingWheel[];
      }),
      map((res) => {
        return ctx.setState(
          patch<LookupStateModel>({
            vehicleDrivingWheels: patch<VehicleDrivingWheels>({ [key]: res }),
          }),
        );
      }),
    );
  }

  @Action(FetchVehicleFuelTypes, { cancelUncompleted: true })
  fetchVehicleFuelTypes(ctx: StateContext<LookupStateModel>, act: FetchVehicleFuelTypes) {
    const key = LookupState.getFuelTypeKey(
      act.payload.year,
      act.payload.makeCode,
      act.payload.modelName,
      act.payload.cylinders,
      act.payload.drivingWheelsCode,
    );

    const state = ctx.getState();
    if (!isEmpty(state.vehicleFuelTypes[key])) {
      return;
    }

    const request: VehicleFuelTypesRequest = {
      cylinders: act.payload.cylinders,
      makeCode: act.payload.makeCode,
      modelName: act.payload.modelName,
      vehicleYear: act.payload.year,
      drivingWheelsCode: act.payload.drivingWheelsCode,
    };

    return this.http.put<ApiResponse<VehicleFuelTypesResponse>>(`/api/lookup/vehiclefueltypes`, request).pipe(
      getApiResponseData(),
      map((res) => {
        return res.vehicleFuelTypes.map(toCamelCase) as VehicleFuelType[];
      }),
      map((res) => {
        return ctx.setState(
          patch<LookupStateModel>({
            vehicleFuelTypes: patch<VehicleFuelTypes>({ [key]: res }),
          }),
        );
      }),
    );
  }

  @Action(FetchDealerRepresentatives)
  fetchDealerRepresentatives(ctx: StateContext<LookupStateModel>, act: FetchDealerRepresentatives) {
    const key = act.payload.dealerCode;

    const state = ctx.getState();
    if (!isEmpty(state.dealerRepresentatives[key])) {
      return;
    }

    const request: DealerRepresentativesRequest = {
      dealerCode: act.payload.dealerCode,
    };

    return this.http.put<ApiResponse<DealerRepresentative[]>>(`/api/lookup/dealerrepresentatives`, request).pipe(
      getApiResponseData(),
      map((res) => {
        return ctx.setState(
          patch<LookupStateModel>({
            dealerRepresentatives: patch<DealerRepresentatives>({ [key]: res }),
          }),
        );
      }),
    );
  }
  @Action(FetchPrograms)
  fetchPrograms(ctx: StateContext<LookupStateModel>) {
    const state = ctx.getState();
    if (!isEmpty(state.programs)) {
      return;
    }
    return this.http.put<ApiResponse<Program[]>>(`/api/lookup/programs`, {}).pipe(
      getApiResponseData(),
      map((programs) => {
        return ctx.setState(
          patch<LookupStateModel>({
            programs,
          }),
        );
      }),
    );
  }
}
