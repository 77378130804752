import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  AgenciesRequest,
  Agency,
  AgencyRequest,
  ApiResponse,
  Dealer,
  DealerRequest,
  Lender,
  LendersRequest,
  MBPFinancePlan,
  Program,
  ProgramsRequest,
  StatesRequest,
  State as UsState,
  VehicleCylinder,
  VehicleCylindersRequest,
  VehicleDrivingWheel,
  VehicleDrivingWheelsRequest,
  VehicleFuelType,
  VehicleFuelTypesRequest,
  VehicleInformation,
  VehicleMake,
  VehicleMakesRequest,
  VehicleModel,
  VehicleModelsRequest,
  ZipLookupResponse,
} from '@mbp/core';
import { Action, State, StateContext, StateToken } from '@ngxs/store';
import { patch } from '@ngxs/store/operators';
import { catchError, map, tap } from 'rxjs/operators';
import { ForteRequest } from '../models/forte-request.model';
import { Forte } from '../models/forte.model';
import { getApiResponseData } from '../rxjs/get-api-response-data';
import { createPropertySelectors } from '../state/ngxs-next';
import { LookupActions as Acts } from './lookup.service.actions';

interface LookupServiceStateModel {
  mbpFinancePlans: MBPFinancePlan[];
  dealerPrograms: Program[];
}

const initialState: LookupServiceStateModel = {
  mbpFinancePlans: null,
  dealerPrograms: null,
};

const LOOKUP_SERVICE_STATE_TOKEN = new StateToken<LookupServiceStateModel>('lookupService');

@State<any>({
  name: LOOKUP_SERVICE_STATE_TOKEN,
  defaults: initialState,
})
@Injectable({
  providedIn: 'root',
})
export class LookupService {
  public static props = createPropertySelectors(LOOKUP_SERVICE_STATE_TOKEN);

  constructor(private http: HttpClient) {}

  getAgencies(request: AgenciesRequest) {
    return this.http.put<ApiResponse<Agency[]>>(`/api/lookup/agencies`, request).pipe(getApiResponseData());
  }

  // Might not be used
  getAgency(request: AgencyRequest) {
    return this.http.put<ApiResponse<Agency>>(`/api/lookup/agency`, request).pipe(getApiResponseData());
  }

  getDealer(request: DealerRequest) {
    return this.http.put<ApiResponse<Dealer>>(`/api/lookup/dealer`, request).pipe(getApiResponseData());
  }

  getLenders(request: LendersRequest) {
    return this.http
      .get<ApiResponse<Lender[]>>(`/api/lenders`, {
        params: { dealerCode: request.dealerCode },
      })
      .pipe(getApiResponseData());
  }

  createLender(request: Lender) {
    return this.http.post<ApiResponse<number>>(`/api/lenders`, request).pipe(getApiResponseData());
  }

  updateLender(request: Lender) {
    return this.http.put<ApiResponse<number>>(`/api/lenders/${request.dealerLenderID}`, request).pipe(getApiResponseData());
  }

  deleteLender(dealerLenderId: number) {
    return this.http.delete<ApiResponse<number>>(`/api/lenders/${dealerLenderId}`).pipe(getApiResponseData());
  }

  @Action(Acts.FetchFinancePlans)
  fetchMbpFinancePlans(ctx: StateContext<LookupServiceStateModel>, act: Acts.FetchFinancePlans) {
    return this.http.put<ApiResponse<MBPFinancePlan[]>>(`/api/lookup/mbpfinanceplans`, act.payload).pipe(
      getApiResponseData(),
      tap((response) => ctx.setState(patch<LookupServiceStateModel>({ mbpFinancePlans: response }))),
      map((response) => {
        return ctx.dispatch(new Acts.FetchFinancePlansSuccess(response));
      }),
      catchError((error) => ctx.dispatch(new Acts.FetchFinancePlansError(error))),
    );
  }

  @Action(Acts.FetchProgramsForDealer)
  fetchProgramsForDealer(ctx: StateContext<LookupServiceStateModel>, act: Acts.FetchProgramsForDealer) {
    return this.http.put<ApiResponse<Program[]>>(`/api/lookup/programs`, act.payload).pipe(
      getApiResponseData(),
      tap((response) => ctx.setState(patch<LookupServiceStateModel>({ dealerPrograms: response }))),
      map((response) => {
        return ctx.dispatch(new Acts.FetchProgramsForDealerSuccess(response));
      }),
      catchError((error) => ctx.dispatch(new Acts.FetchProgramsForDealerError(error))),
    );
  }

  getFortePartInfo(request: ForteRequest) {
    return this.http.post<ApiResponse<Forte>>(`/api/lookup/fortePart`, request).pipe(getApiResponseData());
  }

  // Can probably delete as this is called in lookup.state
  getPrograms(request: ProgramsRequest) {
    return this.http.put<ApiResponse<Program[]>>(`/api/lookup/programs`, request).pipe(getApiResponseData());
  }

  // Not sure if this is used anywhere
  getStates(request: StatesRequest) {
    return this.http.put<ApiResponse<UsState[]>>(`/api/lookup/states`, request).pipe(getApiResponseData());
  }

  /**
   * @deprecated please use actions
   */
  getVehicleMakes(request: VehicleMakesRequest) {
    return this.http.put<ApiResponse<VehicleMake[]>>(`/api/lookup/vehiclemakes`, request).pipe(getApiResponseData());
  }

  @Action(Acts.FetchVehicleMakes)
  private fetchVehicleMakes(ctx: StateContext<LookupService>, act: Acts.FetchVehicleMakes) {
    return this.http.put<ApiResponse<VehicleMake[]>>(`/api/lookup/vehiclemakes`, act.payload).pipe(
      getApiResponseData(),
      map((response) => ctx.dispatch(new Acts.FetchVehicleMakesSuccess(response))),
      catchError((error) => ctx.dispatch(new Acts.FetchVehicleMakesError(error))),
    );
  }

  /**
   * @deprecated please use actions
   */
  getVehicleModels(request: VehicleModelsRequest) {
    return this.http.put<ApiResponse<VehicleModel[]>>(`/api/lookup/vehiclemodels`, request).pipe(getApiResponseData());
  }

  @Action(Acts.FetchVehicleModels)
  private fetchVehicleModels(ctx: StateContext<LookupService>, act: Acts.FetchVehicleModels) {
    return this.http.put<ApiResponse<VehicleModel[]>>(`/api/lookup/vehiclemodels`, act.payload).pipe(
      getApiResponseData(),
      map((response) => ctx.dispatch(new Acts.FetchVehicleModelsSuccess(response))),
      catchError((error) => ctx.dispatch(new Acts.FetchVehicleModelsError(error))),
    );
  }

  getVehicleCylinders(request: VehicleCylindersRequest) {
    return this.http.put<ApiResponse<VehicleCylinder[]>>(`/api/lookup/vehiclecylinders`, request).pipe(getApiResponseData());
  }

  getVehicleDrivingWheels(request: VehicleDrivingWheelsRequest) {
    return this.http.put<ApiResponse<VehicleDrivingWheel[]>>(`/api/lookup/vehicledrivingwheels`, request).pipe(getApiResponseData());
  }

  getVehicleFuelTypes(request: VehicleFuelTypesRequest) {
    return this.http.put<ApiResponse<VehicleFuelType[]>>(`/api/lookup/vehiclefueltypes`, request).pipe(getApiResponseData());
  }

  @Action(Acts.FetchVehicleInformation)
  getVehicleInformation(ctx: StateContext<LookupService>, act: Acts.FetchVehicleInformation) {
    return this.http.put<ApiResponse<VehicleInformation>>(`/api/lookup/vehicleinformation`, act.payload).pipe(
      getApiResponseData(),
      map((response) =>
        ctx.dispatch(response != null ? new Acts.FetchVehicleInformationSuccess(response) : new Acts.FetchVehicleInformationError()),
      ),
      catchError((error) => ctx.dispatch(new Acts.FetchVehicleInformationError(error))),
    );
  }

  getZipInformation(zipCode: string) {
    return this.http
      .get<ApiResponse<ZipLookupResponse>>(`/api/lookup/zipCodePlaces`, {
        params: { q: zipCode },
      })
      .pipe(getApiResponseData());
  }
}
