import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import {
  ApiResponse,
  FileDownloadResponse,
  GenerateRaterDealerCostReportRequest,
  GenerateRaterMenuPdfRequest,
  QuoteResponse,
  RaterGetPreviousQuoteRequest,
  RaterHistory,
  RaterHistoryRequest,
  RaterMenu,
  RaterMenuRequest,
  RaterProgramSurcharge,
  RaterProgramSurchargesRequest,
  RaterPurchaseRequest,
  RaterPurchaseResponse,
  RaterQuoteRequest,
  RaterSaveMenuFeesRequest,
  RaterSaveMenuGroupsRequest,
  RaterSaveMenuProductsRequest,
  RaterSaveMenuSettingRequest,
  RegenerateContractRequest,
  VoidContractRequest,
} from '@mbp/core';
import { Action, State, StateContext } from '@ngxs/store';
import { catchError, map } from 'rxjs/operators';
import { PORTAL_API_BASE_URL } from '../core.module';
import { getApiResponseData } from '../rxjs/get-api-response-data';

export class RaterApiFetchRates {
  public static readonly type = '[Rater API] Fetch Rates';
  constructor(public payload: RaterQuoteRequest) {}
}

export class FetchRatesSuccess {
  public static readonly type = '[Rater API] Fetch Rates Success';
  constructor(public payload: QuoteResponse) {}
}

export class RaterApiFetchRatesError {
  public static readonly type = '[Rater API] Fetch Rates Error';
  constructor(public payload: QuoteResponse) {}
}

export class RaterApiFetchProgramSurcharges {
  public static readonly type = '[Rater API] Fetch Program Surcharges';
  constructor(public payload: RaterProgramSurchargesRequest) {}
}

export class RaterApiFetchProgramSurchargesSuccess {
  public static readonly type = '[Rater API] Fetch Program Surcharges Success';
  constructor(public payload: RaterProgramSurcharge[]) {}
}

export class RaterApiFetchProgramSurchargesError {
  public static readonly type = '[Rater API] Fetch Program Surcharges Error';
  constructor(public payload: Error | any) {}
}

@State<any>({
  name: 'raterService',
})
@Injectable({
  providedIn: 'root',
})
export class RaterService {
  constructor(private http: HttpClient, @Inject(PORTAL_API_BASE_URL) private baseUrl: string) {}

  generateDealerCostPdf(request: GenerateRaterDealerCostReportRequest) {
    return this.http
      .put<ApiResponse<FileDownloadResponse>>(`${this.baseUrl}/api/pdf/raterdealercostreport`, request)
      .pipe(getApiResponseData());
  }

  generateMenuPdf(request: GenerateRaterMenuPdfRequest) {
    return this.http.put<ApiResponse<FileDownloadResponse>>(`${this.baseUrl}/api/pdf/ratermenu`, request).pipe(getApiResponseData());
  }

  getMenu(request: RaterMenuRequest) {
    return this.http.put<ApiResponse<RaterMenu>>(`/api/rater/getmenu`, request).pipe(getApiResponseData());
  }

  getPreviousQuote(request: RaterGetPreviousQuoteRequest) {
    return this.http.put<ApiResponse<QuoteResponse>>(`/api/rater/getpreviousquote`, request).pipe(getApiResponseData());
  }

  getRaterHistory(request: RaterHistoryRequest) {
    return this.http.put<ApiResponse<RaterHistory[]>>(`/api/rater/history`, request).pipe(getApiResponseData());
  }

  getRegenerateContract(request: RegenerateContractRequest) {
    return this.http.put<ApiResponse<FileDownloadResponse>>(`/api/rater/regeneratecontract`, request).pipe(getApiResponseData());
  }

  purchase(request: RaterPurchaseRequest) {
    return this.http.put<ApiResponse<RaterPurchaseResponse>>(`/api/rater/purchase`, request).pipe(getApiResponseData());
  }

  @Action(RaterApiFetchProgramSurcharges)
  getProgramSurcharges(ctx: StateContext<RaterService>, act: RaterApiFetchProgramSurcharges) {
    return this.http.put<ApiResponse<RaterProgramSurcharge[]>>(`/api/rater/getprogramsurcharges`, act.payload).pipe(
      getApiResponseData(),
      map((response) => {
        return ctx.dispatch(new RaterApiFetchProgramSurchargesSuccess(response));
      }),
      catchError((error) => ctx.dispatch(new RaterApiFetchProgramSurchargesError(error))),
    );
  }

  @Action(RaterApiFetchRates)
  putQuote(ctx: StateContext<RaterService>, act: RaterApiFetchRates) {
    return this.http.put<ApiResponse<QuoteResponse>>(`/api/rater/quote`, act.payload).pipe(
      getApiResponseData(),
      map((response) => {
        if (response.errors) return ctx.dispatch(new RaterApiFetchRatesError(response));

        return ctx.dispatch(new FetchRatesSuccess(response));
      }),
      catchError((error) => ctx.dispatch(new RaterApiFetchRatesError(error))),
    );
  }

  saveMenuSetting(request: RaterSaveMenuSettingRequest) {
    return this.http.put<ApiResponse<boolean>>(`/api/rater/savemenusetting`, request).pipe(getApiResponseData());
  }

  saveMenuFees(request: RaterSaveMenuFeesRequest) {
    return this.http.put<ApiResponse<boolean>>(`/api/rater/savemenufees`, request).pipe(getApiResponseData());
  }

  saveMenuGroups(request: RaterSaveMenuGroupsRequest) {
    return this.http.put<ApiResponse<boolean>>(`/api/rater/savemenugroups`, request).pipe(getApiResponseData());
  }

  saveMenuProducts(request: RaterSaveMenuProductsRequest) {
    return this.http.put<ApiResponse<boolean>>(`/api/rater/savemenuproducts`, request).pipe(getApiResponseData());
  }

  voidContract(request: VoidContractRequest) {
    return this.http.put<ApiResponse<boolean>>(`/api/rater/voidcontract`, request).pipe(getApiResponseData());
  }
}
