import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import {
  AlertService,
  AppStateModel,
  ContractMinimal,
  RaterService,
  SettingsState,
  ToastType,
  VoidContractRequest,
  VoidReason,
  VoidReasonType,
} from '@mbp/core';
import { Store } from '@ngxs/store';

@Component({
  selector: 'mbp-void-contract-form',
  templateUrl: './void-contract-form.component.html',
  styleUrls: ['./void-contract-form.component.scss'],
})
export class VoidContractComponent implements OnInit {
  //#region : Variable Declarations :

  formErrors = {
    voidReasonType: null,
  };
  validationMessages = {
    voidReasonType: {
      required: 'Please choose a void reason',
    },
  };

  isLoading = false;
  dialogOpen = false;
  voidForm: FormGroup;
  actionDataItem: ContractMinimal;
  voidContractRequest: VoidContractRequest = new VoidContractRequest();
  voidReasons: VoidReason[] = [];
  //#endregion

  //#region : Constructor :
  constructor(
    private formBuilder: FormBuilder,
    private store: Store,
    private alertService: AlertService,
    private raterService: RaterService,
    private settings: SettingsState,
  ) {}
  //#endregion

  //#region : Properties :
  @Input() public set model(dataItem: ContractMinimal) {
    this.actionDataItem = dataItem;
    if (this.voidForm != null) {
      this.voidForm.patchValue({ voidReasonType: null });
    }
    this.dialogOpen = dataItem !== undefined;
  }

  @Output() cancel: EventEmitter<any> = new EventEmitter();
  @Output() contractVoided: EventEmitter<any> = new EventEmitter();
  //#endregion

  //#region : Implementation Methods :
  ngOnInit() {
    this.buildForm();

    setTimeout(() => {
      this.loadLookupData();
    }, 500);
  }
  //#endregion

  //#region : Methods :
  buildForm() {
    this.voidContractRequest.programCode = '';
    this.voidContractRequest.dealerCode = '';
    this.voidContractRequest.voidReasonType = null;
    this.voidForm = this.formBuilder.group(this.voidContractRequest);
    // this.voidForm.valueChanges.subscribe(data => this.onFormValueChanged(data));
  }

  loadLookupData() {
    this.isLoading = true;

    this.voidReasons.push(new VoidReason(VoidReasonType.Duplicate, 'Duplicate'));
    this.voidReasons.push(new VoidReason(VoidReasonType.Cancellation, 'Cancellation'));
    this.voidReasons.push(new VoidReason(VoidReasonType.CustomerBackOut, 'Customer Back Out'));
    this.voidReasons.push(new VoidReason(VoidReasonType.UnableToFund, 'Unable to Fund'));

    this.isLoading = false;
  }

  public onCancel(e): void {
    e.preventDefault();
    this.closeForm();
  }

  public onFormValueChanged(data?: any) {
    if (!this.voidForm) {
      return;
    }
    const form = this.voidForm;
    for (const field in this.formErrors) {
      if (Object.prototype.hasOwnProperty.call(this.formErrors, field)) {
        this.formErrors[field] = '';
        const control = form.get(field);
        if (control && control.dirty && !control.valid) {
          const messages = this.validationMessages[field];
          for (const key in control.errors) {
            if (Object.prototype.hasOwnProperty.call(control.errors, key)) {
              this.formErrors[field] += messages[key] + ' ';
            }
          }
        }
      }
    }
  }

  public onVoid(e): void {
    e.preventDefault();

    this.isLoading = true;
    this.alertService.startLoadingMessage('', 'Voiding contract...');

    // Let's void this contract
    this.voidContractRequest = this.voidForm.value;
    this.voidContractRequest.userName = this.store.selectSnapshot((state: AppStateModel) => state.auth?.user?.username);
    this.voidContractRequest.contractNumber = this.actionDataItem.contractNumber.toString();
    this.voidContractRequest.dealerCode = this.actionDataItem.dealerCode;
    this.voidContractRequest.programCode = this.actionDataItem.programCode;
    this.voidContractRequest.pointOfSaleOrderIdentifier = this.actionDataItem.pointOfSaleOrderIdentifier;
    this.voidContractRequest.pointOfSalePaymentAmount = this.actionDataItem.pointOfSalePaymentAmount;
    this.voidContractRequest.pointOfSaleTransactionNumber = this.actionDataItem.pointOfSaleTransactionNumber;
    this.voidContractRequest.useSefi = false;
    this.raterService.voidContract(this.voidContractRequest).subscribe(
      (data) => this.onVoidContractSuccessful(data),
      (error) => this.onVoidContractFailed(error),
    );
  }

  onVoidContractSuccessful(success: boolean) {
    this.isLoading = false;
    this.alertService.stopLoadingMessage();
    if (success) {
      this.contractVoided.emit();
    } else {
      this.alertService.showMessage('Error', 'There was a problem voiding the contract!', ToastType.error);
    }
  }

  onVoidContractFailed(error: any) {
    this.isLoading = false;
    this.alertService.stopLoadingMessage();
    this.alertService.showMessage('Error', 'There was a problem voiding the contract!', ToastType.error);
  }

  closeForm(): void {
    this.cancel.emit();
  }
  //#endregion
}
