import { ClaimStatusType } from './enums';

export class ClaimStatus {
  //#region : Constructor :
  constructor(claimStatusType?: ClaimStatusType, name?: string) {
    this.claimStatusType = claimStatusType;
    this.name = name;
  }
  //#endregion

  //#region : Properties :
  public claimStatusType: ClaimStatusType;
  public name: string;
  //#endregion
}
