import { DealerMinimal } from './dealer-minimal';
import { AccountPaymentMethodType } from './enums';

export class RemittanceSummaryRequest {
  //#region : Constructor :
  constructor() {}
  //#endregion

  //#region : Properties :
  public dealerCode?: string;
  public paymentMethodType?: AccountPaymentMethodType;
  public startDate?: Date;
  public endDate?: Date;
  public userName: string;
  public dealer?: DealerMinimal = null;
  //#endregion
}
