<div class="number-card">
  <div fxLayout fxLayoutAlign="space-between center" (click)="cardClicked()" [ngClass]="{ 'clickable-card': actionEndpoint != null }">
    <mat-icon class="icon" [style.color]="color" fontSet="material-icons-outlined">{{ icon }}</mat-icon>
    <div class="spacer"></div>
    <div fxLayout="column" fxLayoutAlign="end end">
      <span class="mat-subheading-2 title">{{ title }}</span>
      <div fxLayout fxLayoutAlign="center center" class="value-container">
        <span [hidden]="loading" class="value">{{ value$ | async | withCommas }}</span>
        <mat-spinner *ngIf="loading" diameter="24"></mat-spinner>
      </div>
    </div>
  </div>
  <div>
    <div class="hr" [style.borderTopColor]="color"></div>
    <div fxLayout fxLayoutAlign="start center" class="footer">
      <button mat-icon-button class="refresh" (click)="load$.next('')">
        <mat-icon class="refresh-icon">loop</mat-icon>
      </button>
      <ng-container *ngIf="loading; else elseTemplate">
        <span class="refresh-time">Updating...</span>
      </ng-container>
      <ng-template #elseTemplate>
        <span class="refresh-time">Updated {{ updateTime$ | async }}</span>
      </ng-template>
    </div>
  </div>
</div>
