import { Agency } from './agency.model';
import { DealerMinimal } from './dealer-minimal';
import { Program } from './program.model';
import { VehicleMake } from './vehicle-make.model';

export class QuoteRequestReportRequest {
  //#region : Constructor :
  constructor() {}
  //#endregion

  //#region : Properties :
  public userName: string;
  public programCode: string;
  public agentCode: string;
  public dealerCode: string;
  public startDate: Date;
  public endDate: Date;
  public byVehicleMakeModel: boolean;
  public vehicleYear: number;
  public vehicleMakeCode: string;
  public vehicleModelName: string;
  public accountProfileID: number;
  public comingThroughDealerServices: boolean;
  public useSefi: boolean;
  public dealer: DealerMinimal = null;
  public agency: Agency = null;
  public program: Program = null;
  public vehicleMake: VehicleMake = null;
  //#endregion
}
