import { NgModule } from '@angular/core';
import { SharedModule } from '@mbp/shared';
import { NgxsModule } from '@ngxs/store';
import { AppRoutingModule } from 'app/app-routing.module';
import { RootComponent } from 'app/root/root.component';
import { CoreModule } from './core/core.module';
import { IeDetectorComponent } from './root/ie-detector/ie-detector.component';
import { NotificationsWindowComponent } from './root/notifications-window/notifications-window.component';
import {
  AgencyGrossCommissionSearchCriteriaComponent,
  DealerClaimsSearchCriteriaComponent,
  DealerContractsSearchCriteriaComponent,
  InternalMessagesSearchCriteriaComponent,
  NewDealerDisbursementSearchCriteriaComponent,
  PendingRemittanceSearchCriteriaComponent,
  QuoteRequestsSearchCriteriaComponent,
  RaterHistorySearchCriteriaComponent,
  RemittanceHistorySearchCriteriaComponent,
  RemittanceSummarySearchCriteriaComponent,
  SalesSummarySearchCriteriaComponent,
  ScsClaimInfoSearchCriteriaComponent,
} from './root/sidebar';
import { SidebarComponent } from './root/sidebar/sidebar.component';
import { SidemenuItemComponent } from './root/sidemenu-item/sidemenu-item.component';
import { SidemenuComponent } from './root/sidemenu/sidemenu.component';
import { RootState } from './root/state/root.state';
import { ToolbarNotificationsComponent } from './root/toolbar-notifications/toolbar-notifications.component';
import { ToolbarComponent } from './root/toolbar/toolbar.component';
import { UserMenuComponent } from './root/user-menu/user-menu.component';

@NgModule({
  declarations: [
    AgencyGrossCommissionSearchCriteriaComponent,
    DealerClaimsSearchCriteriaComponent,
    DealerContractsSearchCriteriaComponent,
    IeDetectorComponent,
    InternalMessagesSearchCriteriaComponent,
    NewDealerDisbursementSearchCriteriaComponent,
    NotificationsWindowComponent,
    PendingRemittanceSearchCriteriaComponent,
    QuoteRequestsSearchCriteriaComponent,
    RaterHistorySearchCriteriaComponent,
    RemittanceHistorySearchCriteriaComponent,
    RemittanceSummarySearchCriteriaComponent,
    RootComponent,
    SalesSummarySearchCriteriaComponent,
    ScsClaimInfoSearchCriteriaComponent,
    SidebarComponent,
    SidemenuComponent,
    SidemenuItemComponent,
    ToolbarComponent,
    ToolbarNotificationsComponent,
    UserMenuComponent,
  ],
  imports: [AppRoutingModule, CoreModule, SharedModule, NgxsModule.forFeature([RootState])],
  bootstrap: [RootComponent],
})
export class AppModule {}
