import { Setting } from '../models/setting.model';

export class InitSettings {
  public static readonly type = '[Init Settings] Initializing Settings';
}

export class UpdateSetting {
  public static readonly type = '[Settings Page] Setting Changed';
  constructor(public payload: Setting) {}
}
