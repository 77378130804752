import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  ApiResponse,
  DocumentRepositoryDownloadFileRequest,
  DocumentRepositoryFile,
  DocumentRepositoryFilesRequest,
  DocumentRepositoryFolder,
  DocumentRepositoryFoldersRequest,
  FileDownloadResponse,
  getApiResponseData,
} from '@mbp/core';

@Injectable({
  providedIn: 'root',
})
export class DocumentRepositoryService {
  constructor(private http: HttpClient) {}

  getFile(request: DocumentRepositoryDownloadFileRequest) {
    return this.http
      .get<FileDownloadResponse | ApiResponse<FileDownloadResponse>>(`/api/documents/file`, {
        params: toParams(request),
      })
      .pipe(getApiResponseData());
  }

  getFiles(request: DocumentRepositoryFilesRequest) {
    return this.http
      .post<DocumentRepositoryFile[] | ApiResponse<DocumentRepositoryFile[]>>(`/api/documents/files`, request)
      .pipe(getApiResponseData());
  }

  getFolders(request: DocumentRepositoryFoldersRequest) {
    return this.http
      .post<DocumentRepositoryFolder[] | ApiResponse<DocumentRepositoryFolder[]>>(`/api/documents/folders`, request)
      .pipe(getApiResponseData());
  }
}

function toParams(request: any) {
  const params = {};
  for (const [key, value] of Object.entries(request)) {
    if (value) params[key] = value.toString();
  }
  return params;
}
