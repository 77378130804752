import { Component, OnInit } from '@angular/core';
import { Agency, DealerMinimal, DealerSelectedEventItem, PortalFilterService } from '@mbp/core';

@Component({
  selector: 'mbp-portal-toolbar-filter',
  templateUrl: './toolbar-filter.component.html',
  styleUrls: ['./toolbar-filter.component.scss'],
})
export class ToolbarFilterComponent implements OnInit {
  //#region : Variable Declarations :
  cssPrefix = 'toolbar-notification';
  isOpen = false;
  hasActiveFilter = false;
  hasDealerFilter = false;
  hasAgencyFilter = false;
  showDealerFilter = false;
  showAgencyFilter = false;

  cleanSearchText = '';
  //#endregion

  //#region : Constructor :
  constructor(private portalFilterService: PortalFilterService) {}
  //#endregion

  //#region : Properties :
  get currentFilteredAgency(): Agency {
    return this.portalFilterService.currentFilteredAgency;
  }

  get currentFilteredDealer(): DealerMinimal {
    return this.portalFilterService.currentFilteredDealer;
  }
  //#endregion

  //#region : Implementation Methods :
  ngOnInit() {
    this.initializeFlags();
  }
  //#endregion

  //#region : Methods :
  cancelAgencyFilter() {
    this.showAgencyFilter = false;
  }

  cancelDealerFilter() {
    this.showDealerFilter = false;
  }

  initializeFlags() {
    this.hasActiveFilter = this.portalFilterService.currentFilteredAgency != null || this.portalFilterService.currentFilteredDealer != null;
    this.hasDealerFilter = this.portalFilterService.currentFilteredDealer != null;
    this.hasAgencyFilter = this.portalFilterService.currentFilteredAgency != null;
  }

  // onAgencySelected(selectedEvent: DealerSelectedEventItem) {
  //    this.showAgencyFilter = false;
  //    this.portalFilterService.agencyChanged(selectedEvent.dataItem);
  // }

  onDealerSelected(selectedEvent: DealerSelectedEventItem) {
    this.showDealerFilter = false;
    this.portalFilterService.dealerChanged(selectedEvent.dataItem);
    this.initializeFlags();
    this.isOpen = false;
  }

  removeDealerFilter() {
    this.portalFilterService.clearDealer();
    this.initializeFlags();
    this.isOpen = false;
  }

  viewAgencyLookup() {
    // this.isOpen = false;
    this.showAgencyFilter = true;
  }

  viewDealerLookup() {
    // this.isOpen = false;
    this.showDealerFilter = true;
  }
  //#endregion
}
