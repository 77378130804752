import { Customer, VehicleInformation } from '..';
import { Dealer } from './dealer.model';
import { ContractStatusType, CoverageDisplayType, ProgramType } from './enums';
import { Lender } from './lender.model';
import { Note } from './note.model';

export class Contract {
  //#region : Constructor :
  constructor() {}
  //#endregion

  //#region : Properties :
  public accountPayment: string;
  public activeDate: Date;
  public cancelAsOfDate: Date;
  public cancelProcessFromDate: Date;
  public cancelTransactionDate: Date;
  public cancelFeeAmount: number;
  public cancelReason: string;
  public cancelRefundPercent: number;
  public companySortOrder: number;
  public contractFormName: any;
  public contractNumber: string;
  public contractPurchaseDate: Date;
  public contractStatusType: ContractStatusType;
  public contractStatusTypeName: string;
  public contractSuffix: string;
  public coverageDisplay: string; // used for exporting (only set after the grid is populated)
  public coverageDisplayType: CoverageDisplayType;
  public coverageMiles: number;
  public coverageMonths: number;
  public coverageMonthsMiles: string;
  public coverageOptionTypeCode: string;
  public coverageTypeCode: string;
  public createdBy: any;
  public createUser: string;
  public customer: Customer;
  public customerPrice: number;
  public customerRefundAmount: number;
  public dealer: Dealer;
  public dealerCode: string;
  public dealerCost: number;
  public dealerName: string;
  public dealerRefundAmount: number;
  public dealerRepresentativeName: string;
  public deductibleAmount: number;
  public deniedDate: Date;
  public deviceIMEINumber: string;
  public deviceSerialNumber: string;
  public discountAmount: number;
  public expirationDate: Date;
  public expirationMiles: number;
  public expirationMilesDisplay: string; // used for exporting (only set after the grid is populated)
  public exportStatus: string;
  public hasDisappearingDeductible: boolean;
  public includesCosmeticCoverageOption: boolean;
  public includesDentCoverageOption: boolean;
  public includesKeyCoverageOption: boolean;
  public includesSafeLinkTheftCoverageOption: boolean;
  public includesTireCoverageOption: boolean;
  public includesWindshieldCoverageOption: boolean;
  public isMBPIFinanced: boolean;
  public isTestContract: boolean;
  public isUserAllowedAccess: boolean;
  public lastClaimMileage: number;
  public lastTransferDate: Date;
  public lastTransferMileage: number;
  public lender: Lender;
  public mbpiRefundAmount: number;
  public notes: Note[];
  public optionalCoverages: string;
  public planCode: string;
  public planDescription: string;
  public planIdentifier: string;
  public pointOfSaleOrderIdentifier: string;
  public pointOfSalePaymentAmount: string;
  public pointOfSaleTransactionNumber: string;
  public postedDate: Date;
  public programCode: string;
  public programName: string;
  public programType: ProgramType;
  public purchaseDate: Date;
  public purchasedContractID: number;
  public quoteResponseID: string;
  public refundPercentage: number;
  public reinstatedDate: Date;
  public reinstatedFlag: string;
  public remitHistory: string;
  public retailAmount: number;
  public roadsidePhoneNumber: string;
  public roNumber: string;
  public saleType: number;
  public statusCode: string;
  public subtractClaimsFlag: string;
  public surcharges: string;
  public totalClaimCount: number;
  public totalClaimsPaidAmount: number;
  public transferFeeAmount: number;
  public transferNumber: number;
  public transferredFlag: string;
  public transferTypeCode: string;
  public vehicle: VehicleInformation;
  public vehicleMilesWhenCancel: number;
  //#endregion

  //#region : Legacy Properties :
  // public programTypeID: number;

  // public statusDescription: string;
  public coverageDisplayTypeID: number;

  // public customerFirstName: string;
  // public customerLastName: string;
  // public secondaryCustomerFirstName: string;
  // public secondaryCustomerLastName: string;
  // public customerPhoneNumber: string;
  // public customerEmailAddress: string;
  // public customerAddress1: string;
  // public customerAddress2: string;
  // public customerCity: string;
  // public customerStateAbbreviation: string;
  // public customerZipCode: string;

  // public customerCostAmount: number;
  // public dealerCostAmount: number;

  // public dealerPhoneNumber: string;
  // public dealerAddress1: string;
  // public dealerAddress2: string;
  // public dealerCity: string;
  // public dealerStateAbbreviation: string;
  // public dealerZipCode: string;
  // public lienHolderName: string;
  // public lienHolderAddress1: string;
  // public lienHolderAddress2: string;
  // public lienHolderStateAbbreviation: string;
  // public lienHolderZipCode: string;
  // public lienHolderFinanceAmount: number;
  // public lienHolderMonthlyPaymentAmount: number;
  // public lienHolderLoanContractTypeID: number;
  // public lienHolderInterestRate: number;
  // public lienHolderLoanTerm: number;
  // public lienHolderFirstPaymentDate: Date;
  // public lienHolderDownPaymentAmount: number;
  // public lienHolderCity: string;

  // public vehicleYear: number;
  // public vinNumber: string;
  // public newOrUsedCode: string;
  //#endregion

  //#region : Methods :
  static createFromObject(obj: object) {
    const item = new Contract();
    Object.assign(item, obj);
    return item;
  }

  getCoverageDisplay(): string {
    let display = '';
    const displayType =
      this.coverageDisplayType != null
        ? this.coverageDisplayType
        : this.coverageDisplayTypeID === CoverageDisplayType.Months
        ? CoverageDisplayType.Months
        : CoverageDisplayType.Years;

    if (displayType === CoverageDisplayType.Months) {
      // Months
      display += this.coverageMonths > 0 ? this.coverageMonths.toString() + ' month' + (this.coverageMonths > 1 ? 's' : '') : 'Unlimited';
    } else {
      // Years
      const years = this.coverageMonths / 12;
      display += years.toString() + ' year' + (years > 1 ? 's' : '');
    }

    if (this.coverageMiles < 999999) {
      display += ' / ' + (this.coverageMiles != null && this.coverageMiles > 0 ? this.coverageMiles.toLocaleString() : '0') + ' miles';
    }

    return display;
  }

  getExpirationMilesDisplay(): string {
    let display = '';

    if (this.expirationMiles < 999999) {
      display +=
        ' or ' + (this.expirationMiles != null && this.expirationMiles > 0 ? this.expirationMiles.toLocaleString() : '0') + ' miles';
    }

    return display;
  }
  //#endregion
}
