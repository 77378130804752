import { Injectable } from '@angular/core';
import { PreloadingStrategy, Route } from '@angular/router';
import { Observable, of, timer } from 'rxjs';
import { flatMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AppPreloadingStrategy implements PreloadingStrategy {
  preload(route: Route, load: () => Observable<any>): Observable<any> {
    if (!route?.data?.preload) {
      return of(null);
    }

    const delay = route.data.delay < 0 ? 0 : route.data.delay;

    return timer(delay).pipe(flatMap((_) => load()));
  }
}
