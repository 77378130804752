import { AbstractControl, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';

export class CustomValidators {
  static pattern(regex: RegExp, error: ValidationErrors): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (!control.value) {
        return null;
      }
      const valid = regex.test(control.value);
      return valid ? null : error;
    };
  }

  static matchLength(length: number | string, error: ValidationErrors): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (!control.value) {
        return null;
      }
      return control.value.toString().length === length ? null : error;
    };
  }

  static mustMatch(matchingControlName: string, error: ValidationErrors): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (!control.parent || !control.value) {
        return null;
      }
      const matchingControl = control.parent.controls[matchingControlName];
      return control.value === matchingControl.value ? null : error;
    };
  }

  static requiredIfControl(otherControlName: string, predicate: (otherControlValue: any) => boolean): ValidatorFn {
    return (control: AbstractControl): ValidationErrors => {
      if (!control.parent) {
        return null;
      }
      const otherControl = control.parent.controls[otherControlName];
      const predicateValue = predicate(otherControl.value);
      const required = Validators.required(control);
      return predicateValue ? required : null;
    };
  }
}
