import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { faPercent } from '@fortawesome/free-solid-svg-icons';
import { SidenavBackdropClicked, ToggleSidenav } from '@mbp/core';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { delay } from 'rxjs/operators';
import { RootComponentSelector, RootComponentStateModel } from './root.selector';
import { NbIconLibraries } from '@nebular/theme';

@Component({
  selector: 'mbp-root',
  templateUrl: './root.component.html',
  styleUrls: ['./root.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RootComponent implements OnInit {
  viewModel$: Observable<RootComponentStateModel>;
  @Select(RootComponentSelector.getState) state$: Observable<RootComponentStateModel>;

  constructor(library: FaIconLibrary, private iconLibraries: NbIconLibraries) {
    library.addIcons(faPercent);
    this.iconLibraries.registerSvgPack('google-material-icons', {
      car_repair:
        '<svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24"><path fill="currentColor" d="M16.22 12c.68 0 1.22-.54 1.22-1.22c0-.67-.54-1.22-1.22-1.22S15 10.11 15 10.78c0 .68.55 1.22 1.22 1.22m-9.66-1.22c0 .67.54 1.22 1.22 1.22S9 11.46 9 10.78c0-.67-.54-1.22-1.22-1.22s-1.22.55-1.22 1.22M7.61 4L6.28 8h11.43l-1.33-4zm8.67-1s.54.01.92.54c.02.02.03.04.05.07c.07.11.14.24.19.4c.22.65 1.56 4.68 1.56 4.68v6.5c0 .45-.35.81-.78.81h-.44c-.43 0-.78-.36-.78-.81V14H7v1.19c0 .45-.35.81-.78.81h-.44c-.43 0-.78-.36-.78-.81v-6.5S6.34 4.67 6.55 4c.05-.16.12-.28.19-.4c.03-.02.04-.04.06-.06c.38-.53.92-.54.92-.54zM4 17.01h16V19h-7v3h-2v-3H4z"/></svg>',
    });
  }

  ngOnInit(): void {
    // HACK: Having issues with right sidenav not opening when state changes
    this.viewModel$ = this.state$.pipe(delay(0));
  }

  @Dispatch()
  onSidenavToggle() {
    return new ToggleSidenav();
  }

  @Dispatch()
  onBackdropClick() {
    return new SidenavBackdropClicked();
  }
}
