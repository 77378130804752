import { Pipe, PipeTransform } from '@angular/core';
import * as numeral from 'numeral';

const defaultFormat = '0,0.0000';

@Pipe({
  name: 'numeral',
})
export class NumeralPipe implements PipeTransform {
  transform(value: string | number, format?: string): string | number {
    return numeral(value).format(format || defaultFormat);
  }
}
