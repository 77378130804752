export class ContractMinimal {
  //#region : Constructor :
  constructor() {}
  //#endregion

  //#region : Properties :

  public contractNumber: number;
  public firstName: string;
  public lastName: string;
  public vin: string;
  public planDescription: string;
  public coverageMiles: number;
  public coverageMonths: number;
  public coverageDisplayTypeID: number;
  public dealerCode: string;
  public programCode: string;
  public vehicleYear: number;
  public vehicleMakeDescription: string;
  public vehicleModelDescription: string;
  public vehicleNumberWheelsDriving: number;
  public vehicleCylinderNumber: number;
  public vehicleFuelTypeDescription: string;
  public vehicleHasTurbo: boolean;
  public vehicleHasSupercharger: boolean;
  public pointOfSaleOrderIdentifier: string;
  public pointOfSaleTransactionNumber: string;
  public pointOfSalePaymentAmount: number;

  //#endregion

  //#region : Methods :
  static createFromObject(obj: object) {
    const raterHistory = new ContractMinimal();
    Object.assign(raterHistory, obj);
    return raterHistory;
  }

  getCoverageDisplay(): string {
    let display = '';

    if (this.coverageDisplayTypeID === 1) {
      // Months
      display += this.coverageMonths > 0 ? this.coverageMonths.toString() + ' month' + (this.coverageMonths > 1 ? 's' : '') : 'Unlimited';
    } else {
      // Years
      const years = this.coverageMonths / 12;
      display += years.toString() + ' year' + (years > 1 ? 's' : '');
    }

    if (this.coverageMiles < 999999) {
      display += ' / ' + (this.coverageMiles != null && this.coverageMiles > 0 ? this.coverageMiles.toFixed(0) : '0') + ' miles';
    }

    return display;
  }
  //#endregion
}
