import { isEmpty } from 'lodash';
import { Validator } from './validator';

export class ValidatorController {
  private validators: Validator[] = [];
  public errors: string[] | null;

  public getFirstError(): string | null {
    if (this.errors?.length > 0) {
      return this.errors[0];
    }
    return null;
  }

  addValidators(validators: Validator[]): ValidatorController {
    if (isEmpty(validators?.filter(Boolean))) return this;
    this.validators.push(...validators);

    return this;
  }

  validate(): string | null {
    return this.validators.find((validator) => validator.validate()?.error)?.error;
  }
}
