import { Contract, ContractSearchOnSearch, ContractSearchState } from '@mbp/core';
import { NbComponentSize, NbWindowState } from '@nebular/theme';
import { ActionsExecuting, actionsExecuting } from '@ngxs-labs/actions-executing';
import { Selector } from '@ngxs/store';
import { orderBy } from 'lodash';

export class ContractSearchQueries {
  @Selector([ContractSearchState.props.contracts])
  public static getContracts(contracts: Contract[]): Contract[] {
    const sortedContracts = orderBy(contracts, 'contractStatusType', 'asc');
    return sortedContracts;
  }

  @Selector([
    ContractSearchState.props.contractSearchWindowSize,
    actionsExecuting([ContractSearchOnSearch]),
    ContractSearchQueries.getContracts,
  ])
  public static getViewModel(nbWindowState: NbWindowState, isSearching: ActionsExecuting, contracts: Contract[]) {
    const cardSize = nbWindowStateMap[nbWindowState];
    const scrollItemSize = scrollItemSizeMap[cardSize];

    return {
      cardSize,
      scrollItemSize,
      isSearching,
      contracts,
    };
  }
}

const nbWindowStateMap: Record<NbWindowState, NbComponentSize> = {
  [NbWindowState.MINIMIZED]: 'tiny',
  [NbWindowState.MAXIMIZED]: 'tiny',
  [NbWindowState.FULL_SCREEN]: 'small',
};

const scrollItemSizeMap: Record<NbComponentSize, number> = {
  tiny: 246,
  small: 369,
  medium: 492,
  large: 615,
  giant: 738,
};
