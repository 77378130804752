import { ProgramType } from './enums';
import { QuoteResponsePlan } from './quote-response-plan.model';
import { RaterMenuGroup } from './rater-menu-group.model';

export class RaterMenuProduct {
  //#region : Constructor :
  constructor() {}
  //#endregion

  //#region : Properties :

  public menuProductID: string;
  public programType: ProgramType = null;
  public name: string;
  public description: string;
  public applySalesTax: boolean;
  public groups: RaterMenuGroup[];
  public currentGroups: RaterMenuGroup[];
  public isIncluded: boolean;
  public isIncludedInitially: boolean;
  public isIncludedInQuote: boolean;
  public sortOrder: number;
  public externalProductCustomerPrice: number;
  public externalProductDealerCost: number;
  public externalProductCoverageMonths: number = null;
  public externalProductCoverageMiles: number = null;

  public selectedPlan: QuoteResponsePlan;
  public selectedPlan2: QuoteResponsePlan;
  public costPerMonthAmount: number = 0;
  public costPerMonthAmount2: number;
  public totalTaxAmount: number;
  public totalTaxAmount2: number;

  public isMBPFinanced: boolean;
  public planGroupName: string; // This is used for multiple products within the same program type
  public isSplitOutProduct: boolean;
  public isTemporaryProduct: boolean;
  //#endregion

  //#region : Methods :
  public static cloneForAdd(sourceMenuProduct: RaterMenuProduct): RaterMenuProduct {
    const newProduct: RaterMenuProduct = new RaterMenuProduct();

    Object.assign(newProduct, sourceMenuProduct);

    newProduct.menuProductID = '00000000-0000-0000-0000-000000000000';
    newProduct.name = '';
    newProduct.programType = null;
    newProduct.description = '';
    newProduct.externalProductCoverageMiles = null;
    newProduct.externalProductCoverageMonths = null;
    newProduct.externalProductCustomerPrice = null;
    newProduct.externalProductDealerCost = null;

    newProduct.groups.forEach((p) => {
      p.isIncluded = true;
      p.isIncludedInitially = true;
    });

    return newProduct;
  }

  public static cloneForNewProductSubType(sourceMenuProduct: RaterMenuProduct, planGroupName: string): RaterMenuProduct {
    const newProduct: RaterMenuProduct = new RaterMenuProduct();

    Object.assign(newProduct, sourceMenuProduct);

    newProduct.name = planGroupName + ' ' + newProduct.name;
    newProduct.planGroupName = planGroupName;
    newProduct.isSplitOutProduct = null;
    newProduct.isTemporaryProduct = true;

    newProduct.externalProductCoverageMiles = null;
    newProduct.externalProductCoverageMonths = null;
    newProduct.externalProductCustomerPrice = null;
    newProduct.externalProductDealerCost = null;

    newProduct.groups.forEach((p) => {
      p.isIncluded = true;
      p.isIncludedInitially = true;
    });

    return newProduct;
  }
  //#endregion
}
