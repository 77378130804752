import { ProgramType } from './enums';

export class RegenerateContractRequest {
  //#region : Constructor :
  constructor(
    userName?: string,
    dealerCode?: string,
    contractNumber?: string,
    contractSuffix?: string,
    programType?: ProgramType,
    useSefi?: boolean,
  ) {
    this.userName = userName;
    this.dealerCode = dealerCode;
    this.contractNumber = contractNumber;
    this.contractSuffix = contractSuffix;
    this.programType = programType;
    this.useSefi = useSefi;
  }
  //#endregion

  //#region : Properties :
  public userName: string;
  public dealerCode: string;
  public contractNumber: string;
  public contractSuffix: string;
  public programType: ProgramType;
  public useSefi: boolean;
  //#endregion
}
