import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { RepositoryItem } from '@mbp/core';

@Component({
  selector: 'mbp-notifications-window',
  templateUrl: './notifications-window.component.html',
  styleUrls: ['./notifications-window.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationsWindowComponent {
  @Input() notifications: RepositoryItem[] = [];
}
