import { Injectable } from '@angular/core';
import { Action, NgxsAfterBootstrap, Selector, State, StateContext, StateToken, Store } from '@ngxs/store';
import { patch } from '@ngxs/store/operators';
import { interval } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';
import { AuthState } from '..';
import { DealersService } from '../api/dealers.service';
import { DealerMinimal } from '../models/dealer-minimal';
import { FetchDealerCount, FetchDealerMinimals, SetDealerCode } from './dealer.actions';
import { createPropertySelectors } from './ngxs-next';

export const DEALER_COUNT_THRESHOLD = 50;

export interface DealerSearchStateModel {
  dealerMinimals: DealerMinimal[];
  fetchedDealerMinimals: boolean;
}

// TODO: Make it more apparent what properties are being used for "Currently Logged in Dealer/Agent"
export interface DealerStateModel {
  dealerCode: string;
  dealerCount: number;
  dealerMinimals: DealerMinimal[];
  fetchedDealerMinimals: boolean;
  currentDealerMinimal: DealerMinimal;
}

const defaultState: DealerStateModel = {
  // TODO: rename, as this is for the currently logged in user's dealerCode, not for searching
  dealerCode: null,
  dealerCount: 0,
  dealerMinimals: [],
  fetchedDealerMinimals: false,
  currentDealerMinimal: null,
};

export const DEALER_STATE_TOKEN = new StateToken<DealerStateModel>('dealer');

@State<DealerStateModel>({
  name: DEALER_STATE_TOKEN,
  defaults: {
    ...defaultState,
  },
})
@Injectable()
export class DealerState implements NgxsAfterBootstrap {
  public static props = createPropertySelectors(DEALER_STATE_TOKEN);

  @Selector()
  static dealerSearch(state: DealerStateModel): DealerSearchStateModel {
    return {
      fetchedDealerMinimals: state.fetchedDealerMinimals,
      dealerMinimals: state.dealerMinimals,
    };
  }

  constructor(private dealerService: DealersService, private store: Store) {}

  // FIXME: I think this causes a problem when logging out/in
  ngxsAfterBootstrap(ctx: StateContext<DealerStateModel>): void {
    const run = () => {
      const isLoggedIn = this.store.selectSnapshot(AuthState.getIsLoggedIn);
      if (isLoggedIn) {
        ctx.dispatch(new FetchDealerCount());
      }
    };
    run();
    interval(1000 * 60 * 60).subscribe(() => {
      ctx.setState(
        patch<DealerStateModel>({
          fetchedDealerMinimals: false,
        }),
      );
      run();
    });
  }

  @Action(SetDealerCode)
  setDealerCode(ctx: StateContext<DealerStateModel>, act: SetDealerCode) {
    return ctx.setState(
      patch<DealerStateModel>({
        dealerCode: act.payload,
      }),
    );
  }

  @Action(FetchDealerCount)
  fetchDealerCount(ctx: StateContext<DealerStateModel>) {
    return this.dealerService.dealerCount().pipe(
      map((dealerCount) => {
        ctx.setState(
          patch<DealerStateModel>({
            dealerCount,
          }),
        );
        if (dealerCount <= DEALER_COUNT_THRESHOLD) {
          return ctx.dispatch(new FetchDealerMinimals());
        }
      }),
    );
  }

  @Action(FetchDealerMinimals)
  fetchDealerMinimals(ctx: StateContext<DealerStateModel>) {
    return this.dealerService.dealers('').pipe(
      filter((dealerMinimals) => !!dealerMinimals),
      tap((dealerMinimals) => {
        ctx.setState(
          patch<DealerStateModel>({
            currentDealerMinimal: dealerMinimals.length === 1 ? dealerMinimals[0] : null,
            dealerMinimals,
            fetchedDealerMinimals: true,
          }),
        );
      }),
    );
  }
}
