<ng-container *ngIf="viewModel$ | async as vm">
  <div fxlayout="row">
    <mbp-search-input
      fxFlex
      placeholder="Contract #, VIN, or Last Name"
      [isSearching]="vm.isSearching"
      [focus]="true"
      [searchText]="searchText"
      (search)="search($event); onHelpHide()"
      (clear)="clear()"
    ></mbp-search-input>

    <button nbButton ghost (click)="onHelpToggle()">
      <nb-icon icon="question-mark-circle-outline" pack="eva"></nb-icon>
    </button>
  </div>
  <div [nbPopover]="ContractSearchHelpComponent" nbPopoverTrigger="noop" nbPopoverPlacement="bottom"></div>

  <cdk-virtual-scroll-viewport [itemSize]="vm.scrollItemSize" class="scroll-viewport">
    <p *ngIf="!!(searchMessage$ | async) || !vm.contracts?.length" class="search-message">{{ searchMessage$ | async }}</p>
    <mbp-contract-card
      *cdkVirtualFor="let contract of vm.contracts; trackBy: trackByFn; let first = first; templateCacheSize: 0"
      [contract]="contract"
      [class.first]="first"
      class="contract-card"
      [cardSize]="vm.cardSize"
    >
    </mbp-contract-card>
  </cdk-virtual-scroll-viewport>
</ng-container>
