<mbp-loading-container [isLoading]="isLoadingAgents" [hasError]="loadFailed" [showReload]="false" (reload)="reload()">
  <mat-form-field *ngIf="agents.length > 1 && agents.length <= 20 && isLoadingAgents == false">
    <mat-label> Agency </mat-label>
    <mat-select
      [placeholder]="placeholder"
      [required]="isRequired"
      [value]="selectedAgent"
      (selectionChange)="onAgentSelected($event.value)"
    >
      <mat-option *ngFor="let agent of filteredAgents" [value]="agent"> {{ agent.name }} ({{ agent.agentCode }}) </mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field *ngIf="agents.length > 20 && isLoadingAgents == false" class="mbp-full-width" style="width: 100%">
    <mat-label> Agency </mat-label>
    <input
      matInput
      type="text"
      class="input"
      [value]="searchText"
      [placeholder]="placeholder"
      [required]="isRequired"
      [matAutocomplete]="agentAuto"
      (keyup)="onValueChanged($event.target.value)"
    />
    <mat-autocomplete
      #agentAuto="matAutocomplete"
      [displayWith]="agentSearchDisplay"
      (optionSelected)="onAgentSelected($event.option.value)"
    >
      <mat-option *ngFor="let agent of filteredAgents" [value]="agent"> {{ agent.name }} ({{ agent.agentCode }}) </mat-option>
    </mat-autocomplete>
  </mat-form-field>
</mbp-loading-container>
