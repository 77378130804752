<button mat-button [matMenuTriggerFor]="menu" fxLayout fxLayoutAlign="start center" disableRipple class="trigger">
  <span fxLayout fxLayoutAlign="start center">
    <span class="name" fxHide fxShow.gt-sm>{{ fullName }}</span>
    <mat-icon class="icon" fxHide fxShow.gt-sm>keyboard_arrow_down</mat-icon>
  </span>
</button>
<mat-menu #menu="matMenu" xPosition="before">
  <a [href]="settingsUrl" mat-menu-item class="menu-item">
    Profile
    <mat-icon>account_circle</mat-icon>
  </a>
  <!-- <a routerLink="user/settings" mat-menu-item class="menu-item">
    Settings
    <mat-icon>settings</mat-icon>
  </a> -->
  <a href="https://www.mbpnetwork.com/consumers/contact/" target="_blank" mat-menu-item class="menu-item">
    Contact Us
    <mat-icon>contact_page</mat-icon>
  </a>
  <a [href]="logoutUrl" mat-menu-item class="menu-item">
    Logout
    <mat-icon>exit_to_app</mat-icon>
  </a>
  <!-- <mat-divider></mat-divider>
  <div mat-menu-item class="v menu-item" disabled>version: {{ version }}</div> -->
</mat-menu>
