export class UserAssociateContractRequest {
  //#region : Constructor :
  constructor() {}
  //#endregion

  //#region : Properties :
  public userName: string = null;
  public contractNumber: string = null;
  public lastName: string = null;
  public last6OfVin: string = null;
  public zipCode: string = null;
  //#endregion
}
