import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit, OnChanges } from '@angular/core';
import {
  Contract,
  ContractSearchFetchPolicyContract,
  ContractSearchOnDownload,
  ContractSearchOnPrintCancelForm,
  ContractSearchOnRefreshFromAs400,
  ContractSearchOnStartCancelQuote,
  ContractSearchWindowClose,
  ContractSearchWindowMinimize,
  ContractStatusType,
  ProgramType,
} from '@mbp/core';
import { NbComponentSize, NbIconConfig } from '@nebular/theme';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { Actions, Store, ofActionCompleted } from '@ngxs/store';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import { ContractCardQueries } from './contract-card.queries';

@UntilDestroy()
@Component({
  selector: 'mbp-contract-card',
  templateUrl: './contract-card.component.html',
  styleUrls: ['./contract-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContractCardComponent implements OnInit, OnChanges {
  @Input() contract: Contract;
  @Input() cardSize: NbComponentSize = 'tiny';
  viewModel$: Observable<ReturnType<ReturnType<typeof ContractCardQueries.getViewModel>>>;

  ContractStatusType = ContractStatusType;
  ContractSearchWindowClose = ContractSearchWindowClose;
  ProgramType = ProgramType;
  car_repair: NbIconConfig = { icon: 'car_repair', pack: 'google-material-icons' };

  downloadClicked = false;
  printCancelFormClicked = false;
  refreshFromAs400Clicked = false;
  cancelQuoteClicked = false;

  constructor(private actions$: Actions, private cd: ChangeDetectorRef, private store: Store) {}

  ngOnChanges(): void {
    this.viewModel$ = this.store.select(ContractCardQueries.getViewModel(this.contract));
  }

  ngOnInit(): void {
    ContractSearchFetchPolicyContract.dispatch({ contract: this.contract });
    this.actions$
      .pipe(
        filter((_) => this.downloadClicked || this.printCancelFormClicked || this.refreshFromAs400Clicked || this.cancelQuoteClicked),
        ofActionCompleted(
          ContractSearchOnDownload,
          ContractSearchOnPrintCancelForm,
          ContractSearchOnRefreshFromAs400,
          ContractSearchOnStartCancelQuote,
        ),
        untilDestroyed(this),
      )
      .subscribe(() => {
        this.downloadClicked = false;
        this.printCancelFormClicked = false;
        this.refreshFromAs400Clicked = false;
        this.cancelQuoteClicked = false;
        this.cd.detectChanges();
      });
  }

  @Dispatch()
  onDownload() {
    this.downloadClicked = true;
    this.cd.detectChanges();
    return new ContractSearchOnDownload(this.contract);
  }

  @Dispatch()
  onPrintCancelForm() {
    this.printCancelFormClicked = true;
    this.cd.detectChanges();
    return new ContractSearchOnPrintCancelForm(this.contract);
  }

  @Dispatch()
  onRefreshFromAs400() {
    this.refreshFromAs400Clicked = true;
    this.cd.detectChanges();
    return new ContractSearchOnRefreshFromAs400(this.contract);
  }

  @Dispatch()
  onStartCancelQuote() {
    this.cancelQuoteClicked = true;
    this.cd.detectChanges();

    return [new ContractSearchOnStartCancelQuote(this.contract), new ContractSearchWindowMinimize()];
  }
}
