import { Pipe, PipeTransform } from '@angular/core';
import { conformToMask } from 'angular2-text-mask';

const phoneNumberMask = ['(', /\d/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];

@Pipe({
  name: 'phoneMask',
})
export class PhoneMaskPipe implements PipeTransform {
  transform(value: string) {
    if (!value) {
      return;
    }

    const conformed = conformToMask(value, phoneNumberMask, {});
    return conformed.conformedValue;
  }
}
