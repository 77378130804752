import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NbWindowState } from '@nebular/theme';
import { Action, State, StateContext, StateToken, Store, createSelector } from '@ngxs/store';
import { patch } from '@ngxs/store/operators';
import { ClaimsFormRoutes } from 'app/modules/claims-authorization/models/models';
import { of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { ContractsService } from '../api/contracts.service';
import { RaterService } from '../api/rater.service';
import { ContractsGraphql } from '../graphql';
import { ContractSearchRequest } from '../models/contract-search-request.model';
import { Contract } from '../models/contract.model';
import { ContractStatusType, ProgramCode } from '../models/enums';
import { ConfigurationItem } from '../models/enums-pg';
import { GenerateCancelFormRequest } from '../models/generate-cancel-form-request.model';
import { RefreshContractRequest } from '../models/refresh-contract-request.model';
import { RegenerateContractRequest } from '../models/regenerate-contract-request.model';
import { SaveFile } from './app.state';
import { AuthState } from './auth.state';
import {
  ContractSearchFetchPolicyContract,
  ContractSearchHelpHide,
  ContractSearchHelpToggle,
  ContractSearchOnClear,
  ContractSearchOnDownload,
  ContractSearchOnPrintCancelForm,
  ContractSearchOnRefreshFromAs400,
  ContractSearchOnSearch,
  ContractSearchWindowSizeChanged,
} from './contract-search.actions';
import { createPropertySelectors } from './ngxs-next';

export interface ContractSearchStateModel {
  contracts: Contract[];
  policyContracts: Record<string, string>;
  searchText: string;
  showContractSearchHelp: boolean;
  contractSearchWindowSize: NbWindowState;
}

const defaultState: ContractSearchStateModel = {
  contracts: null,
  policyContracts: {},
  searchText: null,
  showContractSearchHelp: false,
  contractSearchWindowSize: null,
};

const CONTRACT_SEARCH_STATE_TOKEN = new StateToken<ContractSearchStateModel>('contractSearch');

@State<ContractSearchStateModel>({
  name: CONTRACT_SEARCH_STATE_TOKEN,
  defaults: defaultState,
})
@Injectable()
export class ContractSearchState {
  public static props = createPropertySelectors(CONTRACT_SEARCH_STATE_TOKEN);

  public static getLinkForContract(contract: Contract) {
    return createSelector(
      [ContractSearchState.props.policyContracts, AuthState.props.currentUserIsMBPI, AuthState.props.allowedClaimsPreAuth],
      (
        policyContracts: ReturnType<typeof ContractSearchState.props.policyContracts>,
        currentUserIsMBPI: boolean,
        allowedClaimsPreAuth: boolean,
      ) => {
        if (
          (currentUserIsMBPI || allowedClaimsPreAuth) &&
          contract.contractStatusType == ContractStatusType.Active &&
          [ProgramCode.TAW, ProgramCode.MCR].includes(contract.programCode as ProgramCode)
        ) {
          const key = buildPolicyContractKey(contract);
          return policyContracts[key];
        }
        return null;
      },
    );
  }

  constructor(
    private contractService: ContractsService,
    private raterService: RaterService,
    private store: Store,
    private contractsGraphql: ContractsGraphql,
  ) {}

  @Action(ContractSearchOnSearch, { cancelUncompleted: true })
  public contractSearchOnSearch({ patchState }: StateContext<ContractSearchStateModel>, { payload: searchText }: ContractSearchOnSearch) {
    patchState({
      searchText,
      contracts: null,
    });

    const searchRequest: ContractSearchRequest = {
      userName: this.store.selectSnapshot(AuthState.userName),
      searchText,
      searchAllDealers: true,
    };

    return this.contractService.getContracts(searchRequest).pipe(
      tap((contracts) =>
        patchState({
          contracts,
        }),
      ),
    );
  }

  @Action(ContractSearchOnClear)
  public contractSearchOnClear({ patchState }: StateContext<ContractSearchStateModel>) {
    patchState({
      searchText: null,
      contracts: null,
    });
  }

  @Action(ContractSearchOnDownload)
  public contractSearchOnDownload(ctx: StateContext<ContractSearchStateModel>, act: ContractSearchOnDownload) {
    const regenerateRequest = new RegenerateContractRequest(
      this.store.selectSnapshot(AuthState.userName),
      act.payload.dealerCode,
      act.payload.contractNumber.toString(),
      act.payload.contractSuffix,
      act.payload.programType,
    );
    return this.raterService.getRegenerateContract(regenerateRequest).pipe(
      tap((response) =>
        SaveFile.dispatch({
          data: response.fileContents,
          filename: response.fileDownloadName,
          contentType: response.contentType,
        }),
      ),
    );
  }

  @Action(ContractSearchOnPrintCancelForm)
  public contractSearchOnPrintCancelForm(ctx: StateContext<ContractSearchStateModel>, act: ContractSearchOnPrintCancelForm) {
    const request = {
      userName: this.store.selectSnapshot(AuthState.userName),
      contract: act.payload,
      onlyCancellationForm: true,
    } as GenerateCancelFormRequest;
    return this.contractService.generateCancelFormPdf(request).pipe(
      tap((response) =>
        SaveFile.dispatch({
          data: response.fileContents,
          filename: response.fileDownloadName,
          contentType: response.contentType,
        }),
      ),
    );
  }

  @Action(ContractSearchOnRefreshFromAs400)
  public contractSearchOnRefreshFromAs400(_: any, { payload }: ContractSearchOnPrintCancelForm) {
    const request = new RefreshContractRequest(
      this.store.selectSnapshot(AuthState.userName),
      payload.programCode,
      payload.dealerCode,
      payload.contractNumber.toString(),
      payload.contractSuffix,
    );
    return this.contractService.refreshContract(request).pipe(catchError((error) => of(error)));
  }

  @Action(ContractSearchHelpHide)
  public contractSearchHelpHide(ctx: StateContext<ContractSearchStateModel>, act: ContractSearchHelpHide) {
    return ctx.setState(
      patch<ContractSearchStateModel>({
        showContractSearchHelp: false,
      }),
    );
  }

  @Action(ContractSearchHelpToggle)
  public contractSearchHelpToggle(ctx: StateContext<ContractSearchStateModel>, act: ContractSearchHelpToggle) {
    const showContractSearchHelp = ctx.getState().showContractSearchHelp;
    return ctx.setState(
      patch<ContractSearchStateModel>({
        showContractSearchHelp: !showContractSearchHelp,
      }),
    );
  }

  @Action(ContractSearchWindowSizeChanged)
  public contractSearchWindowSizeChanged(ctx: StateContext<ContractSearchStateModel>, act: ContractSearchWindowSizeChanged) {
    return ctx.setState(
      patch<ContractSearchStateModel>({
        contractSearchWindowSize: act.payload.contractSearchWindowSize,
      }),
    );
  }

  @Action(ContractSearchFetchPolicyContract)
  public async contractSearchFetchPolicyContract(
    ctx: StateContext<ContractSearchStateModel>,
    { payload }: ContractSearchFetchPolicyContract,
  ) {
    const variables = {
      contractNumber: payload.contract.contractNumber,
      contractSuffix: payload.contract.contractSuffix ?? '',
      programCode: payload.contract.programCode,
    };
    const { data, errors } = await this.contractsGraphql.getPolicyContractForLink(variables);

    if (errors) return;

    const legacy = data.selectLegacyPolicyContracts?.[0];
    const policyContract = data.selectPolicyContracts?.[0];
    const id = legacy?.id ?? policyContract?.id;

    if (!id) return;

    const includesDentCoverage = legacy?.includesDentCoverage ?? policyContract?.includesDentCoverage;
    const includesTireCoverage = legacy?.includesTireCoverage ?? policyContract?.includesTireCoverage;
    const includesWindshieldCoverage = legacy?.includesWindshieldCoverage ?? policyContract?.includesWindshieldCoverage;

    let form = '';
    const categoryCode = legacy?.categoryCode ?? policyContract?.product.category.code;
    if (categoryCode === ProgramCode.TAW) {
      form = ClaimsFormRoutes.TireAndWheel;
    }

    if (categoryCode === ProgramCode.MCR) {
      form = ClaimsFormRoutes.Choose;
      const coverageCount = +includesDentCoverage + +includesTireCoverage + +includesWindshieldCoverage;
      if (coverageCount === 1) {
        form = includesDentCoverage
          ? ClaimsFormRoutes.Dent
          : includesTireCoverage
          ? ClaimsFormRoutes.TireAndWheel
          : ClaimsFormRoutes.Windshield;
      }
    }

    return ctx.setState(
      patch<ContractSearchStateModel>({
        policyContracts: patch({
          [buildPolicyContractKey(variables)]: `/claims-authorization/contract/${id}/${form}`,
        }),
      }),
    );
  }
}

export function buildPolicyContractKey(input: { contractNumber: string; contractSuffix: string; programCode: string }) {
  return `${input.contractNumber}_${input.contractSuffix ?? ''}_${input.programCode}`;
}
