import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import {
  InternalMessagesRequest,
  InternalMessageType,
  MessageStatus,
  MessageStatusType,
  MessageType,
  RightSidebarService,
  SearchCriteriaPageType,
  ServicePageEvent,
} from '@mbp/core';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'mbp-internal-messages-search-criteria',
  templateUrl: './internal-messages-search-criteria.component.html',
  styleUrls: ['./internal-messages-search-criteria.component.scss'],
})
export class InternalMessagesSearchCriteriaComponent implements OnInit, OnDestroy {
  //#region : Variable Declarations :
  isLoading = false;
  searchCriteriaForm: FormGroup;

  messageStatuses: MessageStatus[] = MessageStatus.arrayList();
  messageTypes: InternalMessageType[] = InternalMessageType.arrayList();
  searchCriteriaRequest = new InternalMessagesRequest();

  runImmediatelySubscription: Subscription;
  //#endregion

  //#region : Constructor :
  constructor(private formBuilder: FormBuilder, private rightSidebarService: RightSidebarService) {}
  //#endregion

  //#region : Properties :
  //#endregion

  //#region : Implementation Methods :
  ngOnInit() {
    this.buildForm();

    setTimeout(() => {
      this.loadLookupData();
    }, 500);

    this.runImmediatelySubscription = this.rightSidebarService.runImmediately$
      .pipe(filter((d) => !!d))
      .subscribe((sc: ServicePageEvent) => {
        setTimeout(() => {
          if (sc.searchCriteriaPageType == SearchCriteriaPageType.InternalMessages) {
            this.search();
          }
        }, 500);
      });
  }

  ngOnDestroy() {
    if (this.runImmediatelySubscription != null) {
      this.runImmediatelySubscription.unsubscribe();
    }
  }
  //#endregion

  //#region : Methods :
  buildForm() {
    this.searchCriteriaRequest.messageStatusTypeID = MessageStatusType.All;
    this.searchCriteriaRequest.messageTypeID = MessageType.All;
    this.searchCriteriaRequest.onlyActiveMessages = true;
    this.searchCriteriaRequest.searchText = null;
    this.searchCriteriaRequest.startDate = null;
    this.searchCriteriaRequest.endDate = null;

    this.searchCriteriaForm = this.formBuilder.group(this.searchCriteriaRequest);
  }

  loadLookupData() {}

  public search() {
    this.rightSidebarService.setSearchCriteria(SearchCriteriaPageType.InternalMessages, this.searchCriteriaForm.value);
  }
  //#endregion
}
