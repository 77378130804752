import { HttpBackend, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActiveToast } from 'ngx-toastr';
import { of } from 'rxjs';
import { catchError, delay, mergeMap, repeat } from 'rxjs/operators';
import { AlertService, ToastType } from '..';

@Injectable({
  providedIn: 'root',
})
export class VersionCheckService {
  // this will be replaced by actual hash post-build.js
  private currentHash = '{{POST_BUILD_ENTERS_HASH_HERE}}';

  private readonly http: HttpClient;
  private toast: ActiveToast<any>;

  constructor(private httpBackend: HttpBackend, private alertService: AlertService) {
    // Skip interceptors for this http client
    this.http = new HttpClient(this.httpBackend);
  }

  /**
   * Checks in every set frequency the version of frontend application
   * @param {string} url
   * @param {number} frequency - in milliseconds, defaults to 10 minutes
   * @returns {void}
   */
  public init({ url, frequency }: { url: string; frequency: number }): void {
    const poll = of({}).pipe(
      // timestamp these requests to invalidate caches
      delay(frequency),
      mergeMap((_) => this.http.get(url + '?t=' + new Date().getTime())),
      repeat(),
      catchError((error) => {
        console.error(error, 'Could not get version');
        return of(null);
      }),
    );

    // poll.subscribe((response: any) => {
    //   // If new version, do something
    //   if (this.currentHash !== response?.hash) {
    //     this.toast?.toastRef?.close();
    //     this.toast = this.alertService.showCustomMessage({
    //       title: 'New Version Available!',
    //       message: 'Please reload the page to get the latest version.',
    //       config: {
    //         disableTimeOut: true, // Equivalent to timeOut: 0 and extendedTimeOut: 0
    //         tapToDismiss: false,
    //         closeButton: false,
    //       },
    //       type: ToastType.info,
    //     });
    //     // store the new hash so we wouldn't trigger versionChange again
    //     // only necessary in case you did not force refresh
    //     // this.currentHash = hash;
    //   }
    // });
  }
}
