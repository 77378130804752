import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import {
  Agency,
  AppStateModel,
  FetchVehicleYears,
  LookupService,
  LookupState,
  Program,
  QuoteRequestReportRequest,
  RightSidebarService,
  RoleType,
  SearchCriteriaPageType,
  VehicleMake,
  VehicleMakesRequest,
  VehicleModel,
  VehicleModelsRequest,
} from '@mbp/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngxs/store';
import { Observable, Subscription } from 'rxjs';
import { filter, finalize, map } from 'rxjs/operators';

@UntilDestroy()
@Component({
  selector: 'mbp-quote-requests-search-criteria',
  templateUrl: './quote-requests-search-criteria.component.html',
  styleUrls: ['./quote-requests-search-criteria.component.scss'],
})
export class QuoteRequestsSearchCriteriaComponent implements OnInit {
  //#region : Variable Declarations :
  formErrors = {
    dealer: '',
    dealerCode: '',
    startDate: '',
    endDate: '',
  };
  validationMessages = {
    dealer: {
      required: 'Please select a dealer',
      dealer: 'Please select a valid dealer',
    },
    dealerCode: {
      required: 'Please select a dealer',
      dealer: 'Please select a valid dealer',
    },
    startDate: {
      required: 'Please enter a start date',
    },
    endDate: {
      required: 'Please enter a end date',
    },
  };

  includeAgencyFilter = false;
  isLoading = false;
  isLoadingVehicleYears = false;
  isLoadingVehicleMakes = false;
  isLoadingVehicleModels = false;
  searchCriteriaForm: FormGroup;

  programs: Program[] = [];
  vehicleYears$: Observable<string[]>;
  vehicleMakes: VehicleMake[];
  vehicleModels: VehicleModel[];
  selectedAgency: Agency = null;
  private subscription = new Subscription();
  //#endregion

  //#region : Constructor :
  constructor(
    private formBuilder: FormBuilder,
    private store: Store,
    private lookupService: LookupService,
    private rightSidebarService: RightSidebarService,
  ) {}
  //#endregion

  //#region : Properties :
  //#endregion

  //#region : Implementation Methods :
  ngOnInit() {
    const user = this.store.selectSnapshot((state: AppStateModel) => state.auth.user);
    this.includeAgencyFilter = user.roleType === RoleType.Agent || user.roleType === RoleType.MBPIUser;
    this.buildForm();

    setTimeout(() => {
      this.loadLookupData();
    }, 500);

    this.subscription.add(
      this.rightSidebarService.runImmediately$
        .pipe(
          filter((sc) => !!sc),
          filter(({ searchCriteriaPageType }) => searchCriteriaPageType === SearchCriteriaPageType.QuoteRequestReport),
          untilDestroyed(this),
        )
        .subscribe((sc) => {
          if (sc.eventData) {
            this.searchCriteriaForm.patchValue({ dealer: sc.eventData });

            const onlyDealer = this.store.selectSnapshot((appState: AppStateModel) => appState.dealer.dealerMinimals.length === 1);
            if (onlyDealer) {
              this.searchCriteriaForm.get('dealer').disable();
              this.searchCriteriaForm.updateValueAndValidity();
            }
          }
          this.search();
        }),
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  //#endregion

  //#region : Methods :
  buildForm() {
    const startDate = new Date();
    const endDate = new Date();

    this.searchCriteriaForm = this.formBuilder.group({
      agency: null,
      dealer: null,
      program: '',
      startDate,
      endDate,
      vehicleYear: null,
      vehicleMake: null,
      vehicleModelName: null,
    });
    this.searchCriteriaForm.valueChanges.subscribe((data) => this.onFormValueChanged(data));
  }

  loadLookupData() {
    this.lookupService.getPrograms({}).subscribe(
      (data) => this.onProgamsLoadSuccessful(data),
      (error) => this.onProgramsLoadFailed(error),
    );

    this.vehicleYears$ = this.store.dispatch(new FetchVehicleYears()).pipe(
      map(() => this.store.selectSnapshot(LookupState.props.vehicleYears)),
      finalize(() => (this.isLoadingVehicleYears = false)),
    );
  }

  public onFormValueChanged(data?: any) {
    if (!this.searchCriteriaForm) {
      return;
    }
    const form = this.searchCriteriaForm;
    for (const field in this.formErrors) {
      if (Object.prototype.hasOwnProperty.call(this.formErrors, field)) {
        this.formErrors[field] = '';
        const control = form.get(field);
        if (control && control.dirty && !control.valid) {
          const messages = this.validationMessages[field];
          for (const key in control.errors) {
            if (Object.prototype.hasOwnProperty.call(control.errors, key)) {
              this.formErrors[field] += messages[key] + ' ';
            }
          }
        }
      }
    }
  }

  onProgamsLoadSuccessful(programsData: Program[]) {
    this.programs = Array.isArray(programsData) ? programsData : [];
  }

  onProgramsLoadFailed(error: any) {}

  public search() {
    const formValue = this.searchCriteriaForm.getRawValue();
    const searchCriteria = new QuoteRequestReportRequest();

    searchCriteria.agency = formValue.agency;
    searchCriteria.agentCode = formValue.agency && formValue.agency.agentCode;
    searchCriteria.dealer = formValue.dealer;
    searchCriteria.dealerCode = formValue.dealer && formValue.dealer.dealerCode;
    searchCriteria.program = formValue.program;
    searchCriteria.programCode = searchCriteria.program && searchCriteria.program.programCode;
    searchCriteria.startDate = formValue.startDate;
    searchCriteria.endDate = formValue.endDate;
    searchCriteria.vehicleYear = formValue.vehicleYear;
    searchCriteria.vehicleMake = formValue.vehicleMake;
    searchCriteria.vehicleModelName = formValue.vehicleModelName;
    searchCriteria.vehicleMakeCode = searchCriteria.vehicleMake && searchCriteria.vehicleMake.makeCode;

    this.rightSidebarService.setSearchCriteria(SearchCriteriaPageType.QuoteRequestReport, searchCriteria);
  }
  //#endregion

  //#region : Year/Make/Model Loading Methods :
  onVehicleMakesLoadSuccessful(makesData: VehicleMake[]) {
    this.vehicleMakes = Array.isArray(makesData) ? makesData : [];
    this.isLoadingVehicleMakes = false;
  }

  onVehicleMakesLoadFailed(error: any) {
    this.isLoadingVehicleMakes = false;
  }

  onVehicleMakeChange(makeCode: string) {
    const vehicleYear = this.searchCriteriaForm.get('vehicleYear').value;
    const modelsRequest: VehicleModelsRequest = {
      vehicleYear,
      makeCode,
    };
    this.isLoadingVehicleModels = true;
    this.lookupService.getVehicleModels(modelsRequest).subscribe(
      (data) => this.onVehicleModelsLoadSuccessful(data),
      (error) => this.onVehicleModelsLoadFailed(error),
    );
    this.searchCriteriaForm.patchValue({
      vehicleModel: null,
    });
  }

  onVehicleModelsLoadSuccessful(modelsData: VehicleModel[]) {
    this.vehicleModels = Array.isArray(modelsData) ? modelsData : [];
    this.isLoadingVehicleModels = false;
  }

  onVehicleModelsLoadFailed(error: any) {
    this.isLoadingVehicleMakes = false;
  }

  onVehicleYearChange(vehicleYear: string) {
    const makesRequest: VehicleMakesRequest = {
      vehicleYear,
    };
    this.isLoadingVehicleMakes = true;
    this.lookupService.getVehicleMakes(makesRequest).subscribe(
      (data) => this.onVehicleMakesLoadSuccessful(data),
      (error) => this.onVehicleMakesLoadFailed(error),
    );
    this.searchCriteriaForm.patchValue({
      vehicleMake: null,
      vehicleModelName: null,
    });
  }
  //#endregion
}
