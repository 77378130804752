export interface ApiResponse<T> {
  data: T;
  code: ApiResponseCode;
  errors: ApiResponseError[];
}

export interface ApiResponseError {
  message: string;
  friendlyMessage: string;
}

export enum ApiResponseCode {
  SystemError = -1,
  Success = 0,
  ExpiredJwt,
  Unauthorized,
  Error,
  RefreshTokenError,
  KratosCookieMissing,
  KratosUnauthorized,
  UserNotFound,
  UserFlowInvalidCode,
  GraphQlException,
  MfaCookieMissing,
  MfaCookieInvalid,
  MfaCookieExists,
  MfaCodeNotFound,
  MfaCodeInvalid,
  MfaCodeMaxVerificationAttempts,
  MfaCodeMaxRequests,
  MfaToInvalid,
  MfaCodeUnhandled,
  FlowItemsExist,
}
