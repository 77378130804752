import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'mbp-sidemenu-item',
  templateUrl: './sidemenu-item.component.html',
  styleUrls: ['./sidemenu-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SidemenuItemComponent {
  @Input() icon: string;
  @Input() route: string;
  @Input() externalRoute: string;
  @Input() addActiveClass = true;
  @Input() target = '_blank';
}
