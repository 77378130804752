<ng-container *ngIf="!isLoading && !hasError">
  <ng-content></ng-content>
</ng-container>

<div class="table" style="width: 100%">
  <div class="tableRow">
    <div *ngIf="isLoading" class="tableCell" fxLayout="row" fxLayoutAlign="center center">
      <ng-content select="[loading-text]"></ng-content>
      <div class="lds-ring-control gray">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
    <div *ngIf="!isLoading && hasError" class="tableCell" style="width: 20px">
      <div style="text-align: center; display: block">
        <div style="display: inline-block">
          <a><mat-icon class="cardIcon">warning</mat-icon></a>
        </div>
      </div>
    </div>
    <div *ngIf="!isLoading && showReload" class="tableCell" style="width: 20px; vertical-align: bottom">
      <div style="text-align: center; display: block">
        <div style="cursor: pointer; display: inline-block" (click)="refresh()">
          <a><mat-icon class="cardIcon">refresh</mat-icon></a>
        </div>
      </div>
    </div>
  </div>
</div>
