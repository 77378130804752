import { isNilOrEmptyString } from '..';
import { Validator } from './validator';
import { ValidatorDetails } from './validator-details';

export interface RequiredValidatorPayload {
  value: string | boolean | number | null;
}

export class RequiredValidator implements Validator {
  public error: string | null;
  constructor(public propertyDetails: ValidatorDetails, public payload: RequiredValidatorPayload) {}

  public validate(): Validator {
    if (isNilOrEmptyString(this.payload.value)) {
      this.error = `${this.propertyDetails.displayName} is required.`;
    }
    return this;
  }
}
