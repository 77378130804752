import { DealerBankAccount } from './dealer-bank-account.model';
import { Dealer } from './dealer.model';
import { AccountPaymentMethodType } from './enums';
import { PendingContract } from './pending-contract.model';

export class RemitRequest {
  //#region : Constructor :
  constructor() {}
  //#endregion

  //#region : Properties :
  public userName: string;
  public dealerCode: string;
  public programCode: string;
  public pendingContracts: PendingContract[];
  public paymentMethodType: AccountPaymentMethodType;
  public bankAccount: DealerBankAccount;
  public totalRemittanceAmount: number;
  public dealer: Dealer;
  public useSefi: boolean;
  //#endregion
}
