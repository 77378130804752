import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import {
  ApiResponse,
  DealerBankAccount,
  FileDownloadResponse,
  GenerateRemittanceReportRequest,
  PendingContract,
  PendingContractHistory,
  RemitRequest,
  RemitResponse,
  RemittanceDealerBankAccountsRequest,
  RemittanceHistoryRequest,
  RemittancePendingContractsRequest,
  SaveDealerBankAccountRequest,
} from '@mbp/core';
import { PORTAL_API_BASE_URL } from '../core.module';
import { getApiResponseData } from '../rxjs/get-api-response-data';

@Injectable({
  providedIn: 'root',
})
export class RemittanceService {
  constructor(private http: HttpClient, @Inject(PORTAL_API_BASE_URL) private baseUrl: string) {}

  generateRemittanceReportPdf(request: GenerateRemittanceReportRequest) {
    return this.http
      .put<ApiResponse<FileDownloadResponse>>(`${this.baseUrl}/api/reporting/remittancereport`, request)
      .pipe(getApiResponseData());
  }

  getDealerBankAccounts(request: RemittanceDealerBankAccountsRequest) {
    return this.http.put<ApiResponse<DealerBankAccount[]>>(`/api/remittance/bankaccounts`, request).pipe(getApiResponseData());
  }

  getHistory(request: RemittanceHistoryRequest) {
    return this.http.put<ApiResponse<PendingContractHistory[]>>(`/api/remittance/history`, request).pipe(getApiResponseData());
  }

  getPendingRemittance(request: RemittancePendingContractsRequest) {
    return this.http.put<ApiResponse<PendingContract[]>>(`/api/remittance/pending`, request).pipe(getApiResponseData());
  }

  remitPendingContracts(request: RemitRequest) {
    return this.http.post<ApiResponse<RemitResponse>>(`/api/remittance/remit`, request).pipe(getApiResponseData());
  }

  saveDealerBankAccount(request: SaveDealerBankAccountRequest) {
    return this.http.put<ApiResponse<boolean>>(`/api/remittance/savedealerbankaccount`, request).pipe(getApiResponseData());
  }
}
