import { VehicleStatusType } from './enums';

export class TestVehicleInformation {
  constructor(
    public name?: string,
    public vin?: string,
    public mileage?: number,
    public vehicleStatusType?: VehicleStatusType,
    public purchasePrice?: string,
    public inServiceDate?: string,
    public isPowersports?: boolean,
  ) {}
}
