import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { environment } from '@mbp/environment';
import { AppModule } from './app/app.module';

if (environment.env === 'prd') {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));

if ('serviceWorker' in navigator) {
  navigator.serviceWorker
    .getRegistrations()
    .then(async function (registrations) {
      for (let registration of registrations) {
        const worked = await registration.unregister();
        if (worked) {
          console.log('Registration unregistered');
        } else {
          console.log('Registration could not unregister');
        }
      }
    })
    .catch(function (error) {
      // registration failed
      console.log('Registration failed with ' + error);
    });
}
