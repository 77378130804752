import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { AuthState } from '..';

const noAuth = ['/api/auth/login', '/api/auth/register', '/api/profile/getsession'];
const formData = ['/api/profile/updateagencylogo', '/api/profile/updateprofilephoto'];

@Injectable({ providedIn: 'root' })
export class HeadersInterceptor implements HttpInterceptor {
  constructor(private store: Store) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const jwt = this.store.selectSnapshot(AuthState.props.accessToken);
    const authHeaders = {
      Authorization: `Bearer ${jwt}`,
      'x-custom-authorization': jwt,
    };
    const acceptHeader = {
      Accept: 'application/json, application/pdf, text/plain, application/octet-stream, */*',
    };
    try {
      const path = request.url.indexOf('/') == 0 ? request.url.substring(1) : request.url;
      const url = !request.url.includes('http') ? `${window.location.origin}/${path}` : path;
      const { pathname } = new URL(url);
      if (noAuth.includes(pathname)) {
        return next.handle(request);
      }
      if (formData.includes(pathname)) {
        const modified = request.clone({
          setHeaders: {
            ...acceptHeader,
            ...authHeaders,
          },
        });

        return next.handle(modified);
      }
    } catch (_) {
      return next.handle(request);
    }

    const modified = request.clone({
      setHeaders: {
        'Content-Type': 'application/json',
        ...acceptHeader,
        ...authHeaders,
      },
    });
    return next.handle(modified);
  }
}
