import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import {
  Agency,
  AppStateModel,
  ContractStatus,
  ContractStatusType,
  DealerContractReportRequest,
  DealerMinimal,
  LookupService,
  PortalFilterService,
  Program,
  RightSidebarService,
  SearchCriteriaPageType,
} from '@mbp/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngxs/store';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

@UntilDestroy()
@Component({
  selector: 'mbp-dealer-contracts-search-criteria',
  templateUrl: './dealer-contracts-search-criteria.component.html',
  styleUrls: ['./dealer-contracts-search-criteria.component.scss'],
})
export class DealerContractsSearchCriteriaComponent implements OnInit, OnDestroy {
  //#region : Variable Declarations :
  formErrors = {
    dealer: '',
    dealerCode: '',
    startDate: '',
    endDate: '',
  };
  validationMessages = {
    dealer: {
      required: 'Please select a dealer',
      dealer: 'Please select a valid dealer',
    },
    dealerCode: {
      required: 'Please select a dealer',
      dealer: 'Please select a valid dealer',
    },
    startDate: {
      required: 'Please enter a start date',
    },
    endDate: {
      required: 'Please enter a end date',
    },
  };

  isLoading = false;
  searchCriteriaForm: FormGroup;

  programs: Program[] = [];
  contractStatusTypes: ContractStatus[] = [];
  selectedAgency: Agency = null;
  private subscription = new Subscription();
  //#endregion

  //#region : Constructor :
  constructor(
    private formBuilder: FormBuilder,
    private store: Store,
    private lookupService: LookupService,
    private rightSidebarService: RightSidebarService,
    private portalFilterService: PortalFilterService,
  ) {}
  //#endregion

  //#region : Properties :
  //#endregion

  //#region : Implementation Methods :
  ngOnInit() {
    this.buildForm();
    this.updateWithPortalFilter();

    setTimeout(() => {
      this.loadLookupData();
    }, 500);

    this.subscription.add(
      this.rightSidebarService.runImmediately$
        .pipe(
          filter((d) => !!d),
          filter(({ searchCriteriaPageType }) => searchCriteriaPageType === SearchCriteriaPageType.DealerContractsReport),
          untilDestroyed(this),
        )
        .subscribe((sc) => {
          this.updateWithPortalFilter();

          if (sc.eventData) {
            this.searchCriteriaForm.patchValue({ dealer: sc.eventData as DealerMinimal });

            const onlyDealer = this.store.selectSnapshot((appState: AppStateModel) => appState.dealer.dealerMinimals.length === 1);
            if (onlyDealer) {
              this.searchCriteriaForm.get('dealer').disable();
              this.searchCriteriaForm.updateValueAndValidity();
            }
          }
          this.search();
        }),
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
  //#endregion

  //#region : Methods :
  buildForm() {
    const startDate = new Date();
    const endDate = new Date();

    // TODO: fix api to send back less data, so we can widen this search parameter
    startDate.setDate(startDate.getDate() - 1);

    this.searchCriteriaForm = this.formBuilder.group({
      dealer: null,
      program: '',
      startDate,
      endDate,
      contractStatus: new ContractStatus(ContractStatusType.All, '[All Statuses]'),
    });
    this.searchCriteriaForm.valueChanges.subscribe((data) => this.onFormValueChanged(data));
  }

  loadLookupData() {
    if (this.contractStatusTypes == null || this.contractStatusTypes.length === 0) {
      this.contractStatusTypes.push(new ContractStatus(ContractStatusType.All, '[All Statuses]'));
      this.contractStatusTypes.push(new ContractStatus(ContractStatusType.Active, 'Active'));
      this.contractStatusTypes.push(new ContractStatus(ContractStatusType.Cancelled, 'Cancelled'));
      this.contractStatusTypes.push(new ContractStatus(ContractStatusType.Denied, 'Denied'));
      this.contractStatusTypes.push(new ContractStatus(ContractStatusType.Expired, 'Expired'));
      this.contractStatusTypes.push(new ContractStatus(ContractStatusType.Suspense, 'Suspense'));

      this.searchCriteriaForm.patchValue({ contractStatus: this.contractStatusTypes[0] });
    }

    this.lookupService.getPrograms({}).subscribe(
      (data) => this.onProgamsLoadSuccessful(data),
      (error) => this.onProgramsLoadFailed(error),
    );
  }

  public onFormValueChanged(data?: any) {
    if (!this.searchCriteriaForm) {
      return;
    }
    const form = this.searchCriteriaForm;
    for (const field in this.formErrors) {
      if (Object.prototype.hasOwnProperty.call(this.formErrors, field)) {
        this.formErrors[field] = '';
        const control = form.get(field);
        if (control && control.dirty && !control.valid) {
          const messages = this.validationMessages[field];
          for (const key in control.errors) {
            if (Object.prototype.hasOwnProperty.call(control.errors, key)) {
              this.formErrors[field] += messages[key] + ' ';
            }
          }
        }
      }
    }
  }

  onProgamsLoadSuccessful(programsData: Program[]) {
    this.programs = Array.isArray(programsData) ? programsData : [];
  }

  onProgramsLoadFailed(error: any) {}

  public search() {
    const formValue = this.searchCriteriaForm.getRawValue();
    const searchCriteria = new DealerContractReportRequest();
    searchCriteria.contractStatus = formValue.contractStatus;
    searchCriteria.contractStatusType = formValue.contractStatus && formValue.contractStatus.contractStatusTypeID;
    searchCriteria.dealer = formValue.dealer;
    searchCriteria.dealerCode = formValue.dealer && formValue.dealer.dealerCode;
    searchCriteria.endDate = formValue.endDate;
    searchCriteria.program = formValue.program;
    searchCriteria.programCode = formValue.program && formValue.program.programCode;
    searchCriteria.startDate = formValue.startDate;
    searchCriteria.dealerCode = searchCriteria.dealer && searchCriteria.dealer.dealerCode;

    this.rightSidebarService.setSearchCriteria(SearchCriteriaPageType.DealerContractsReport, searchCriteria);
  }

  public updateWithPortalFilter() {
    if (this.portalFilterService.currentFilteredDealer) {
      this.searchCriteriaForm.patchValue({
        dealer: this.portalFilterService.currentFilteredDealer,
      });
      this.searchCriteriaForm.updateValueAndValidity();
    }
    if (this.portalFilterService.currentFilteredDealerCode) {
      this.searchCriteriaForm.patchValue({
        dealerCode: this.portalFilterService.currentFilteredDealerCode,
      });
      this.searchCriteriaForm.updateValueAndValidity();
    }
  }
  //#endregion
}
