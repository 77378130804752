export class UserUnassociateContractRequest {
  //#region : Constructor :
  constructor(userName?: string, contractNumber?: string) {
    this.userName = userName;
    this.contractNumber = contractNumber;
  }
  //#endregion

  //#region : Properties :
  public userName: string = null;
  public contractNumber: string = null;
  //#endregion
}
