import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { NbWindowState } from '@nebular/theme';
import { Select } from '@ngxs/store';
import { AUTH_API_BASE_URL, CC_API_BASE_URL } from 'app/core/core.module';
import { Observable } from 'rxjs';
import { SidemenuComponentSelector, SidemenuComponentStateModel } from './sidemenu.selector';

@Component({
  selector: 'mbp-portal-sidemenu',
  templateUrl: './sidemenu.component.html',
  styleUrls: ['./sidemenu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SidemenuComponent {
  logoutUrl: string;
  ccUrl: string;
  constructor(@Inject(AUTH_API_BASE_URL) private authBaseUrl: string, @Inject(CC_API_BASE_URL) private ccBaseUrl: string) {
    const logoutUrl = new URL('ui/logout', this.authBaseUrl);
    const return_to = window.location.href;
    logoutUrl.searchParams.append('return_to', return_to);
    this.logoutUrl = logoutUrl.toString();

    this.ccUrl = new URL('/users', this.ccBaseUrl).toString();
  }

  @Select(SidemenuComponentSelector.getState) state$: Observable<SidemenuComponentStateModel>;

  NbWindowState = NbWindowState;
}
