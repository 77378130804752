import { PortalUser } from './portal-user.model';

export class SavePortalUserRequest {
  //#region : Constructor :
  constructor(userName?: string, portalUser?: PortalUser) {
    this.userName = userName;
    this.portalUser = portalUser;
  }
  //#endregion

  //#region : Properties :
  public userName: string;
  public portalUser: PortalUser;
  //#endregion
}
