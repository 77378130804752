import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  ApiResponse,
  ClaimsDetail,
  ClaimsSummary,
  ContractsDetail,
  ContractsDetailRequest,
  DealerClaimsRequest,
  DealerContractsReceivedRequest,
  DealerContractsSummaryRequest,
  DealerLoginsRequest,
  DealerLoginsResult,
  DealerLossRatio,
  DealerLossRatioRequest,
  DealerMinimal,
  DealerReceivedContract,
  DealerSale,
  DealerSalesDetail,
  DealerSalesDetailRequest,
  DealerSalesRequest,
  DealerSummaryContract,
} from '@mbp/core';
import { getApiResponseData } from '../rxjs/get-api-response-data';

@Injectable({
  providedIn: 'root',
})
export class DealersService {
  constructor(private http: HttpClient) { }

  public dealers(searchText: string) {
    return this.http
      .get<ApiResponse<DealerMinimal[]>>(`/api/dealers`, {
        params: { q: searchText },
      })
      .pipe(getApiResponseData());
  }

  public dealer(id: string) {
    return this.http.get<ApiResponse<DealerMinimal>>(`/api/dealers/${id}`).pipe(getApiResponseData());
  }

  public dealerLossRatio(request: DealerLossRatioRequest) {
    return this.http
      .get<ApiResponse<DealerLossRatio>>(`/api/dealers/lossRatio`, {
        params: toParams(request),
      })
      .pipe(getApiResponseData());
  }

  public dealerCount() {
    return this.http.get<ApiResponse<number>>(`/api/dealers/count`).pipe(getApiResponseData());
  }

  public dealerMonthlyContracts(request: DealerContractsReceivedRequest) {
    return this.http
      .get<ApiResponse<DealerReceivedContract[]>>(`/api/dealers/monthlyContracts`, {
        params: { dealerCode: request.dealerCode, programCode: request.programCode },
      })
      .pipe(getApiResponseData());
  }

  public dealerSummaryCounts(request: DealerContractsSummaryRequest) {
    return this.http
      .get<ApiResponse<DealerSummaryContract>>(`/api/dealers/summaryCounts`, {
        params: toParams(request),
      })
      .pipe(getApiResponseData());
  }

  public dealerContractsGet(request: ContractsDetailRequest) {
    return this.http
      .get<ApiResponse<ContractsDetail[]>>(`/api/dealers/contractsDetail`, {
        params: toParams(request),
      })
      .pipe(getApiResponseData());
  }

  public dealerMonthlyClaims(request: DealerClaimsRequest) {
    return this.http
      .get<ApiResponse<{ claims: ClaimsDetail[]; summaryItems: ClaimsSummary[] }>>(`/api/dealers/monthlyClaims`, {
        params: toParams(request),
      })
      .pipe(getApiResponseData());
  }

  public dealerSales(request: DealerSalesRequest) {
    return this.http
      .get<ApiResponse<DealerSale[]>>(`/api/dealers/sales`, {
        params: toParams(request),
      })
      .pipe(getApiResponseData());
  }

  public dealerSalesDetail(request: DealerSalesDetailRequest) {
    return this.http
      .get<ApiResponse<DealerSalesDetail[]>>(`/api/dealers/salesDetail`, {
        params: toParams(request),
      })
      .pipe(getApiResponseData());
  }

  public dealerLogins(request: DealerLoginsRequest) {
    return this.http
      .get<ApiResponse<DealerLoginsResult[]>>(`/api/dealers/logins`, {
        params: toParams(request),
      })
      .pipe(getApiResponseData());
  }
}

function toParams(request: any) {
  const params = {};
  for (const [key, value] of Object.entries(request)) {
    if (value) params[key] = value.toString();
  }
  return params;
}
