import { HttpResponse } from '@angular/common/http';
import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { environment } from '@mbp/environment';
import * as LogRocket from 'logrocket';
import { ApiResponse } from '.';
import { ActionError } from './models/errors';
import { AlertService, ToastType } from './services/alert.service';

@Injectable({
  providedIn: 'root',
})
export class MbpErrorHandler implements ErrorHandler {
  constructor(private injector: Injector) {}

  handleError<T>(error: any | ActionError | HttpResponse<ApiResponse<T>>) {
    const alertService = this.injector.get(AlertService);

    alertService.showApiError(error);

    if (error instanceof ActionError) {
      alertService?.showStickyMessage(error.message, '', ToastType.error);
    }

    if (environment.logRocket.on === 'true') {
      LogRocket.captureException(error, {
        tags: {
          // additional data to be grouped as "tags"
          // subscription: 'Pro',
        },
        extra: {
          // additional arbitrary data associated with the event
          // pageName: 'ProfileView',
        },
      });
    }
    console.error(error);
  }
}
