import { DealerMinimal } from './dealer-minimal';

export class DealerSelectedEventItem {
  //#region : Constructor :
  constructor(dataItem?: DealerMinimal) {
    this.dataItem = dataItem;
  }
  //#endregion

  //#region : Properties :
  public dataItem: DealerMinimal;
  //#endregion
}
