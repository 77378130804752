import { RaterMenuDealerFee } from './rater-menu-dealer-fee.model';

export class RaterSaveMenuFeesRequest {
  //#region : Constructor :
  constructor() {}
  //#endregion

  //#region : Properties :
  public userName: string;
  public dealerCode: string;
  public fees: RaterMenuDealerFee[];
  //#endregion
}
