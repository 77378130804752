<kendo-dialog *ngIf="dialogOpen" (close)="closeForm()">
  <kendo-dialog-titlebar>
    Search for Dealer
  </kendo-dialog-titlebar>

  <div style="padding-bottom: 5px; min-width: 400px;">
    <mat-form-field class="mbp-full-width">
      <input
        matInput
        #searchInput
        [value]="searchText"
        (keyup.enter)="onSearch(searchInput.value)"
        placeholder="Search by Dealer Code or Name"
      />
    </mat-form-field>

    <div>
      <button
        class="k-button"
        style="width: 100%; border: 1px solid darkgray;"
        [disabled]="searchInput.value == null || searchInput.value.length == 0 || isLoading"
        (click)="onSearch(searchInput.value)"
      >
        <mat-icon style="font-size: 13pt; padding-right: 5px;">search</mat-icon>Search
      </button>
    </div>
  </div>

  <div
    *ngIf="hasSearched"
    fxLayout="column"
    fxLayout.xs="column"
    style="padding: 5px; margin-top: 5px;"
    [ngStyle]="{ 'background-color': isLoading ? '#e6e6e6' : 'white' }"
  >
    <div class="mat-headline" style="margin: 10px; border-bottom: 1px solid #dddddd;">
      FOUND DEALERS
    </div>
    <div *ngIf="isLoading" style="width: 100%; height: 100%; text-align: center; vertical-align: middle; display: block;">
      <div style="display: inline-block;">
        <div class="lds-ring">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
        <div style="font-size: 10pt;">Loading contracts</div>
      </div>
    </div>
    <div>
      <div style="padding-top: 5px; width: 400px;">
        <kendo-grid #historyTable [resizable]="true" [data]="gridViewData" [height]="200" *ngIf="!isLoading" style="font-size: 9pt;">
          <kendo-grid-column title="" width="55">
            <ng-template kendoGridCellTemplate let-dataItem>
              <button
                mat-raised-button
                color="primary"
                type="button"
                (click)="onSelectDealer(dataItem)"
                style="width: 30px; min-width: 30px; padding: 0px;"
              >
                <mat-icon>check_circle_outline</mat-icon>
              </button>
            </ng-template>
          </kendo-grid-column>
          <kendo-grid-column field="name" title="Name">
            <ng-template kendoGridCellTemplate let-dataItem> {{ dataItem.name }} ({{ dataItem.dealerCode }}) </ng-template>
          </kendo-grid-column>
          <kendo-grid-column field="address1" title="Address">
            <ng-template kendoGridCellTemplate let-dataItem>
              {{ dataItem.address1 }} {{ dataItem.address2 }} {{ dataItem.city }}, {{ dataItem.stateAbbreviation }}
              {{ dataItem.zip }}
            </ng-template>
          </kendo-grid-column>
          <kendo-grid-column field="expirationDate" title="Expiration Date" [width]="90">
            <ng-template kendoGridCellTemplate let-dataItem>
              {{ dataItem.expirationDate | date: 'MM/dd/yyyy' }}
            </ng-template>
          </kendo-grid-column>
        </kendo-grid>
      </div>
    </div>
  </div>

  <div style="padding-top: 10px;">
    <button class="k-button" style="width: 100%;" (click)="onCancel($event)">Cancel</button>
  </div>
</kendo-dialog>
