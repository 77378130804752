import { AuthState, AuthStateModel, NotificationsState, UIState } from '@mbp/core';
import { environment } from '@mbp/environment';
import { Selector } from '@ngxs/store';
import { ClickupTaskItem } from 'app/core/models/clickup-task.model';

export interface ToolbarViewModel {
  showDealerSearch: boolean;
  showToolbarFilter: boolean;
  notifications: ClickupTaskItem[];
  fullName: string;
  pageHeader: string;
}

export class ToolbarQueries {
  @Selector([AuthState, NotificationsState.notifications, UIState.props.pageHeader])
  static getViewModel(auth: AuthStateModel, notifications: ClickupTaskItem[], pageHeader: string): ToolbarViewModel {
    return {
      showDealerSearch: auth.currentUserIsAgent || auth.currentUserIsMBPI,
      showToolbarFilter: false,
      notifications: notifications ?? [],
      fullName: auth.user?.fullName ?? 'Unknown',
      pageHeader,
    };
  }
}
