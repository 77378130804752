import { ContractMinimal } from './contract-minimal.model';

export class PendingContract {
  //#region : Constructor :
  constructor() {}
  //#endregion

  //#region : Properties :

  public key: string;
  public purchasedContractID: number;
  public programCode: string;
  public programName: string;
  public programTypeID: number;
  public dealerCode: string;
  public dealerName: string;
  public contractNumber: number;
  public firstName: string;
  public lastName: string;
  public vin: string;
  public lenderName: string;
  public planCode: string;
  public planDescription: string;
  public coverageMiles: number;
  public coverageMonths: number;
  public coverageDisplayTypeID: number;
  public customerPrice: number;
  public retailAmount: number;
  public discountAmount: number;
  public dealerCost: number;
  public dealerRemitAmount: number;
  public dealerRemitAccountPaymentTypeID: number;
  public dealerRemitAccountPaymentTypeName: string;
  public hasBeenImportedIntoAS400: boolean;
  public isRatingEngineContract: boolean;
  public isMBPIFinanced: boolean;
  public isAllowedToChangeMBPFinanceRemitAmount: boolean;
  public purchaseDate: Date;
  public createDate: Date;
  public createUser: boolean;
  public canBeVoided: boolean;

  //#endregion

  static createFromObject(obj: Object) {
    let raterHistory = new PendingContract();
    Object.assign(raterHistory, obj);
    return raterHistory;
  }

  getCoverageDisplay(): string {
    let display = '';

    if (this.coverageDisplayTypeID === 1) {
      // Months
      display += this.coverageMonths > 0 ? this.coverageMonths.toString() + ' month' + (this.coverageMonths > 1 ? 's' : '') : 'Unlimited';
    } else {
      // Years
      let years = this.coverageMonths / 12;
      display += years.toString() + ' year' + (years > 1 ? 's' : '');
    }

    if (this.coverageMiles < 999999) {
      display += ' / ' + (this.coverageMiles != null && this.coverageMiles > 0 ? this.coverageMiles.toFixed(0) : '0') + ' miles';
    }

    return display;
  }

  toContractMinimal() {
    let contractMinimal = new ContractMinimal();
    Object.assign(contractMinimal, this);
    return contractMinimal;
  }
}
