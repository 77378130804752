<ng-template #content>
  <mat-icon matListIcon>{{ icon }}</mat-icon>
  <ng-content></ng-content>
</ng-template>
<a class="sidemenu-link" *ngIf="route" [routerLink]="route" [routerLinkActive]="addActiveClass ? 'active' : ''" mat-list-item>
  <ng-container *ngTemplateOutlet="content"></ng-container>
</a>
<a class="sidemenu-link" *ngIf="externalRoute" [href]="externalRoute" [target]="target" mat-list-item>
  <ng-container *ngTemplateOutlet="content"></ng-container>
</a>
