import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
  ContractSearchStateModel,
  ContractSearchWindowClose,
  ContractSearchWindowMinimize,
  ContractSearchWindowOpen,
  ContractSearchWindowSizeChanged,
} from '@mbp/core';
import { ContractSearchComponent } from '@mbp/shared';
import { NbWindowRef, NbWindowService, NbWindowState } from '@nebular/theme';
import { Action, State, StateContext } from '@ngxs/store';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';

let contractSearchWindowRef: NbWindowRef;
let componentDestroyed$: Subject<void> = new Subject<void>();

@State<string>({
  name: 'root',
  defaults: '',
})
@Injectable()
export class RootState {
  constructor(private windowService: NbWindowService, private router: Router) {}

  @Action(ContractSearchWindowOpen)
  contractSearchWindowOpen(ctx: StateContext<ContractSearchStateModel>, action: ContractSearchWindowOpen) {
    if (!contractSearchWindowRef) {
      contractSearchWindowRef = this.windowService.open(ContractSearchComponent, {
        closeOnBackdropClick: false,
        closeOnEsc: false,
        hasBackdrop: false,
        title: 'Contract Search',
        windowClass: 'contract-search-window',
        initialState: action.payload.screenSize,
      });

      contractSearchWindowRef.stateChange.pipe(takeUntil(componentDestroyed$)).subscribe((state) => {
        ctx.dispatch(new ContractSearchWindowSizeChanged({ contractSearchWindowSize: state.newState }));
      });

      contractSearchWindowRef.onClose.pipe(take(1)).subscribe((_) => {
        this.router.navigate([{ outlets: { contractsearch: null } }]);
        ctx.dispatch(new ContractSearchWindowClose());
      });
    } else {
      if (action.payload.screenSize === NbWindowState.FULL_SCREEN) {
        contractSearchWindowRef.fullScreen();
      } else if (action.payload.screenSize === NbWindowState.MINIMIZED) {
        contractSearchWindowRef.minimize();
      } else {
        contractSearchWindowRef.maximize();
      }
    }
  }

  @Action(ContractSearchWindowClose)
  contractSearchWindowClose() {
    componentDestroyed$.next();
    contractSearchWindowRef?.close();
    contractSearchWindowRef = null;
  }

  @Action(ContractSearchWindowMinimize)
  ContractSearchWindowMinimize() {
    contractSearchWindowRef.minimize();
  }
}
