import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'enumAsString',
})
export class EnumAsStringPipe implements PipeTransform {
  transform(value: number, enumType: any, characterLimit: number = null): any {
    var enumString = enumType[value]
      .split(/(?=[A-Z])/)
      .join()
      .replace(',', ' ');

    if (characterLimit != null && characterLimit > 0) {
      enumString = enumString.slice(0, characterLimit);
    }

    return enumString;
  }
}
