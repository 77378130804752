// tslint:disable: directive-selector
import { FocusMonitor, FocusOrigin } from '@angular/cdk/a11y';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { Directive, ElementRef, HostBinding, HostListener, Input, OnDestroy, Optional, Self } from '@angular/core';
import { NgControl } from '@angular/forms';
import { MatFormFieldControl } from '@angular/material/form-field';
import { Subject } from 'rxjs';

@Directive({
  selector: '[feux-form-field-control]',
  providers: [{ provide: MatFormFieldControl, useExisting: FeuxFormFieldControlDirective }],
})
export class FeuxFormFieldControlDirective implements MatFormFieldControl<FeuxFormFieldControlDirective>, OnDestroy {
  @Input()
  set value(val: any) {
    this._value = val;
    this.stateChanges.next();
  }
  get value(): any {
    return this._value;
  }

  get empty(): boolean {
    return !this._value;
  }

  @HostBinding('class.floating')
  get shouldLabelFloat(): boolean {
    return this.focused || !this.empty;
  }

  @Input()
  set error(err: any) {
    this._error = coerceBooleanProperty(err);
    this.stateChanges.next();
  }
  get error(): any {
    return this._error;
  }
  get errorState(): boolean {
    return this.touched || this._submitted ? this.error : false;
  }

  @Input()
  get placeholder(): string {
    return this._placeholder;
  }
  set placeholder(plh: string) {
    this._placeholder = plh;
    this.stateChanges.next();
  }

  @Input()
  get required(): any {
    return this._required;
  }
  set required(req: any) {
    this._required = coerceBooleanProperty(req);
    this.stateChanges.next();
  }

  @Input()
  get disabled(): boolean {
    return this._disabled;
  }
  set disabled(value: boolean) {
    this._disabled = coerceBooleanProperty(value);
    this.stateChanges.next();
  }

  @Input()
  get submitted(): boolean {
    return this._submitted;
  }
  set submitted(value: boolean) {
    this._submitted = coerceBooleanProperty(value);
    this.stateChanges.next();
  }

  static instanceId = 0;
  public stateChanges = new Subject<void>();
  public focused = false;
  @HostBinding() id = `feux-form-field-control-${FeuxFormFieldControlDirective.instanceId++}`;
  @HostBinding('attr.aria-describedby') describedBy = '';
  private _error = false;
  private _placeholder = '';
  private _required = false;
  private _disabled = false;
  private _value: any;
  private _submitted = false;
  private touched = false;
  constructor(@Optional() @Self() public ngControl: NgControl, private fm: FocusMonitor, private elRef: ElementRef<HTMLElement>) {
    this.fm.monitor(this.elRef.nativeElement, true).subscribe((origin) => this.onFocus(origin));
  }

  setDescribedByIds(ids: string[]): void {
    this.describedBy = ids.join(' ');
  }

  ngOnDestroy(): void {
    this.stateChanges.complete();
    this.fm.stopMonitoring(this.elRef.nativeElement);
  }

  onFocus(origin: FocusOrigin): void {
    this.focused = !!origin;
    this.stateChanges.next();
  }

  @HostListener('blur') onBlur(): void {
    this.touched = true;
    this.stateChanges.next();
  }

  onContainerClick(event: MouseEvent): void {
    if ((event.target as Element).tagName.toLowerCase() !== 'input') {
      const query = this.elRef.nativeElement.querySelector('input');
      if (query) {
        query.focus();
      }
    }
  }
}
