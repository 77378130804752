import { SidenavMode, SidenavState } from './ui.state';

export interface UIMediaQueryListEventPayload {
  mobileView: boolean;
  sidenavOpened: boolean;
  sidenavMode: SidenavMode;
  showToggle: boolean;
}

export class UIMediaQueryListEvent {
  static readonly type = '[Media Query List] Update UI';
  constructor(public payload: UIMediaQueryListEventPayload) {}
}

export class SetRightSidenav {
  static readonly type = '[UI] Set Right Sidenav';
  constructor(public payload: SidenavState) {}
}

export class ToggleRightSidenav {
  static readonly type = '[UI] Toggle Right Sidenav';
}

export class ToggleSidenav {
  static readonly type = '[UI] Toggle Sidenav';
}

export class SidenavBackdropClicked {
  static readonly type = '[Root Component] Sidenav Backdrop Clicked';
}

export class SetCustomerMode {
  static readonly type = '[UI] Set Customer Mode';
  constructor(public payload: boolean) {}
}

export interface SetPageHeaderPayload {
  pageHeader: string;
}

export class SetPageHeader {
  static readonly type = '[UI] Set Page Header';
  constructor(public payload: SetPageHeaderPayload) {}
}
