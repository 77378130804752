import { NgModule } from '@angular/core';
import { environment } from '@mbp/environment';
import { NgxsActionsExecutingModule } from '@ngxs-labs/actions-executing';
import { NgxsDispatchPluginModule } from '@ngxs-labs/dispatch-decorator';
import { NgxsSelectSnapshotModule } from '@ngxs-labs/select-snapshot';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsFormPluginModule } from '@ngxs/form-plugin';
import { NgxsLoggerPluginModule } from '@ngxs/logger-plugin';
import { NgxsRouterPluginModule } from '@ngxs/router-plugin';
import { NgxsModule, NoopNgxsExecutionStrategy } from '@ngxs/store';
import { LookupService } from '../api/lookup.service';
import { RaterService } from '../api/rater.service';
import { AppState } from './app.state';
import { AuthState } from './auth.state';
import { BrandingState } from './branding.state';
import { ContractSearchState } from './contract-search.state';
import { DealerState } from './dealer.state';
import { DocumentsState } from './documents.state';
import { LookupState } from './lookup/lookup.state';
import { NotificationsState } from './notifications.state';
import { RetailSettingsState } from './retail-settings.state';
import { SettingsState } from './settings.state';
import { UIState } from './ui.state';
import { ZipCodeState } from './zip-code.state';

@NgModule({
  imports: [
    NgxsModule.forRoot(
      [
        AppState,
        AuthState,
        BrandingState,
        ContractSearchState,
        DealerState,
        DocumentsState,
        LookupState,
        NotificationsState,
        RetailSettingsState,
        SettingsState,
        UIState,
        ZipCodeState,

        LookupService,
        RaterService,
      ],
      {
        developmentMode: environment.env === 'hmr',
        selectorOptions: {
          injectContainerState: false,
          suppressErrors: false,
        },
        executionStrategy: NoopNgxsExecutionStrategy,
      },
    ),
    NgxsActionsExecutingModule.forRoot(),
    NgxsFormPluginModule.forRoot(),
    environment.ngxs.devTools === 'true' ? NgxsReduxDevtoolsPluginModule.forRoot({ name: 'MBPI Portal' }) : [],
    NgxsRouterPluginModule.forRoot(),
    NgxsDispatchPluginModule.forRoot(),
    NgxsSelectSnapshotModule.forRoot(),
    environment.ngxs.logger === 'true' ? NgxsLoggerPluginModule.forRoot() : [],
  ],
  exports: [
    NgxsModule,
    NgxsActionsExecutingModule,
    NgxsFormPluginModule,
    NgxsRouterPluginModule,
    environment.ngxs.logger === 'true' ? NgxsLoggerPluginModule : [],
    environment.ngxs.devTools === 'true' ? NgxsReduxDevtoolsPluginModule : [],
  ],
})
export class StateModule {}
