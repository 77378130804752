export class RefreshContractRequest {
  //#region : Constructor :
  constructor(userName?: string, programCode?: string, dealerCode?: string, contractNumber?: string, contractSuffix?: string) {
    this.userName = userName;
    this.programCode = programCode;
    this.dealerCode = dealerCode;
    this.contractNumber = contractNumber;
    this.contractSuffix = contractSuffix;
  }
  //#endregion

  //#region : Properties :
  public userName: string;
  public programCode: string;
  public dealerCode: string;
  public contractNumber: string;
  public contractSuffix: string;
  //#endregion
}
