// TODO: this needs more work when we have an actualy quote model
export interface QuoteModel {
  createdAt: string;
  dealerCode: string;
  firstName: string;
  id: string;
  lastName: string;
  selectedPlanCount: number;
  updatedAt: string;
  vehicleCylinderNumber: number;
  vehicleFuelTypeDescription: string;
  vehicleMakeDescription: string;
  vehicleModelDescription: string;
  vehicleNumberWheelsDriving: number;
  vehicleYear: number;
  vin: string;
}

export enum QuoteColumns {
  createdAt = 'createdAt',
  DealerCode = 'DealerCode',
  FirstName = 'FirstName',
  Id = 'Id',
  LastName = 'LastName',
  SelectedPlanCount = 'SelectedPlanCount',
  UpdatedAt = 'UpdatedAt',
  VehicleCylinderNumber = 'VehicleCylinderNumber',
  VehicleFuelTypeDescription = 'VehicleFuelTypeDescription',
  VehicleMakeDescription = 'VehicleMakeDescription',
  VehicleModelDescription = 'VehicleModelDescription',
  VehicleNumberWheelsDriving = 'VehicleNumberWheelsDriving',
  VehicleYear = 'VehicleYear',
  Vin = 'Vin',
}
